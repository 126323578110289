import React from 'react'

export const SliderCircles = () => {
  return (
    <>
        <div className='flex md:gap-8 gap-2 text-center'>
            <div className='bg-white md:w-24 mx-auto md:h-24 w-[72px] h-[72px] rounded-full'>
                <p className='font-extrabold md:pt-8 pt-4 leading-3'>3000+</p>
                <p className='text-sm leading-5'>Sent Gifts</p>
            </div>
            <div className='bg-white md:w-24 mx-auto md:h-24 w-[72px] h-[72px] rounded-full'>
                <p className='font-extrabold md:pt-8 pt-4 leading-3'>3500+</p>
                <p className='text-sm md:leading-5 leading-4'>Silver Tickets</p>
            </div>
            <div className='bg-white md:w-24 mx-auto md:h-24 w-[72px] h-[72px] rounded-full'>
                <p className='font-extrabold md:pt-8 pt-4 leading-3'>2900+</p>
                <p className='text-sm md:leading-5 leading-4'>Gold Tickets</p>
            </div>
            <div className='bg-white md:w-24 mx-auto md:h-24 w-[72px] h-[72px] rounded-full'>
                <p className='font-extrabold md:pt-8 pt-4 leading-3'>2200+</p>
                <p className='text-sm leading-4'>Diamond<br/> Tickets</p>
            </div>
        </div>
    </>
  )
}
