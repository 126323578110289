import React from "react";

const CashbackAccordion = () => {
    return(
        <>
        <div class="accordion" id="accordionExample">
            <div class="accordion-item bg-gray-100 border border-gray-200">
                <h2 class="accordion-header mb-0" id="headingOne">
                <button class="
                    accordion-button
                    relative
                    flex
                    items-center
                    w-full
                    py-4
                    px-5
                    text-base text-gray-800 text-left
                    bg-white
                    border-0
                    rounded-none
                    transition
                    focus:outline-none
                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne">
                    Calculation examples
                </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body py-4 px-5 visible">
                    <div className="p-6">
                        <div>
                            <p className="font-bold">Calculation of cashback from Monstrade</p> 
                            <br/>
                            <p className="font-bold">Example 1</p>
                            <br/>
                            <p>
                                Instruments: EUR/USD, USD/JPY<br/>
                                Trading volume in EUR/USD per trading session: 3 lots<br/>
                                Trading volume in USD/JPY per trading session: 0.9 lot<br/>
                                Current cashback level: 3 (cashback rate $5 per lot)<br/>
                                Holding period for each transaction is more than 3 minutes<br/>
                                The difference between the opening and closing prices of each transaction was at least 10 pips.
                            </p>
                            <br/>
                            <ul className="list-disc">
                                <li>EUR/USD: open price 1.06925, close price 1.07052 (12.7 pips difference),</li><br/>
                                <li>USD/JPY: open price 131.632, close price 131.52 (11.2 pips difference),</li>
                            </ul>
                            <br/>
                            <strong>Cashback Amount:</strong> 43 lots * $5 + 0.9 lot * $5 = $19.5 
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CashbackAccordion;
