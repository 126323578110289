import React from 'react';

const PricingMenu = (props) => {
    return(
        <>
        <div className='pb-6'>
            <ul class="overflow-hidden bg-slate-100 place-content-center">
                <div className='md:grid flex grid-cols-11 overflow-x-auto '>
                    <div className='col-span-2 whitespace-nowrap'>
                        <li class="text-center">
                            <a className={`text-center block border py-3 px-6 ${props.isActive == "spreads" ? "border-monstrade-blue  bg-monstrade-blue hover:bg-blue-700 text-white" : "border-white rounded hover:border-gray-200 text-monstrade-blue hover:bg-gray-200"} `} 
                            href="/trading/pricing/spreads">SPREADS</a>
                        </li>
                    </div>
                    <div className='col-span-2 whitespace-nowrap'>
                        <li class="text-center">
                            <a className={`text-center block border py-3 px-6 ${props.isActive == "swaprates" ? "border-monstrade-blue  bg-monstrade-blue hover:bg-blue-700 text-white" : "border-white rounded hover:border-gray-200 text-monstrade-blue hover:bg-gray-200"} `}
                            href="/trading/pricing/swap-rates">SWAP RATES</a>
                        </li>
                    </div>
                    <div className='col-span-3 whitespace-nowrap'>
                        <li class="text-center">
                            <a className={`text-center block border py-3 px-6 ${props.isActive == "tradinghours" ? "border-monstrade-blue  bg-monstrade-blue hover:bg-blue-700 text-white" : "border-white rounded hover:border-gray-200 text-monstrade-blue hover:bg-gray-200"} `}
                            href="/trading/pricing/trading-hours">TRADING HOURS</a>
                        </li>
                    </div>
                    <div className='col-span-4 whitespace-nowrap'>
                        <li class="text-center">
                            <a className={`text-center block border py-3 px-6 ${props.isActive == "holidaytradinghours" ? "border-monstrade-blue  bg-monstrade-blue hover:bg-blue-700 text-white" : "border-white rounded hover:border-gray-200 text-monstrade-blue hover:bg-gray-200"} `}
                            href="/trading/pricing/holiday-trading-hours">HOLIDAY TRADING HOURS</a>
                        </li>
                    </div>   
                </div>
            </ul>
        </div>
        </>
    )
}

export default PricingMenu;