import React from 'react'

export const AccountTypeCompareCard = ({ item }) => {
    return (
        <div id={item.header} className='max-w-[300px] rounded-2xl md:text-regular text-xl overflow-hidden h-full md:py-0 py-8' >
            <div className='grid grid-cols-2 bg-[#292929] py-2 px-5'>
                <div className='flex' >
                    <p className='text-white my-auto font-semibold' >{item.header}</p>
                </div>
                <div className='justify-self-end' >
                    <div className='max-h-[29px]' >
                        <img src={item.icon} alt='monstrade' />
                    </div>
                </div>
            </div>
            <div className='bg-[#D9D9D9] px-5 py-3 h-full relative md:text-xs text-base grid grid-flow-row auto-rows-max gap-3 leading-5'>
                <div className=''>
                    <p>Min. Deposit</p>
                </div>
                <div>
                    <p className='text-4xl font-bold' >$ {item.minDeposit}</p>
                </div>
                <div className='font-light' >
                    <p>(USD)</p>
                </div>
                <div className='grid grid-cols-2 auto-rows-max gap-4'>
                    <div>
                        <p className='font-bold'>Max. Deposit</p>
                        <p className='font-light' >NA</p>
                    </div>
                    <div>
                        <p className='font-bold' >Min.Trade Size</p>
                        <p className='font-light' >0.01 lots</p>
                    </div>
                    <div>
                        <p className='font-bold'>Spreads</p>
                        <p className='font-light'>From {item.spread} pips</p>
                    </div>
                    <div>
                        <p className='font-bold'>Leverage</p>
                        <p className='font-light' >Up to {item.leverage}</p>
                    </div>
                    <div>
                        <p className='font-bold'>Platform</p>
                        <p className='font-light' >MT5</p>
                    </div>
                    <div>
                        <p className='font-bold' >Commision</p>
                        <p className='font-light' >0</p>
                    </div>
                </div>
                <div>
                    <p className='font-bold' >Base Currency</p>
                    <p>USD</p>
                </div>
                <div>
                    <p className='font-bold' >What can you trade</p>
                    <p>Forex, Indices, Spot Metals, Commodities, Shares, Crypto</p>
                </div>
                <div className='h-52' >
                    <p className='font-bold' >Other features</p>
                    <ul className='list-disc' >
                        {item.features.map((feat) => {
                            return <li>{feat}</li>
                        })}
                    </ul>
                </div>
                <div className='md:h-24 h-6 bottom-0 w-full flex justify-center' >
                    <div className='mx-auto md:mt-0 mt-[-6px]' >
                        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='
                                whitespace-nowrap
                                border-2 border-mons-blue-1 rounded-full 
                                px-6 py-4
                                text-base font-bold text-monstrade-blue
                                hover:bg-mons-blue-1
                                hover:text-white
                                ease-in duration-300
                                lg:mt-8'>
                            Open Account
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
