import React from "react";
import ContentLeverage from "../containers/Content.Leverage";
import LandingBannerLeverage from "../containers/LandingBanner.Leverage";
import { BannerContainer } from '../components/Containers/BannerContainer'
import bgImage from "../assets/images/banners/leverage-banner.png";

const landingOptions = {
    image : bgImage,
    container : {
      width : 'w-full',
      padding : 'py-20'
    },
    header: {
      text : 'LEVERAGE INFORMATION',
      size: 'text-3xl',
      align: "text-center"
    },
    subHeader: {
        text : "",
        align: "text-left"
    }
  }

const Leverage = () => {
    return (
        <>
            <BannerContainer options={landingOptions}/>
            <ContentLeverage />
        </>
    )
}

export default Leverage