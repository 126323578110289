import React,{useState, useEffect} from 'react'
import { useSpring, animated } from "react-spring";
import AnimatedArrow from '../../assets/icons/animated-card-arrow.svg'


export const AnimatedCard = () => {

  const [pageWidth, setPageWidth] = useState(0)

  const handleWindowResize = () => {
    setPageWidth(window.innerWidth);
  }

  useEffect(() => {
    handleWindowResize();
    setIsHover('DEMO')
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const [isOpened, setIsOpened] = useState('DEMO')
  const [key, setKey] = useState(1);
  const [isHover, setIsHover] = useState('')
  const [whichButton, setWhichButton] = useState({header: "DEMO", desc: "Are you ready for a free real account experience? With Demo Account, you can try yourself for free."})

  const buttonHandler = (card) => {
    setWhichButton(card)
    setIsHover(card.header)
  }

  const scrollingCard = useSpring({
    from: { transform: "translate(0%,0)" },
    to: { transform: "translate(-880%,0)" },
    config: { duration: 20000 },
    reset: true,
    // reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    }
  });

    const animatedCards = [
        { header: "DEMO", desc: "Are you ready for a free real account experience? With Demo Account, you can try yourself for free." },
        { header: "CENT", desc: "Be ready to trade for less risk. Suitable for beginners with low capital and low risk." },
        { header: "CLASSIC", desc: "You are ready to trade. Trade with standard lot sizes and balanced risk ratios." },
        { header: "PRIME", desc: "Advantage of the trade on the best market. Trade like a pro with Prime Account." },
        { header: "ECN", desc: "For market professionals an exclusive conditions. Experience direct market access and many top-tier advantages." },
        { header: "ISLAMIC", desc: "Swap-free accounts are charged a fixed forex comission." },
    ]

  return (
    <>
    <div className='lg:block hidden py-20'>
      <div className='grid grid-cols-8 gap-2'>
      {animatedCards.map((card)=> {
        if(isOpened == card.header){
          return(
            <div onMouseEnter={() => setIsHover(card.header)} onMouseLeave={() => setIsHover(key)} key={key} className='relative overflow-hidden grid col-span-3 mx-auto bg-[#F8F8F8] text-center items-center xl:w-[450px] w-[350px] h-[400px] p-16 rounded-lg'>
              <div>
                <p className='text-4xl font-bold'>{card.header}</p>
                <p className='leading-3 font-semibold'>account</p>
              </div>
              <div>
                <p>{card.desc}</p>
              </div>
              <div className='z-20'>
                <button className='bg-monstrade-blue text-white font-semibold rounded-3xl p-2 px-4'>
                  <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">Open Account</a>
                </button>
              </div>
              <div className='overflow-hidden z-0 overflow-x-hidden'>
                <animated.div className={"text-[200px] leading-[200px] whitespace-nowrap tracking-[0.1em] pl-16 font-bold z-0 overflow-hidden absolute bottom-0"} 
                style={
                  isHover == card.header ? scrollingCard : key
                }>
                  <p className=' outlined-text text-opacity-0'>{card.header}</p>
                </animated.div>
              </div>
            </div>
          )
        }
        else{
          return(
            <div onMouseEnter={()=>setIsOpened(card.header)} className='relative mx-auto bg-[#F8F8F8] text-center h-[400px] xl:w-[150px] w-[120px]'>
              <div className='verticaltext'>
                  <p className='text-4xl font-bold mt-40'>{card.header}</p>
                  <p className='leading-3 font-semibold'>account</p>
              </div>
              <div>
                <img className='absolute bottom-4 left-16' src={AnimatedArrow} alt="" />
              </div>
            </div>
            )
        }
      
      })}
      </div>
    </div>

    <div className='lg:hidden block py-12'>
      <div>
        <div className='flex gap-4 sm:justify-center overflow-x-auto pb-4 text-center'>
          {animatedCards.map((card)=> {
            return(
            <button onClick={()=>buttonHandler(card)} className={`${whichButton.header == card.header ? "border-monstrade-blue" : ""} border-[2px] p-2 rounded-2xl`}>
              <p className='font-bold'>{card.header}</p>
            </button>
            )
          })}
        </div>
        <div onMouseEnter={() => setIsHover(whichButton.header)} onMouseLeave={() => setIsHover(key)} key={key}  className='relative overflow-hidden grid mx-auto bg-[#F8F8F8] text-center items-center h-[400px] p-16 rounded-lg'>
              <div>
                <p className='text-4xl font-bold'>{whichButton.header}</p>
                <p className='leading-3 font-semibold'>account</p>
              </div>
              <div>
                <p>{whichButton.desc}</p>
              </div>
              <div className='z-20'>
                <button className='bg-monstrade-blue text-white font-semibold rounded-3xl p-2 px-4'><a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">Open Account</a></button>
              </div>
              <div className='overflow-hidden z-0 overflow-x-hidden'>
                <animated.div className={"text-[200px] leading-[200px] whitespace-nowrap tracking-[0.2em] pl-16 font-bold z-0 overflow-hidden absolute bottom-0"} 
                style={
                  isHover == whichButton.header ? scrollingCard : key
                }>
                  <p className=' outlined-text text-opacity-0'>{whichButton.header}</p>
                </animated.div>
              </div>
        </div>
      </div>
    </div>
    </>
  )
}
