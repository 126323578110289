import React from "react";

const TradeDemoContent = () => {
    return(
        <>
            <div className="p-8 text-gray-800 md:text-left text-center">
                <p>
                    An excellent offer for those who are ready to switch from a demo to a live account. 
                    Make a profit on your demo account and transfer it to your live trading account. It’s as simple as that!
                    Open a demo account, trade successfully using virtual funds within 5 days.
                    On the 5th day, lock in your profits. Open a live trading account
                    Fund your live trading account for the amount equal to or greater than the profit received on your demo account.
                    Contact MonsTrade customer support to add the profit from the demo account to your live trading account.<br/><br/>
                </p>
                <p className="font-bold text-black">
                    Bonus terms and conditions:<br/><br/>
                </p>
                
                <p>
                    The offer is only available for trading Forex instruments on all accounts in MetaTrader 5 platform only.
                    <br/><br/>
                    To get the offer, you need to fund your live trading account for the amount equal to or greater than the profit received on your demo account.
                    <br/><br/>
                    If the profit on the demo account is more than the amount that you are ready to deposit into your live trading account, you can negotiate the bonus amount individually.
                    <br/><br/>
                    To receive the bonus, you need to fund your live trading account no later than 5 days after you make a profit on your demo account.
                    <br/><br/>
                    The bonus is credited only to verified clients of the company.
                    <br/><br/>
                    Bonus is paid in the form of non-withdrawable credits and displayed in the Credit line of the MetaTrader 5 platform. Bonus funds are not added to the real funds on your account balance.
                    <br/><br/>
                    Only closed trades are included in the turnover calculations. The minimum price change per trade – 50 pips.
                    <br/><br/>
                    Only new trades are taken into account in calculations of the turnover. Trading account history doesn’t affect turnover calculations.
                    <br/><br/>
                    Earned profit can be withdrawn with no restrictions, regardless of the amount of bonus funds in the account.
                    <br/><br/>
                    The maximum amount of bonus funds per client – $1000.
                    <br/><br/>
                    This offer is not cumulative with any other MonsTrade’ bonuses and promotions.
                    <br/><br/>
                    If fraudulent activity with credit funds is detected, the Company reserves the right to block the client’s trading account and write off all bonus payments from the trading account without prior notification and explanation.
                    <br/><br/>
                    *How to calculate trading turnover required for bonus withdrawal?
                    The turnover required for bonus withdrawal will be calculated according to the formula: bonus amount / 50 USD. That is,    50 USD per each full lot. The bonus will not be available for withdrawal 
                </p>    
            </div>
        </>
    )
}

export default TradeDemoContent;