import React from 'react'
import Trustpilot from '../Trustpilot';
import MonstarIco from '../../assets/images/monstar-logo.png'
import Monstore from '../../assets/images/store-images/monstore-menu.svg'

export const NavItem = ({ item }) => {
    return (
        <li className="nav-item dropdown static">
            <a className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap" href="#" type="button" id="dropdownMenuButtonX" data-bs-toggle="dropdown"
                aria-expanded="false">{item.name}
            </a>
            <div className="dropdown-menu w-[100vw] max-w-[1440px]
                        mt-0 sm:hidden shadow-lg bg-mons-gray-1 left-0
                        absolute top-full ml-0  min-[1440px]:ml-[-35px]
                        2xl:h-[644px] md:h-[640px]"
                aria-labelledby="dropdownMenuButtonX">
                <div className="grid grid-cols-4 px-6 lg:px-8 py-5">
                        <div className="lg:ml-10 pr-2 border-r-2 border-gray-300" >
                            <p className="text-lg pb-3 text-monstrade-blue font-bold text-1xl">{item.header}</p>
                            <p className="py-3" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                        <div className='col-span-3 grid grid-cols-3 gap-4'>
                        
                        {item.subItems.length > 0 ?
                            item.subItems.map((lvl1) => {
                                if(lvl1.status === true) return (
                                    <div className="ml-5 pr-10" key={lvl1.id} >
                                        <div className='flex items-center'>
                                        {lvl1.image &&
                                            <div>
                                                <img className='pr-4' src={lvl1.image} alt="" />
                                            </div>
                                        }
                                        <p className="pb-3 text-monstrade-blue font-bold text-1xl" >{lvl1.name == "MONSTAR" ? <a href='/accounts/trading/monstar'><img className='max-w-[100px]' src={MonstarIco} alt="" /></a> : lvl1.name}</p>
                                        </div>
                                        <div>
                                            {lvl1.subItems && lvl1.subItems.length > 0 ?
                                                lvl1.subItems.map((lvl2) => {
                                                    if(lvl2.status === true && lvl1.name == "MONSTAR") return (
                                                        <div className="border-b-2 border-gray-300 flex" key={lvl2.id}>
                                                            <a href={lvl2.link} className="py-3"> <div className='flex'><p>{lvl2.name}</p> {lvl2.image && <img className='pl-2' src={lvl2.image}/>}</div> </a>
                                                            {/* <p className="py-3" > {lvl2.name} </p> */}
                                                        </div>
                                                        
                                                    )
                                                    else{
                                                        return (
                                                            <div className="border-b-2 border-gray-300 flex" key={lvl2.id}>
                                                                <a href={lvl2.link} className={`${lvl2.name == "MonsTrade Store" && 'hidden'} py-3`}> <div className='flex'><p>{lvl2.name}</p> {lvl2.image && <img className='pl-2' src={lvl2.image}/>}</div> </a>
                                                                <a href={lvl2.link}> {lvl2.name == "MonsTrade Store" && <img className='pb-[22px]' src={Monstore} alt="" />}</a>
                                                                {/* <p className="py-3" > {lvl2.name} </p> */}
                                                            </div>
                                                        )
                                                    }
                                                })
                                                : ''}
                                                
                                        </div>
                                        
                                    </div>
                                )
                            }) : ""
                        }
                    </div>
                </div>
                <div className='absolute bottom-0 mx-auto w-full'>
                    <Trustpilot/>
                </div>
            </div>
        </li>
    )
}
