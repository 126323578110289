import React from 'react'

import { TabsComponent } from '../components/V2Areas/TabsComponent'
import { HeaderSlider } from '../components/V2Areas/HeaderSlider'
import { DropdownV2 } from '../components/V2Areas/DropdownV2'
import { SliderWithImage } from '../components/V2Areas/SliderWithImage'
import { AnimatedCard } from '../components/V2Areas/AnimatedCard'
import { SliderV2 } from '../components/V2Areas/SliderV2'

import WhyMons1 from '../assets/icons/whymons-ico1.gif'
import WhyMons2 from '../assets/icons/whymons-ico2.gif'
import WhyMons3 from '../assets/icons/whymons-ico3.gif'
import WhyMons4 from '../assets/icons/whymons-ico4.svg'

import Icon1 from '../assets/icons/step1-ico.svg'
import Icon2 from '../assets/icons/step2-ico.svg'
import Icon3 from '../assets/icons/step3-ico.svg'
import Icon4 from '../assets/icons/step4-ico.svg'

import Img1 from '../assets/images/v2-image-1.png'
import Img2 from '../assets/images/v2-image-2.png'
import Img3 from '../assets/videos/v2-image-3.mp4'
import Img4 from '../assets/images/v2-image-4.svg'
import QRcode from '../assets/images/v2-qrcode.png'
import ScanImage from '../assets/images/v2-scan.svg'
import MonstarIco from '../assets/images/monstar-white-logo.svg'
import Review from '../assets/images/v2-review.svg'
import GreenTick from '../assets/icons/v2-greentick.svg'
import Award from '../assets/icons/award_star.svg'

import Google from '../assets/images/home-google-store.png'
import Apple from '../assets/images/home-app-store.png'

import Image1 from '../assets/images/homev2-image.png'
import Image1M from '../assets/images/homev2-image-m.png'
import QRPlatform from '../assets/icons/platform-qr.svg'

import Video1 from '../assets/videos/pamikomamiko.mp4'
import BannerVideo from '../assets/videos/v2-banner-video.mp4'
import Gif from '../assets/videos/tabs-gif.gif'

const content = [
    { header: "MT5 WEBTRADER", image: require('../assets/images/platform-logo/web.png') },
    { header: "MT5 FOR PC", image: require('../assets/images/platform-logo/windows.png') },
    { header: "MT5 FOR IPHONE", image: require('../assets/images/platform-logo/iphone.png') },
    { header: "MT5 FOR MAC", image: require('../assets/images/platform-logo/mac.png') },
    { header: "MT5", image: require('../assets/images/platform-logo/mt5-ico.png') },
    { header: "MT5 FOR ANDROID", image: require('../assets/images/platform-logo/android.png') },
   
]

export const HomeV2 = () => {

  return (
    <>

        <div className='max-w-[1440px] h-[850px] mx-auto'>
            <div className='relative pt-8'>
                <div className='mx-auto'>
                    <video autostart autoPlay muted loop playsInline src={BannerVideo} type="video/mp4" />
                </div>
                <div className='sm:mx-auto mx-4 md:max-w-[500px] pt-4 md:absolute lg:top-72 md:top-48 left-8'>
                    <p className='text-[40px] font-bold leading-10'>Market Trading with <span className='text-monstrade-blue'><br/>a Licensed Broker</span></p>
                    <p className='py-6 text-[22px]'>Invest Forex, Commodities, Bonds, Metals, Energies, Shares, Indices and more product with quick deposits and withdrawals.</p>
                    <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard"><button className='bg-monstrade-blue text-white font-semibold rounded-3xl p-2 px-4'>TRADE NOW</button></a>
                </div>
            </div>
        </div>

        <div className='max-w-[1440px] mx-auto'>
            <div className='grid lg:grid-cols-5 text-center items-center py-20'>
                <div className='flex-1'>
                    <p class="bg-gradient-to-r lg:text-left text-center text-3xl font-bold from-[#61DC9A] via-[#16D0EA] to-[#0052FF] inline-block text-transparent bg-clip-text"><span className='font-normal'>Why</span><br/>MonsTrade?</p>
                </div>
                <div>
                    <img className='mx-auto w-24' src={WhyMons1} alt="" />
                    <hr className='my-2' />
                    <p>Special Campaigns for You</p>
                </div>
                <div>
                    <img className='mx-auto w-24' src={WhyMons2} alt="" />
                    <hr className='my-2' />
                    <p>Trade with 100+ Assets</p>
                </div>
                <div>
                    <img className='mx-auto w-24' src={WhyMons3} alt="" />
                    <hr className='my-2' />
                    <p>A Regulated Broker!</p>
                </div>
                <div>
                    <img className='mx-auto' src={WhyMons4} alt="" />
                    <hr className='my-2' />
                    <p>The New Generation Trade Assistant</p>
                </div>
            </div>
        </div>

        <div className='max-w-[1440px] mx-auto'>
           <AnimatedCard/> 
        </div>
        
        <div className='mx-auto'>
            <div className='bg-gradient-to-r from-black to-[#0052FF] py-20'>
                <div className='lg:flex gap-8 text-white items-center justify-center'>
                    <div className='text-center lg:pb-0 pb-10'>
                        <p className='text-3xl pb-3'>Open an Account Today</p>
                        <div>
                            <button className='bg-transparent border-[1px] border-white rounded-2xl p-1 px-4'><a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">Demo Account</a></button>
                            <button className='bg-monstrade-blue rounded-2xl p-1 px-4 ml-2'><a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">Live Account</a></button>
                        </div>
                    </div>
                    <div className='lg:flex flex-row'>
                        <div>
                            <img className='lg:mx-0 mx-auto lg:pl-0 pl-[100px]' src={Icon1} alt="" />
                            <p className='max-w-[90px] lg:mx-0 mx-auto text-center lg:ml-[-12px]'><span className='font-bold text-lg'>Open</span><br/>An Account</p>
                        </div>
                        <div className='text-center'>
                            <img className='lg:mx-0 mx-auto' src={Icon2} alt="" />
                            <p><span className='font-bold text-lg'>Verify</span><br/>Your Info</p>
                        </div>
                        <div className='text-center'>
                            <img className='lg:mx-0 mx-auto' src={Icon3} alt="" />
                            <p><span className='font-bold text-lg'>Make</span><br/>A Deposit</p>
                        </div>
                        <div>
                            <img className='lg:mx-0 mx-auto lg:pr-0 pr-[100px]' src={Icon4} alt="" />
                            <p className='max-w-[90px] lg:mx-0 mx-auto text-center lg:float-right lg:mr-1'><span className='font-bold text-lg'>Start</span><br/>To Invest</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='max-w-[1440px] mx-auto'>
            <div className='grid lg:grid-cols-2 mx-2 gap-4 items-center py-20 max-w-[1608px] mx-auto'>
                <div className='max-w-[600px] mx-auto sm:order-auto order-2'>
                    <p className='text-2xl'>Our Users are Safe with Solid Regulations</p>
                    <p className='py-3'>FinCEN is one of the units that regulate financial institutions in the United States and is affiliated with the US Department of the treasury. It’s purpose is to combat financial crimes and ensure the financial security of users.MonsTrade is regulated by FinCEN. As a result of these regulations, we provide more transparent and higher quality services to our users. The security of our users is always our top priority.</p>
                    <a href="/about/company/regulations"><button className='bg-transparent border-[1px] border-monstrade-blue text-monstrade-blue rounded-2xl p-1 px-4'>About Regulations</button></a>
                </div>
                <div>
                    <img className='mx-auto' src={Img1} alt="" />
                </div>
            </div>
        </div>

        <div className='max-w-[1440px] mx-auto'>
            <DropdownV2/>
        </div>
       
        <div className='mx-auto bg-monstrade-blue'>
            <div className='max-w-[1440px] mx-auto'>
                <div>
                <div className='grid lg:grid-cols-10 items-center py-20'>
                    <div className='lg:col-span-2 lg:order-first lg:pt-0 pt-10 order-last'>
                        <a href="https://apps.apple.com/tr/app/monstar/id6472909004?l=tr"><img className='mx-auto mb-2' src={Apple} alt="" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.monstrade.monstar&pcampaignid=web_share"><img className='mx-auto mb-2' src={Google} alt="" /></a>
                        <img className='lg:block hidden mx-auto mb-2' src={ScanImage} alt="" />
                        <img className='lg:block hidden mx-auto' src={QRcode} alt="" />
                    </div>
                    <div className='lg:col-span-3 lg:order-first order-2'>
                        <img src={Img2} alt="" />
                    </div>
                    <div className='lg:col-span-5 lg:ml-8 mx-4 text-white'>
                        <img src={MonstarIco} alt="" />
                        <p className='text-3xl py-2'>The New Generation Trade Assistant</p>
                        <p>The world of Forex is breaking new ground with Monstar. With Monstar, you can have access to automatic buy & sell signals, forex news, technical and fundamental analyses, educational content on investment and forex topics, market volume data, a wide range of deposit and withdrawal options, and many other features for free.</p>
                        <div className='grid lg:grid-cols-2 gap-2 py-8'>
                            <div className='flex items-center'>
                                <img src={GreenTick} alt="" />
                                <p>Buy & sell signals</p>
                            </div>
                        <div className='flex items-center'>
                                <img src={GreenTick} alt="" />
                                <p>Forex news</p>
                        </div>
                        <div className='flex items-center'>
                                <img src={GreenTick} alt="" />
                                <p>Technical & fundamental analysis</p>
                            </div>
                        <div className='flex items-center'>
                                <img src={GreenTick} alt="" />
                                <p>Educations</p>
                        </div>
                        <div className='flex items-center'>
                                <img src={GreenTick} alt="" />
                                <p>Trading volume</p>
                            </div>
                        <div className='flex items-center'>
                                <img src={GreenTick} alt="" />
                                <p>Deposit & withdraw options</p>
                        </div>
                        </div>
                        <img className='lg:block hidden' src={Review} alt="" />
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div className='max-w-[1440px] mx-auto'>
            <SliderWithImage/>
        </div>
        <div className='mx-auto sm:bg-[#F4F4F4]'>
            <div className='max-w-[1440px] mx-auto'>
                <SliderV2/>
            </div>
        </div>
        

        <div className='max-w-[1440px] mx-auto'>
            <img className='md:block hidden' src={Image1} alt="" />
            <img className='md:hidden block' src={Image1M} alt="" />
        </div>

        <div className='max-w-[1440px] mx-auto'>
            <div className='grid lg:grid-cols-2 pt-32 py-20 lg:mx-16 mx-4 items-center'>
                <div>
                    <p className='text-3xl'>Trade with <span className='text-monstrade-blue'>MonsTrade</span> at all platforms</p>
                    <p className='py-4'>Customized MetaTrader 5 from MonsTrade have all the powerful tools that you need to trade on both Windows and mobile devices.</p>
                    <video autostart autoPlay muted loop playsInline src={Img3} type="video/mp4" />
                </div>
                <div>
                    <div className='flex items-center bg-[#F2F2FA] p-6 w-[360px] gap-8 mx-auto'>
                        <img src={QRPlatform} alt="" />
                        <div>
                            <p>Scan to download</p>
                            <div className='flex text-center'>
                                <a target='_blank' href="https://apps.apple.com/tr/app/monstar/id6472909004?l=tr">
                                    <button className='p-[6px]'>IOS</button>
                                </a>
                                <a target='_blank' href="https://play.google.com/store/apps/details?id=com.monstrade.monstar&pcampaignid=web_share">
                                    <button className='border-2 p-1 px-2 rounded-3xl ml-2 border-black'>Android</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="lg:columns-3 columns-2 font-semibold items-center justify-center py-12">
                        {content.map((item, index) => {
                            return (
                                    <div key={index} >
                                        <div className="rows-2 py-4">
                                            <div className="flex pb-3 ">
                                                <img className="m-auto h-10 " src={item.image} alt="" />
                                            </div>
                                            <div className="text-center" >
                                                <p>{item.header}</p>
                                            </div>
                                        </div>
                                    </div>
                            )
                        })}
                    </div>
                    <p className='text-center'><a href="/platforms/computer/mt5-for-pc#for-pc">More download options ➔</a></p>
                </div>
            </div>
        </div>

        <div className='max-w-[1440px] mx-auto'>
            <div className='grid lg:grid-cols-2 gap-4 items-center mx-4 py-20'>
                <div className='max-w-[600px] mx-auto lg:text-left text-center'>
                    <p className='text-3xl'>Try Different Approaches to Copy Trading with <span className='text-monstrade-blue font-semibold'>PAMM and MAM</span></p>
                    <p className='py-4'>Join the most transparent social trading network! Copy Top Performing Leaders from different Brokers easily and reach your investment goals!</p>
                    <button className='bg-monstrade-blue rounded-2xl text-white p-1 px-4'><a href="/partnerships/services/mam">Request It</a></button>
                </div>
                <div className='mx-auto'>
                    <video autostart autoPlay muted loop playsInline src={Video1} type="video/mp4" />
                </div>
            </div>
        </div>

        <div className='max-w-[1440px] mx-auto'>
            <HeaderSlider/>
        </div> 

        <div className='max-w-[1440px] mx-auto'>
            <div>
                <div className='grid lg:grid-cols-2 gap-4 py-20 items-center bg-[#F4F4F4] p-4'>
                    <div>
                        <TabsComponent/>
                        <div className='text-center'>
                            <a href="/partnerships/for-partners/partner"><button className='bg-monstrade-blue text-white font-semibold rounded-3xl p-2 px-4'>Become Introducer</button></a>
                        </div>
                    </div>
                    <div>
                        <img src={Gif} alt="" />
                    </div>
                </div>
            </div>
        </div>

    <div className='max-w-[1440px] mx-auto'>
        <div className='text-center pt-10'>
        <p className='text-4xl'>Unleash Your Trading Potential<br/>with <span className='font-bold'>MonsTrade Trading Account</span></p>
            <div className='lg:flex pt-8 max-w-[1000px] mx-auto'>
                <div className='flex rounded-lg bg-gray-100 items-center p-4 py-8 mb-2 w-[300px] mx-auto'>
                    <img className="flex" src={Award} alt="" />
                    <div className="flex-1">
                        <p>Min. deposit</p>
                        <p className='text-monstrade-blue font-bold'>$100</p>
                    </div>
                    <img className="flex" src={Award} alt="" />
                </div>
                <div className='flex rounded-lg bg-gray-100 items-center p-4 py-8 mb-2 w-[300px] mx-auto'>
                    <img className="flex" src={Award} alt="" />
                    <div className="flex-1">
                        <p>Max. Leverage</p>
                        <p className='text-monstrade-blue font-bold'>1:500</p>
                    </div>
                    <img className="flex" src={Award} alt="" />
                </div>
                <div className='flex rounded-lg bg-gray-100 items-center p-4 py-8 mb-2 w-[300px] mx-auto'>
                    <img className="flex" src={Award} alt="" />
                    <div className="flex-1">
                        <p>Spread</p>
                        <p className='text-monstrade-blue font-bold'>From 0.1</p>
                    </div>
                    <img className="flex" src={Award} alt="" />
                </div>
            </div>
        </div>
    </div>
        
    <div className='max-w-[1440px] mx-auto'>
            <div className='lg:flex lg:py-8 max-w-[680px] mx-auto'>
                <div className='flex rounded-lg bg-gray-100 items-center text-center p-4 py-8 mb-2 w-[300px] mx-auto'>
                    <img className="flex" src={Award} alt="" />
                    <div className="flex-1">
                        <p>Swap free**</p>
                        <p className='text-monstrade-blue font-bold'>Yes</p>
                    </div>
                    <img className="flex" src={Award} alt="" />
                </div>
                <div className='flex rounded-lg bg-gray-100 items-center text-center p-4 py-8 mb-2 w-[300px] mx-auto'>
                    <img className="flex" src={Award} alt="" />
                    <div className="flex-1">
                        <p>Spread Type</p>
                        <p className='text-monstrade-blue font-bold'>Fixed / Variable</p>
                    </div>
                    <img className="flex" src={Award} alt="" />
                </div>
            </div>
            <div className='text-center mx-auto'>
                <p className='text-sm'>**Only for certain products. For more information click <a className='text-monstrade-blue underline' href="">here.</a></p>
                <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard"><button className='bg-[#00D17F] text-white font-semibold rounded-3xl my-3 p-2 px-4'>Open Account</button></a>
                <p className='text-sm text-gray-400'>Trading CFDs involves significant risk of loss.</p>
            </div>
    </div>
           
    </>
  )
}
