import React from 'react';

const PrivacyPolicy = () => {

        return(
            <>
            <div className="container-full py-6">
                <div className="container-max mx-auto">
                                <span className='block font-bold text-3xl py-4'>Privacy Policy</span>
                    Protecting the privacy and safeguarding the personal and financial information of our clients and website visitors is one of our highest priorities. The following Privacy Statement explains how Monstrade Dooel Skopje collects and protects your personal information. References to "Monstrade Dooel Skopje" in this Privacy Statement include all Monstrade Dooel Skopje companies and divisions, including monstrade.com.
                    By opening a Monstrade Dooel Skopje account or by using Monstrade Dooel Skopje websites, you give your consent to the collection and use of personal information by Monstrade Dooel Skopje as explained in this Privacy Statement.
                                <span className='block font-bold text-3xl py-4'>Collection of personal information</span>
                    We collect your personal information directly from you and indirectly as a result of your relationship with us. Monstrade Dooel Skopje may use the information collected from you to verify your identity and contact information, establish your trading account, issue you an account number and a secure password, maintain your account activity and contact you with account information, among other reasons. This information also helps us improve our services to you, customize your browsing experience and inform you about additional products, services or promotions that may be of interest to you.
                    You directly provide us with the majority of information we collect. This information includes your contact details, including name, mailing address, telephone number and e-mail address, personal identification data we are required by law to collect, including your passport number and/or tax identification number, and information required by law to assess your trading experience and risk tolerance, including your annual income, net worth and available risk capital. We also collect demographic information when you open an account, including gender, birth date, occupation and employment status.
                    You provide us with this information by completing the account application and related documentation, by sending us an e-mail or by submitting information in response to a promotion or special offer.
                    The information we collect indirectly from you includes your Internet protocol ("IP") address, browser type, operating system, Internet service provider (ISP), time stamps and transaction history.
                    Should you close your account with us, Monstrade Dooel Skopje will retain your information, but only use it to comply with regulatory requirements and to periodically contact you to offer you the opportunity to reactivate your account or participate in other offers.
                                <span className='block font-bold text-3xl py-4'>Cookies</span>
                    We use cookies and web beacons (also known as action tags or single-pixel gifs), and other technologies (collectively, "cookies") to provide us with data we can use to improve your experience and to know you better. Cookies are small text files sent from Web servers that may be stored on your computer. Cookies enable us to capture how you arrived at our site, when you return, which pages on our site you visit, and to recognize that you are already logged on when we receive a page request from your browser. We may link the information we store in cookies to personally identifiable information you submit while on our site. We will never share this information with any 3rd party. Cookies are readable only by us and do not contain any personal information nor do they contain account or password information. We cannot and will not gather information about other sites you may have visited.
                    We may share usage information about visitors to our websites with reputable advertising companies for the purpose of targeting our Internet banner advertisements. The information collected by the advertising company through the use of these pixel tags is not personally identifiable. We may also use 3rd party software to track and analyze usage and volume statistical information including page requests, form requests and click paths. The third party may use cookies to track behavior and may set cookies on behalf of Monstrade Dooel Skopje.
                    All web browsers have settings that allow you to block cookies. By visiting our website with your browser set to allow cookies, you consent to our use of cookies as described above. If you choose to block cookies you may use our services, but some functions may not work as designed.
                                <span className='block font-bold text-3xl py-4'>Sharing Personal Information with Monstrade Dooel Skopje affiliates and Non-affiliated third parties</span>
                    Monstrade Dooel Skopje does not sell, license, lease or otherwise disclose your personal information to any affiliate or third party for any reason, except as described below.
                    Monstrade Dooel Skopje may share information with affiliates if the information is required to provide the product or service you have requested or to provide you with the opportunity to participate in the products or services our affiliates offer.
                    To help us improve our services to you, we may engage another business to help us carry out certain internal functions, such as account processing, fulfillment, client service, client satisfaction surveys or other data collection activities relevant to our business. We may also provide a non-affiliated third party with client information from our database, including your name, address, phone number, and/or e-mail address, to help us analyze and identify client needs, notify clients of product and service offerings or conduct general marketing and market research for us.
                    Monstrade Dooel Skopje also forges partnerships and alliances with non-affiliated third parties which offer high-quality products and services that might be of value to our clients. In order to ensure that these products and services meet your needs and are delivered in a manner that is useful and relevant, Monstrade Dooel Skopje may share some personal information with these non-affiliated third parties.
                    We generally require that all non-affiliated third parties to which we provide your nonpublic personal information agree to keep your information confidential and use such information solely for the limited purpose for which we have engaged them, or as otherwise required by law. In addition, where offers are made to you by non-affiliated third parties, we generally require that they disclose that the offer is being extended because of your relationship with us. We also seek to ensure that these non-affiliated third parties maintain appropriate data security procedures to guard against unauthorized use of, or access to, your personal information.
                    When required by law, Monstrade Dooel Skopje reserves the right to disclose your personal information to third parties or regulatory, law enforcement or other government authorities. We may also disclose your information to credit reporting or collection agencies, or when necessary to protect our rights or property.
                    If at any time you choose to purchase a product or service offered by another company, for example by clicking on an advertisement on a website owned or controlled by Monstrade Dooel Skopje, any personal information you share with that company will no longer be controlled under our Privacy Statement. We are not responsible for the privacy policies or the content of sites we link to, and we have no control over the use or protection of information provided by you to, or collected by, those websites. Whenever you elect to link to a co-branded website or to a linked website, you may be asked to provide registration or other information. Please note that the information you are providing is going to a third party, and you should familiarize yourself with the privacy policy published by that third party.
                                <span className='block font-bold text-3xl py-4'>The security of your personal information</span>
                    We employ security software, systems and procedures to offer you a safe and secure trading environment and to protect your personal, financial and trading information.
                    When you open an account with us, you are issued a unique account number, User ID and a password. Only a limited number of Monstrade Dooel Skopje employees who have a need to know this information will have access to your account number, UserID, and password. Remember that you are ultimately responsible for maintaining the secrecy of your account number, UserID and password. We strongly recommend that you do not disclose this information to anyone.
                    On our websites and online account applications we use technology to encrypt information transmitted by or to you. We use certificate authority (CA) certificates to authenticate our websites and secure trading and customer-facing applications, allowing you to verify that you are connected to our websites.
                                <span className='block font-bold text-3xl py-4'>Changes to this Privacy Statement and Opting Out</span>
                    In the event Monstrade Dooel Skopje materially changes this Privacy Statement, the revised Privacy Statement will promptly be posted to our websites. By opening an account with us, you are deemed to agree to accept posting of a revised Privacy Statement electronically on our website as actual notice to you.
                    Under certain circumstances, such as with respect to our sharing of your personal information with non-affiliated third parties, you may inform us that you would like to "opt out" by contacting us through the contact information listed below. Note that for joint accounts, an-opt out election made by one account owner of a joint account is applicable to all account owners of the joint account. An opt-out election must be made for each separate account you hold at Monstrade Dooel Skopje.
                    Any dispute over our Privacy Statement is subject to this notice and the Monstrade Dooel Skopje Customer Agreement. If you have any questions that this Privacy Statement does not address, please contact a Client Services representative.
                    You may contact Monstrade Dooel Skopje for any reason under this Privacy Statement, or otherwise, as follows:

                    <p className='block py-4'>
                        Call Us: +44 20 3807 5102<br/>
                        International: +44 20 3807 5102<br/>
                        Email: support@monstrade.co<br/>
                        Postal:<br/>
                        Monstrade Dooel Skopje<br/>
                        Attention: Privacy Issues<br/>
                        Str.Kozara No:68-2/6 1000<br/>
                        Skopje /North Macedonia 
                    </p>
                </div>
            </div>
           


            </>
        )

}


export default PrivacyPolicy;
