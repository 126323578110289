import React from 'react'

export const SimpleCard = ({header, link, description, icon, btnText, textClass}) => {
    return (
        <a href={link} key={header} className={`${textClass? textClass : ''}`} >
            <div className="grid-rows-3" >
                <div className="flex justify-center md:h-[9vw] h-[80px]">
                    <img src={icon} alt="" className="py-4" />
                </div>
                <div className="flex justify-center" >
                    <p className="font-semibold md:text-md text-lg pt-2" >{header}</p>
                </div>
                <div className="flex justify-center" >
                    {description &&
                        <p dangerouslySetInnerHTML={{__html : description}} className="text-center text-sm py-1" ></p>
                    }
                    {btnText &&
                        <div className='flex py-2' >
                            <a href="#anchor" className="bg-monstrade-blue hover:bg-monstrade-green text-white px-2 py-1 rounded-md" > View Spreads </a>
                        </div>
                    }
                </div>
            </div>
        </a>
    )
}
