import MonstarIco from '../../assets/images/monstar-logo.png'

import Computer from '../../assets/icons/menu-computer.svg'
import Tablet from '../../assets/icons/menu-tablet.svg'
import Phone from '../../assets/icons/menu-phone.svg'
import Windows from '../../assets/icons/menu-windows.svg'
import Ios from '../../assets/icons/menu-apple.svg'
import Android from '../../assets/icons/menu-android.svg'

const menuContent = () => {
    return (
        [
            {   id: 0, 
                name: 'Trading',
                header: 'TRADING',
                image: '', icon:'',
                description: 'Monstrade different options of Trading Accounts with conditions to meet the needs of both novice and experienced traders. <br/><br/><br/> Risk Warning: Trading on margin products a high level of risk.',
                status : true, link: '/trading', subItems : [
                    {   id: 6, 
                        name: 'TRADING INSTRUMENTS',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/trading/trading-instrument', subItems: [
                            {   id: 7, 
                                name: 'Forex',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/forex', subItems: [
                                    
                                ]
                            },
                            {   id: 8, 
                                name: 'Indices',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/indices', subItems: [
                                    
                                ]
                            },
                            {   id: 9, 
                                name: 'Metals',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/metals', subItems: [
                                    
                                ]
                            },
                            {   id: 10, 
                                name: 'Energies',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/energies', subItems: [
                                    
                                ]
                            },    
                            {   id: 11, 
                                name: 'Cryptocurrencies',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/crypto', subItems: [
                                    
                                ]
                            },  
                            {   id: 12, 
                                name: 'Stocks',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/stocks', subItems: [
                                    
                                ]
                            },                  
                        ]
                    },
                    {   id: 13, 
                        name: 'TRADING TOOLS',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/trading/trading-tools', subItems: [
                            {   id: 15, 
                                name: 'Economic Calendar',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/economic-calender', subItems: [
                                    
                                ]
                            },
                            {   id: 16, 
                                name: 'Forex News',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/forex-news', subItems: [
                                    
                                ]
                            },
                            {   id: 17, 
                                name: 'Leverage',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/leverage', subItems: [
                                    
                                ]
                            },
                            {   id: 18, 
                                name: 'Calculator',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/calculator', subItems: [
                                    
                                ]
                            }                
                        ]
                    },
                    {   id: 14, 
                        name: 'PRICING',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/trading/pricing', subItems: [
                            {   id: 21, 
                                name: 'Spreads',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/spreads', subItems: [
                                    
                                ]
                            },
                            {   id: 22, 
                                name: 'Swap Rates',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/swap-rates', subItems: [
                                    
                                ]
                            },
                            {   id: 23, 
                                name: 'Trading Hours',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/trading-hours', subItems: [
                                    
                                ]
                            },
                            {   id: 24, 
                                name: 'Holiday Trading Hours',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/holiday-trading-hours', subItems: [
                                    
                                ]
                            }                
                        ]
                    }
                ]},
                {   id: 1, 
                    name: 'Accounts',
                    header: 'ACCOUNTS',
                    image: '', icon:'',
                    description: 'Monstrade provides different account for the best trading experience. <br/><br/><br/>Risk Warning: Trading on margin products a high level of risk.',
                    status : true, link: '/accounts', subItems : [
                        {   id: 25, 
                            name: 'TRADING',
                            image: '', icon:'', 
                            description: '',
                            status : true, link: '/accounts/trading', subItems: [
                                {   id: 26, 
                                    name: 'Demo Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/demo-account', subItems: [
                                        
                                    ]
                                },
                                {   id: 27, 
                                    name: 'Cent Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/cent-account', subItems: [
                                        
                                    ]
                                },
                                {   id: 28, 
                                    name: 'Classic Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/classic-account', subItems: [
                                        
                                    ]
                                },
                                {   id: 29, 
                                    name: 'Prime Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/prime-account', subItems: [
                                        
                                    ]
                                },    
                                {   id: 30, 
                                    name: 'ECN Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/ecn-account', subItems: [
                                        
                                    ]
                                },  
                                {   id: 31, 
                                    name: 'Islamic Account ',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/islamic-account', subItems: [
                                        
                                    ]
                                },                  
                            ]
                        },
                        {   id: 32, 
                            name: 'EDUCATION',
                            image: '', icon:'', 
                            description: '',
                            status : true, link: '/accounts/education', subItems: [
                                {   id: 33, 
                                    name: 'Video Tutorials',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/education/video-tutorials', subItems: [
                                        
                                    ]
                                },
                                {   id: 34, 
                                    name: 'E-book',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/education/e-books', subItems: [
                                        
                                    ]
                                }             
                            ]
                        },
                    ]},
                    {   id: 2, 
                        name: 'Platforms',
                        header: 'PLATFORMS',
                        image: '', 
                        icon:'BeakerIcon',
                        description: 'Start trading your preferred instruments on the Monstrade MT5, which is available for both Windows and IOS. You could also try the Monstrade Mt5 WebTrader, which is instantly accessible from your browser.',
                        status : true, link: '/platforms', subItems : [
                            {   id: 35, 
                                name: 'COMPUTER',
                                image: Computer, 
                                icon:'faDisplay', 
                                description: '',
                                status : true, link: '/platforms/computer', subItems: [
                                    {   id: 36, 
                                        name: 'MT 5 for PC',
                                        image: Windows, icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/computer/mt5-for-pc#for-pc', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 37, 
                                        name: 'MT5 for Mac',
                                        image: Ios, icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/computer/mt5-for-mac', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 38, 
                                        name: 'MT5 Web Trader',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/computer/mt5-for-web', subItems: [
                                            
                                        ]
                                    }              
                                ]
                            },
                            {   id: 39, 
                                name: 'PHONE',
                                image: Phone, 
                                icon:'faMobileButton',
                                description: '',
                                status : true, link: '/platforms/phone', subItems: [
                                    {   id: 40, 
                                        name: 'MT 5 for Apple',
                                        image: Ios, icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/phone/mt5-for-apple', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 41, 
                                        name: 'MT5 for Android',
                                        image: Android, icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/phone/mt5-for-android', subItems: [
                                            
                                        ]
                                    }             
                                ]
                            },
                            {   id: 32, 
                                name: 'MONSTAR',
                                image: Phone, icon:{MonstarIco}, 
                                description: '',
                                status : true, link: '/accounts/trading/monstar', subItems: [
  
                                    {   id: 40, 
                                        name: 'Monstar for Apple',
                                        image: Ios, icon:'', 
                                        description: '',
                                        status : true, link: 'https://apps.apple.com/tr/app/monstar/id6472909004?l=tr', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 41, 
                                        name: 'Monstar for Android',
                                        image: Android, icon:'', 
                                        description: '',
                                        status : true, link: 'https://play.google.com/store/apps/details?id=com.monstrade.monstar&pcampaignid=web_share', subItems: [
                                            
                                        ]
                                    }         
                                ]
                            },
                            {   id: 42, 
                                name: 'TABLET',
                                image: Tablet, 
                                icon:'faTabletButton', 
                                description: '',
                                status : true, link: '/platforms/tablet', subItems: [
                                    {   id: 43, 
                                        name: 'MT 5 for Ipad',
                                        image: Ios, icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/tablet/mt5-for-ipad', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 44, 
                                        name: 'MT5 for Android',
                                        image: Android, icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/tablet/mt5-for-android', subItems: [
                                            
                                        ]
                                    }             
                                ]
                            }
                        ]},
                        {   id: 3, 
                            name: 'Advantages',
                            header: 'ADVANTAGES',
                            image: '', icon:'',
                            description: 'The privileges and advantages of MonsTrade will elevate you in the Forex world. Conduct higher volume trades with exclusive promotions and campaigns tailored to you, prevent your losses, and maximize your profits.',
                            status : true, link: '/', subItems : [
                                {   id: 49, 
                                    name: 'PROMOTIONS',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/promotions', subItems: [
                                         {   id: 46, 
                                            name: 'Cashback',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/cashback', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 47, 
                                            name: 'Trading Bonus',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/trading-bonus', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 50, 
                                            name: 'Zero Commission',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/zero-commission', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 51, 
                                            name: 'Trade Demo',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/trade-demo', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 52, 
                                            name: 'Invite a  Friend',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/invite-a-friend', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 53, 
                                            name: 'Account Transfer',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/account-transfer', subItems: [
                                                
                                            ]
                                        } ,
                                        {   id: 66, 
                                            name: 'VIP',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/vip', subItems: [
                                                
                                            ]
                                        }               
                                    ]
                                },
                                {   id: 49, 
                                    name: 'CAMPAIGNS',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/campaign', subItems: [
                                        {   id: 47, 
                                            name: 'Choose Product',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/choose-product', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 66, 
                                            name: 'Account Change',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/account-change', subItems: [
                                                
                                            ]
                                        }, 
                                        {   id: 66, 
                                            name: 'First Trade',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/first-trade', subItems: [
                                                
                                            ]
                                        } ,  
                                        {   id: 66, 
                                            name: 'Loss Refund',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/loss-refund', subItems: [
                                                
                                            ]
                                        } ,
                                        {   id: 52, 
                                            name: 'PAMM&MAM',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/pamm-n-mam', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 51, 
                                            name: 'Swap Day',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/swap-day', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 53, 
                                            name: 'MonsCash',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/mons-cash', subItems: [
                                                
                                            ]
                                        } ,
                                        {   id: 66, 
                                            name: 'Copy Trade',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/copy-trade', subItems: [
                                                
                                            ]
                                        } ,
                                        {   id: 53, 
                                            name: 'MonsCredit',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/mons-credit', subItems: [
                                                
                                            ]
                                        } ,
                                        {   id: 46, 
                                            name: 'Trade Refund',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/campaign/trade-refund', subItems: [
                                                
                                            ]
                                        },
                                        
                                        
                                        
                                        
                                        
                                        
                                                    
                                    ]
                                },
                                // {   id: 49, 
                                //     name: 'SPECIAL',
                                //     image: '', icon:'', 
                                //     description: '',
                                //     status : true, link: '/special', subItems: [
                                //         {   id: 50, 
                                //             name: 'Christmas Draw',
                                //             image: '', icon:'', 
                                //             description: '',
                                //             status : true, link: '/special/christmas-draw', subItems: [
                                                
                                //             ]
                                //         },
                                //     ]
                                // }
                                {   id: 49, 
                                    name: 'PRIVILEGES',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/privileges', subItems: [
                                        {   id: 50, 
                                            name: 'MonsTrade Store',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/privileges/store', subItems: [
                                                
                                            ]
                                        },
                                    ]
                                }
                            ]},
                            {   id: 4, 
                                name: 'About',
                                header: 'ABOUT US',
                                image: '', icon:'',
                                description: 'Monstrade is an international broker with 6 asset types and high number of  trading instruments. We are very proud of the services we provide to our clients and partners, which are of the same quality for all, regardless of experience or investment amount. <br/><br/><br/>Risk Warning: Trading on margin products a high level of risk.',
                                status : true, link: '/about', subItems : [
                                    {   id: 54, 
                                        name: 'COMPANY',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/about/company', subItems: [
                                            {   id: 55, 
                                                name: 'About Us',
                                                image: '', icon:'', 
                                                description: '',
                                                status : true, link: '/about/company/about-us', subItems: [
                                                    
                                                ]
                                            },
                                            // {   id: 56, 
                                            //     name: 'License and Registration',
                                            //     image: '', icon:'', 
                                            //     description: '',
                                            //     status : false, link: '/about/company/license-and-registration', subItems: [
                                                    
                                            //     ]
                                            // },
                                            {   id: 57, 
                                                name: 'Documents',
                                                image: '', icon:'', 
                                                description: '',
                                                status : true, link: '/about/company/documentation', subItems: [
                                                    
                                                ]
                                            },
                                            {   id: 66, 
                                                name: 'Regulations',
                                                image: '', icon:'', 
                                                description: '',
                                                status : true, link: '/about/company/regulations', subItems: [
                                                    
                                                ]
                                            }                
                                        ]
                                    },
                                    {   id: 58, 
                                        name: 'CONTACT US',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/contact-us', subItems: [
                                            {   id: 59, 
                                                name: 'Live Support',
                                                image: '', icon:'', 
                                                description: '',
                                                status : false, link: 'https://api.whatsapp.com/send/?phone=447450125893&type=phone_number&app_absent=0', subItems: [
                                                    
                                                ]
                                            },
                                            {   id: 60, 
                                                name: 'FAQ',
                                                image: '', icon:'', 
                                                description: '',
                                                status : true, link: '/about/contact-us/faq', subItems: [
                                                    
                                                ]
                                            },
                                            {   id: 61, 
                                                name: 'Contact Us',
                                                image: '', icon:'', 
                                                description: '',
                                                status : true, link: '/about/contact-us/contact', subItems: [
                                                    
                                                ]
                                            }              
                                        ]
                                    }
                                ]},
                                {   id: 5, 
                                    name: 'Partnerships',
                                    header: 'PARTNERSHIPS',
                                    image: '', icon:'',
                                    description: 'Earn money by choosing to partner with us. Choose the option that’s right for you.',
                                    status : true, link: '/partnerships', subItems : [
                                        {   id: 62, 
                                            name: 'FOR PARTNERS',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/partnerships/for-partners', subItems: [
                                                {   id: 63, 
                                                    name: 'Partner',
                                                    image: '', icon:'', 
                                                    description: '',
                                                    status : true, link: '/partnerships/for-partners/partner', subItems: [
                                                        
                                                    ]
                                                }           
                                            ]
                                        },
                                        {   id: 64, 
                                            name: 'SERVICES',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/partnerships/services', subItems: [
                                                {   id: 65, 
                                                    name: 'IB Commission',
                                                    image: '', icon:'', 
                                                    description: '',
                                                    status : true, link: '/partnerships/services/ib-commission', subItems: [
                                                        
                                                    ]
                                                }  ,
                                                {   id: 66, 
                                                    name: 'MAM',
                                                    image: '', icon:'', 
                                                    description: '',
                                                    status : true, link: '/partnerships/services/mam', subItems: [
                                                        
                                                    ]
                                                } ,
                                                {   id: 67, 
                                                    name: 'PAMM',
                                                    image: '', icon:'', 
                                                    description: '',
                                                    status : true, link: '/partnerships/services/pamm', subItems: [
                                                        
                                                    ]
                                                }         
                                            ]
                                        }
                                    ]}
        ]
    )
}

export default menuContent;