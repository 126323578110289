import React from 'react';
import { CountrySessionCard } from '../components/Cards/CountrySessionCard';
import styles from './ForexMarketHours.module.css';
import image from '../assets/images/steps.png'
export const ForexMarketHours = () => {
    return (
        <>
            <div className='container-full'>
                <div className='container-max mx-auto text-center flex sm:py-16 py-6'>
                    <div className='text-center m-auto'>
                        <h3 className='text-3xl mb-6 font-semibold' >Forex Market Hours</h3>
                        <p className='text-xl mb-6 '>
                            One of the unique features of the foreign exchange market is that it is accessible 24 hours a day. What makes this possible is the overlap in trading times of the main sessions. Until one major market closes, another market opens. There are 4 major Forex trading sessions:
                        </p>
                    </div>
                </div>
            </div>
            <div className='container-full bg-mons-gray-1 '>
                <div className='container-max mx-auto text-center flex py-16'>
                    <div className='w-full'>
                        <div className='grid md:grid-cols-4 grid-cols-2 md:gap-0 gap-4'>
                            <CountrySessionCard city={'SYDNEY'} hours={'3pm–12am'} />
                            <CountrySessionCard deg={-30} city={'LONDON'} hours={'3am–11am'} />
                            <CountrySessionCard deg={150} city={'NEW YORK'} hours={'8am–5pm'}/>
                            <CountrySessionCard deg={120} city={'TOKYO'} hours={'7pm–4am'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-full bg-white' >
                <div className='container-max flex mx-auto' >
                    <div className='m-auto w-2/3 py-16' >
                        <p className='text-center text-xl sm:block hidden' >
                        All times are listed in Eastern Standard Time (EST) according to New York time for uniformity. 
                        Traders should note that countries such as the United States, United Kingdom, and Australia all 
                        adhere to summer time. Be sure to note the effect this has on local time in your respective time zone.
                        </p>
                    </div>
                </div>
                <div className='container-max mx-auto' >
                    <div className='m-auto w-full mb-16'>
                        <h3 className='text-center text-3xl font-bold' >Become a Part of Forex in 5 Simple Steps</h3>
                    </div>
                    <div className='w-full'>
                        <div>
                            <img src={image} />
                        </div>
                    </div>
                </div>
                <div className='container-max mx-auto text-gray-500 sm:block hidden'>
                    <div className='text-center mb-12' >
                        <h3 className='text-4xl' >Forex Liquidity and Volatility</h3>
                    </div>
                    <div className='grid md:grid-cols-2 -md:grid-rows-2 gap-24'>
                        <div>
                            <p>
                                This slight delay and small breaks during the day have been implemented to help prevent spreads from widening during these times when liquidity providers are offline. The liquidity of certain currency pairs also depends on the time of day. This is because trading volume plays a very important role in the liquidity of the foreign exchange markets.
                                <br/><br/>
                                For example, the EUR/USD pair will experience high trading volume and liquidity when the London and New York sessions converge, that is, when the two sessions overlap. This is when the highest number of traders in the United States and Europe are online.
                            </p>
                        </div>
                        <div>
                            <p>
                                Similarly, the ideal time to trade the Australian dollar to the Hong Kong dollar or Singapore dollar is when the Tokyo and Sydney sessions and both are open. This period will be most active when critical economic data is released not only for Australian and Asian traders, but also for these regions. As a result, this is the best time to do both fundamental and technical analysis to avoid high-risk trading scenarios and identify trading opportunities.
                                <br/><br/>
                                The best time to trade the US dollar is when the North American session is busiest, while the Australian dollar is during the Sydney Session and so on. MonsTrade is a forex broker that caters to forex traders of all skill levels.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
