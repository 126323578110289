import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'
//images
import bgImage from '../assets/images/banners/accounts-banner.png'
import comonditiesIcon from '../assets/images/comondities-icon.png'
import { SimpleCardListContainer } from '../components/ContainerLayout/SimpleCardListContainer'
import { CompareAccountTypeContainer } from '../containers/CompareAccountTypeContainer'
import { StartTrading } from '../containers/StartTrading'
import { TakingOffContainer } from '../containers/TakingOffContainer'

import icon1 from '../assets/icons/account-big-icon-1.svg';
import icon2 from '../assets/icons/account-big-icon-2.svg';
import icon3 from '../assets/icons/account-big-icon-3.svg';
import icon4 from '../assets/icons/account-big-icon-4.svg';
import icon5 from '../assets/icons/account-big-icon-5.svg';
import icon6 from '../assets/icons/account-big-icon-6.svg';

export const Accounts = () => {

    const cards = [
        { header: 'Demo Account', description: 'Are you ready for real account', icon: icon1, link: '#DEMO' },
        { header: 'Cent Account', description: 'Be ready to trade for less risk', icon: icon2, link: '#Cent' },
        { header: 'Classic Account', description: 'You are ready to trade', icon: icon3, link: '#Classic' },
        { header: 'Prime Account', description: 'Advantage of the  trade on the best market ', icon: icon4, link: '#Prime' },
        { header: 'ECN Account', description: 'For market professionals on exclusive conditions', icon: icon5, link: '#ECN' },
        { header: 'Islamic Account', description: 'Swap-Free accounts are charged a fixed forex commission', icon: icon6, link: '#Islamic' }
    ]

    const landingOptions = {
        image : bgImage,
        imageGradient : "rgb(3 0 117 / 65%)",
        container : {
          width : 'md:w-1/3 w-full',
          padding : 'py-20',
          margin : 'md:ml-0 m-auto'
        },
        header: {
          text : 'We Collect the Most Accurate News for You.',
          size: 'text-3xl',
          align: 'md:text-left text-center'
        },
        subHeader: {
          text : "We understand that every trader has different needs. Monstrade offers 6 account types to help you trade better.",
          align : "md:text-left text-center"
        },
        button: {
            bgcolor: "bg-white",
            textcolor: "text-monstrade-blue md:ml-0 m-auto",
            text: "Join Now",
            padding: "pt-4 flex"
        }
      }

    return (
        <div>
            <BannerContainer options={landingOptions}/>
            <SimpleCardListContainer 
                cards={cards}
                header={'<b>What Type of Trading Account is Right for You?</b>'}
                text={'Monstrade offers 6 account types to best suit your trading needs.'}
            />
            <CompareAccountTypeContainer />
            <TakingOffContainer />
            <StartTrading />
        </div>
    )
}
