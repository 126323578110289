import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";
import Security from "../components/Security";
import Faq from "../components/Faq"
import ForexImg from "../assets/images/forex_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import HourglassIco from "../assets/icons/hourglass.svg"
import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";

const pageName = "Forex"

const landingOptions = {
    image : ForexImg,
    imageGradient : 'linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5))',
    mainZindex : 'z-[-1]',
    header: {
        text : 'Invest in Forex',
        size: 'text-3xl',
      },
      subHeader: {
        text : "Trade foreign exchange on the global financial markets on competitive conditions with Monstrade",
        align : "text-center"
      }
}
const cards = [
    { header: 'Leverage up to 1:500', description: '', icon: BalanceIco, link: '' },
    { header: 'Low spreads', description: '', icon: ForexIco, link: '' },
    { header: 'High trade speed', description: '', icon: HourglassIco, link: '' },
]

const faqTitle1 = "What is forex trading, and how to invest in Forex?"
const faqText1 = "The Forex market is the largest and most liquid trading facility in the world with a daily turnover of more than 5 trillion dollars.<br/><br/> The Forex market that is opened 24/5 and considered to be the largest and most liquid trading facility in the world. The turnover is more than 4 trillion Dollars per day and this number is more than any other market. Forex is intended for buying and selling different global currencies, which are paired into single trading instruments. For example, the Euro vs. the US Dollar (EUR/USD). One of the currencies is good, the other one is a tool for paying for this good. The profit from trading is gained due to the currency exchange rate change to one of the directions as time goes by.<br/><br/>No need for large investments to start investing in Forex, as you can use leverage to access stock trading. Thanks to this, traders can perform trading operations that involve bigger amounts of money than they actually have on their accounts. The leverage may increase the profit a trader receives from transactions, but at the same time, it may increase their losses as well."

const faqTitle2 = "What currency pairs are better for trading on Forex?"
const faqText2 = "The Best Forex Major Currency Pairs<br/><br/>EUR/USD: The Euro and US dollar.<br/>USD/JPY: The US dollar and Japanese Yen. <br/>GBP/USD: The British pound sterling and US dollar.<br/>USD/CHF: The US dollar and Swiss Franc.<br/>AUD/CAD: The Australian dollar and Canadian dollar. <br/>NZD/USD: The New Zealand dollar and US dollar."

const ForexPage = ()=> {
    return(
        <>
            {/* <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={HourglassIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={ForexImg} />  */}
            <BannerContainer options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-3'} />
                </div>
            </BannerContainer>
            <div className="mt-10">
                <Button content="START INVESTING" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={false} /> 
            </div>
            <Table type={'forex'} />
            <PhoneMockup pageName={pageName}/>
            <AvailableAccountTypes/>
            <Security/>
            <Faq faqTitle1={faqTitle1} faqText1={<div dangerouslySetInnerHTML={{__html: faqText1}} />} faqTitle2={faqTitle2} faqText2={<div dangerouslySetInnerHTML={{__html: faqText2}} />} isTwoItems={true}/>
        </>
    )
}

export default ForexPage;