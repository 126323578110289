import React from "react";
import tradingStock from "../features/content/tradingStock.content";

const StockContentTable = () => {

    const content = tradingStock()

    return(
        <>
            <div className="container-full">
                <div className="container-max mx-auto md:pt-24 pt-12 pb-10">
                    <div className="text-center">
                        <p className="text-4xl font-light">Achieve your investment goals together with Monstrade</p>
                        <p className="py-8 text-gray-500">In Monstrade, you have an opportunity to invest in more than 300+ real stocks of companies in all over the world.</p>
                    </div>
                    <div className="pt-6 md:overflow-hidden overflow-x-auto">
                        <div>
                            <table className="whitespace-nowrap mx-auto ">
                                <tbody>
                                    <td className="pr-4 text-right text-gray-700 text-sm">
                                        <tr className="h-[40px]"></tr>
                                        {content.meta.map((item) => {
                                            return(<tr className="border-b-2 h-8 border-t-gray-200">{item}</tr>)
                                        })} 
                                    </td>

                                    {content.data.map((item) => {
                                    return(
                                        <td className="bg-slate-50 text-sm pr-4">
                                            <tr className="text-xl h-[40px] text-gray-600 font-semibold">{item.header}</tr>
                                            {item.lines.map((line)=>{
                                                return(<tr className="border-b-2 h-8 text-gray-700 border-t-gray-300">{line}</tr>)
                                            })} 
                                        </td>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StockContentTable


