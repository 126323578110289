import React from 'react'
import { BgWhiteBlueButton } from '../Buttons/BgWhiteBlueButton';
import { useState } from 'react';
import { useEffect } from 'react';

export const ContainerWithBGImage = ({ 
    image,
    imageBgColor,
    imgcolor,
    containerBg,
    children,
    landing,
    itemAlingment,
    textAlingment,
    containerSlice }) => {
    const [isLoaded,setIsLoaded] = useState(false)

    let img = new Image();
    img.src = image
    useEffect(() => {
        
    },[isLoaded])
    return (
        <div className='container-full relative overflow-hidden' style={{ height: `${((img.height / 1440) * 100).toFixed(2)}vw`, background : containerBg }}>
            <div className={`absolute z-[-1] h-full ${imageBgColor ? `bg-[${imageBgColor}]` : ''}`} >
                <img className={`lg:w-full lg:min-w-full md:min-w-[120%] min-w-[250%] ${imageBgColor ? `bg-[${imageBgColor}]` : ''}`}
                    src={img.src}
                    alt="monstrade"
                    onLoad={() => { setIsLoaded(true)}}
                    style={{backgroundColor : imgcolor}}/>
            </div>
            <div className='container-max m-auto h-full flex'>
                {landing &&
                    <div className='flex xl:h-[30vh]'>
                        <div className={`
                                        ${!itemAlingment ? 'm-auto' : ''}
                                        ${itemAlingment === 'left' ? 'my-auto' : ''}
                                        ${itemAlingment === 'lefttop' ? 'mt-24' : ''}
                                         ${textAlingment === 'left' ? '' : 'text-left'} 
                                         w-${containerSlice ? containerSlice : '2'}/2`}>
                            <h1 className={`text-white md:text-3xl text-xl font-bold mb-6 ${landing.h1style} ${landing.padding}`}>{landing.h1}</h1>
                            <p className={`text-white md:text-lg text-sm ${landing.style ? landing.style : "text-center w-2/5"} leading-5 mb-12`}>{landing.p}</p>
                            <div className={`text-center ${landing.btn2 ? "w-3/5 flex" : "w-2/5"}`}>
                            {landing.btn &&
                                <BgWhiteBlueButton text={landing.btn.text} link={landing.btn.link} bgcolor={landing.btn.bgcolor} textcolor={landing.btn.textcolor} bordercolor={landing.btn.bordercolor} margin={landing.btn.margin} />
                            }
                            {landing.btn2 &&
                                <BgWhiteBlueButton text={landing.btn2.text} link={landing.btn2.link} bgcolor={landing.btn2.bgcolor} textcolor={landing.btn2.textcolor} bordercolor={landing.btn2.bordercolor} margin={landing.btn2.margin} />
                            }
                            </div>
                        </div>
                    </div>
                }
                {children}
            </div>
        </div>
    )
}
