import React from 'react'
import menuContent from '../../features/content/menu.content'
//images
import logo from '../../assets/images/monstrade-logo.png'
import { NavItem } from './NavItem';
import { SideNav } from './SideNav';
import xMasLogo from '../../assets/images/x-mas-logo.svg'
import TrustedNavbar from '../../assets/icons/trusted-nav.svg'
import Fincen from '../../assets/icons/finCEN-nav.svg'
import Nfa from '../../assets/icons/NFA-nav.svg'

export const Navbar = () => {
    const navItems = menuContent();
    return (
        <div className='w-full z-[70] bg-white' style={{marginTop: '-6px'}}>
            <div className='max-w-[1370px] mx-auto'>
                <nav className="navbar navbar-expand-lg py-2 relative flex items-center w-full justify-between">
                    <div className="lg:px-0 pl-3 flex">
                        <div className="container-fluid md:flex hidden md:pr-3">
                            <a className="flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 lg:mt-2 lg:mt-0 lg:mr-10 mr-2" href="/">
                                {/* <img src={logo} alt="" loading="lazy" className='lg:h-[32px] h-[20px]' /> */}
                                <img src={logo} alt="" loading="lazy" className='lg:h-[30px] h-[24px]' />
                            </a>
                        </div>
                        <button className="navbar-toggler border-0 py-3 md:hidden leading-none text-xl bg-transparent text-gray-600 hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#sidenav"
                            aria-controls="navbarSupportedContentX"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                            </svg>
                        </button>
                        <div className="md:navbar-collapse grow items-center md:block hidden " id="navbarSupportedContentX">
                            <ul className="navbar-nav mr-auto flex flex-row">
                                {navItems.map((navItem, index) => {
                                    if(navItem.status == true) return <NavItem item={navItem} key={index} />
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className='lg:pr-0 md:flex items-center hidden' >
                        <div className='mr-2 bg-[#EEF0F3] hover:shadow-md p-2 rounded-2xl px-3'>
                            <a href='https://client.monstradecrm.com/en/auth/login?returnUrl=%2Fdashboard' >Sign In</a>
                        </div>
                        <div className='bg-[#00D17F] hover:shadow-md p-2 rounded-2xl px-3 mr-10'>
                            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='rounded-2xl text-white font-medium ' >Open Account</a>
                        </div>
                        <div>
                            <img className='w-10' src={Fincen} alt="" />
                        </div>
                        <div className='px-2'>
                            <img className='w-16' src={Nfa} alt="" />
                        </div>
                        <div>
                            <a target='_blank' href="https://www.wikifx.com/tr/dealer/3545945396.html"><img className='w-16' src={TrustedNavbar} alt="" /></a>
                        </div>
                    </div>
                    {/* mobile logo */}
                    <div className="container-fluid flex md:hidden ">
                        <a className="flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 lg:mt-2 lg:mt-0 lg:mr-10 mr-2" href="/">
                            {/* <img src={logo} alt="" loading="lazy" className='lg:h-[32px] h-[20px]' /> */}
                            <img src={logo} alt="" loading="lazy" className='lg:h-[36px] h-[24px]' />
                        </a>
                    </div>
                </nav>
            </div>

            <SideNav items={navItems} />
        </div>

    )
}
