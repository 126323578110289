import React from "react";
import Button from "../components/Button"

const AccountsForInvests = () => {
    return(
        <>
            <div className="container-full lg:block hidden">
                <div className="container-max mx-auto">
                    <div className="grid-rows-3">
                        <div className="text-center text-3xl pt-16">
                            <p>A single account for all investments</p>
                        </div>
                        <div className="text-center py-12">
                            <p className="text-xl">Choose a suitable account type and invest in Cryptocurrencies without having to open a Wallet or an account at a crypto exchange. At Monstrade, you can trade both Cryptocurrencies and 7 other asset types (Stocks, Indices, Metals, Commodities, Energies, ETFs, and Forex) from a single account.</p>
                            <p className="pt-12 text-base text-cyan-400">
                                <a href="#">All types of accounts</a>
                            </p>
                        </div>
                        <div className="pb-20">
                            <div className="grid grid-cols-5 w-full gap-4">
                                <div>
                                    <table class="table-fixed">
                                        <tbody className="text-neutral-600 text-right text-sm">
                                            <tr>
                                                <td className=" h-[160px]"></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">First deposit</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Execution Type</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Spreads</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Platforms</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Max. leverage</td>
                                            </tr>
                                            <tr>
                                                <td>Number of cryptoinstruments</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table class="table-fixed">
                                        <tbody className="text-neutral-600 text-sm bg-slate-50">
                                            <tr>
                                                <td className="text-xl text-black pb-4 font-semibold">Prime Account</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">An account type with the best trading conditions available at the company. It is suitable for both currency and other types of markets.</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">2500</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Market Execution</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Floating from 1.3 pips</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">MT5</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">up to 1:75</td>
                                            </tr>
                                            <tr>
                                                <td>More than 10</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table class="table-fixed">
                                        <tbody className="text-neutral-600 text-sm bg-slate-50">
                                            <tr>
                                                <td className="text-xl text-black pb-4 font-semibold">ECN</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4 h-[117px]">A specific account type for trading through web platform and mobile app of the same name.</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">10000</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Market Execution</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Floating from 1.3 pips</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">MT5</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">up to 1:75</td>
                                            </tr>
                                            <tr>
                                                <td>More than 10</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table class="table-fixed">
                                        <tbody className="text-neutral-600 text-sm bg-slate-50">
                                            <tr className="row-span-2">
                                                <td className="text-xl text-black pb-4 font-semibold">Classic</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">The choice of experienced traders, which combines the highest order execution speed and competitive trading conditions.</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">100</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Market Execution</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Floating from 1.6 pips</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">MT5</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">up to 1:50</td>
                                            </tr>
                                            <tr>
                                                <td>More than 10</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table class="table-fixed">
                                        <tbody className="text-neutral-600 text-sm bg-slate-50">
                                            <tr>
                                                <td className="text-xl text-black pb-4 font-semibold">Cent</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4 h-[117px]">An account type for testing strategies and EAs, as well as smooth transitioning from a demo account to real trading.</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">100</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Market Execution</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">Floating from 16 pips</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">MT5</td>
                                            </tr>
                                            <tr>
                                                <td className="pb-4">up to 1:50</td>
                                            </tr>
                                            <tr>
                                                <td>More than 10</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button content="INVEST IN CRYPTO" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={true} />
            </div>
        </>
    )
}

export default AccountsForInvests;