import React from 'react'

export const BgWhiteBlueButton = ({text , link, bgcolor, textcolor, bordercolor, margin}) => {
    return (
        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className={`
                                ${bgcolor}
                                ${textcolor}
                                ${bordercolor ? `border-2 ${bordercolor}` : ""}
                                ${margin}
                                hover:bg-monstrade-blue
                                hover:text-white
                                ease-in
                                duration-150
                                lg:py-4 lg:px-4 py-2 px-6 
                                rounded-lg lg:text-2xl md:text-xl 
                                font-semibold`}>
            {text}
        </a>
    )
}
