const Card = ({ value }) => {


    return (
      <>
      <div
        style={{
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          width: 240,
          height: 660,
          background: 'transparent',
          borderRadius: 8,
        }}
      >
        <img src={value.image} alt="" />
        
      </div>
      </>
    )
  }
  
  export default Card