import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import styles from './TradingTimeCollapse.module.css'

export const TradingTimeCollapse = ({ header, times }) => {
    const [isOpen, setIsOpen] = useState(false)
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const clickHandler = () => {
        console.log(isOpen)
        if (isOpen) return setIsOpen(false)
        if (!isOpen) return setIsOpen(true)
    }
    return (
        <div className='flex w-full' >
            <div className='mx-auto w-full text-xl text-center' >
                <div className='w-full text-white'>
                    <button className={styles.colapseContainer} onClick={clickHandler} >
                        <span>Time Table for {header}</span>
                        <span className='absolute right-5' ><FontAwesomeIcon icon="fa-solid fa-chevron-down" /></span>
                    </button>
                </div>
                <div className={`${isOpen ? styles.activeTab : styles.tabStyle}`} >
                    <table className={`${isOpen ? styles.activeTable : styles.tableStyle}`} >
                        {times.map((time, index) => {
                            return (
                                <tr className={`${index%2 == 0 ? 'bg-mons-gray-1' : ''}`} >
                                    <td>{weekdays[index]}</td>
                                    <td className='whitespace-nowrap'>{time}</td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
    )
}
