import React, { useState, useEffect } from 'react'

export const VideoListContainer = () => {
    const [isApiLoading, SetIsApiLoading] = useState(false);
    const [videos, setVideos] = useState([]);

    const fetchYoutubeVideo = async () => {
        try {
            const response = await fetch(`https://www.googleapis.com/youtube/v3/search?key=${process.env.REACT_APP_GOOGLE_API_KEY}&channelId=${process.env.REACT_APP_YOUTUBE_CHANNEL_ID}&part=snippet,id&order=date&maxResults=20`,
            {headers : {
                "Content-Type": "application/json"
               }});
            let data = await response.json();
            console.log(data)
            setVideos(data.items);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchYoutubeVideo()
    }, [])

    return (
        <div className='container-full mb-10' >
            <div className='container-max md:pt-24 pt-10 mx-auto'>
                <div className='grid lg:grid-cols-3 md:grid-cols-2 md:text-left text-center gap-20'>
                    {videos.map((item, index) => {
                        return (
                            <div key={index} className="block">
                                <div>
                                    <p className='line-clamp-2 text-2xl font-bold mb-3 h-[30px]' >{item.snippet.title}</p>
                                    <p className='line-clamp-1 mb-3 h-[30px]' >{item.snippet.description}</p>
                                </div>
                                <iframe className='video md:m-0 mx-auto'
                                    title='Youtube player'
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    src={`https://youtube.com/embed/${item.id.videoId}?autoplay=0`}>
                                </iframe>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
