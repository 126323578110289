import React from "react";
//images
import Visa from "../../assets/icons/visa_icon.svg"
import mastercardLogo from "../../assets/icons/mastercard-logo.svg"
import theterLogo from "../../assets/icons/theter-logo.png"
import bitcoinLogo from "../../assets/icons/bitcoin-logo.png"
import pmLogo from "../../assets/icons/pm-logo.png"
import topchangeLogo from "../../assets/icons/topchange-logo.png"


const PromotionFooter = () => {
    return (
        <>
            <div>
                <div className="text-center p-4 text-lg">
                    <p>Deposit funds using any of the available payment methods</p>
                </div>
                <hr />
                <div>
                    <div className="container-full">
                        <div className="container-max mx-auto p-4">
                            <div className="flex gap-10 items-center justify-center">
                                <div><img className="h-6 mx-auto" src={Visa} alt="" /></div>
                                <div><img className="h-8 mx-auto" src={mastercardLogo} alt="" /></div>
                                <div><img className="h-8 mx-auto" src={theterLogo} alt="" /></div>
                                <div><img className="h-8 mx-auto" src={bitcoinLogo} alt="" /></div>
                                <div><img className="h-8 mx-auto" src={pmLogo} alt="" /></div>
                                <div><img className="h-8 mx-auto" src={topchangeLogo} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromotionFooter;