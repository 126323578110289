import { useEffect } from "react";
import { Outlet } from "react-router-dom"
import Footer from '../containers/Footer';
import RiskWarning from "../containers/RiskWarning";
import { FixedHeaderContainer } from "../components/ContainerLayout/FixedHeaderContainer";
import { Navbar } from "../components/Nav/Navbar";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import {ReactComponent as CompanyIcon} from '../assets/images/android-chrome-512x512.svg'
import NavigationForPhones from "../containers/NavigationForPhones";
import XMas from '../assets/images/xmas-navbar-top.png'

const LayoutWebsite = () => {
    const finageTickerTape = window.finageTickerTape;

    useEffect(() => {
        // finageTickerTape.symbols(["eurusd:forex", "usdjpy:forex", "audusd:forex", "gbpusd:forex", "usdcad:forex", "usdhkd:forex", "nzdusd:forex", "audcad:forex", "audjpy:forex", "cadjpy:forex", "chfjpy:forex", "euraud:forex", "btcusd:crypto", "ethusd:crypto"]);
        finageTickerTape.symbols(["eurusd:forex", "usdjpy:forex", "audusd:forex", "gbpusd:forex", "usdcad:forex"]);
        finageTickerTape.colors(["#fff", ""]); //background color, text color
        // finageTickerTape.theme(["pearl-white"]); //pearl-white, midnight, dark-night
        finageTickerTape.initWidget();
    }, [])
    

    return (
        <>
            <div className="mx-auto">
                <FixedHeaderContainer>
                    {/* <RiskWarning></RiskWarning> */}
                    <div class="finage-ticker-tape-item"></div>
                    {/* <div className={"container-full z-0 py-2 relative bg-no-repeat"} style={{ backgroundImage: `url("${XMas}")` }}>
                        <a href="/special/christmas-draw"><p className="text-white text-center font-bold underline">Join our Christmas Draw</p></a>
                    </div> */}
                    <Navbar />
                </FixedHeaderContainer>
            </div>
            

            <div className="pt-[100px]" id='main' >
                <Outlet />
            </div>

            <div className="max-w-[1440px] mx-auto">
                <div className="md:visible invisible" >
                    <WhatsAppWidget CompanyIcon={CompanyIcon} companyName={"MonsTrade FX Support"} phoneNumber={"447450125893"} message={"Hello! \uD83D\uDC4B\uD83C\uDFFC \n\nWhat can we do for you?"} />
                </div>
                <NavigationForPhones/>
                <Footer></Footer>
            </div>
            
        </>
    )
}

export default LayoutWebsite;