import React from 'react';
import ArrowIcon from '../assets/icons/arrow.png'

const Button = (props) => {
    return(
        <center>
            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className={`${props.color} ${props.py} ${props.px} ${props.tempStyle} text-white hover:bg-monstrade-blue font-semibold rounded`}>
                {props.content} {props.isArrow ? <img className="ml-2 mb-1 inline" src={ArrowIcon} alt="arrowIco" /> : ''}
            </a>
        </center>
    )
    
}

export default Button;