import React from 'react'
import bgImage from '../assets/images/taking-off-bg-image.png'

export const TakingOffContainer = () => {
    return (
        <div className='container-full relative overflow-hidden bg-[#CFE0E8] z-20'>
            <div className='absolute right-0 z-30'>
                <img src={bgImage} alt='' className='w-full h-auto' ></img>
            </div>
            <div className='w-full h-full absolute z-50' style={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 7.09%, #000000 112.47%)' }}>

            </div>
            <div className='container-max h-[900px] mx-auto py-24'>
                <div className='grid lg:grid-cols-2  z-50 relative'>
                    <div className='bg-white rounded-3xl lg:px-16 pl-4 py-20' >
                        <div className='grid grid-flow-row gap-16 relative'>
                            <div className='absolute h-full py-16 ml-14'>
                                <div className='border-l-[3px] border-dashed border-mons-gray-1 h-full'></div>
                            </div>
                            <div className='flex z-50 my-4' >
                                <div className='w-[114px] h-[114px] flex rounded-full p-8 mr-4 border-2 bg-white'>
                                    <div className=' text-center font-bold'>
                                        <p className='text-xl leading-3' >STEP</p>
                                        <p className='text-4xl'>1</p>
                                    </div>
                                </div>
                                <div className='flex' >
                                    <div className='my-auto' >
                                        <p className='font-bold text-3xl mb-3' >Open a live account</p>
                                        <div className='md:pt-0 pt-5'>
                                        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='border-2 rounded-full border-mons-blue-1
                                                            px-5 py-1
                                                            text-mons-blue-1 text-lg font-bold' >
                                            Join Now
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex z-50 my-4' >
                                <div className='w-[114px] h-[114px] flex rounded-full p-8 mr-4 border-2 bg-white'>
                                    <div className=' text-center font-bold'>
                                        <p className='text-xl leading-3' >STEP</p>
                                        <p className='text-4xl'>2</p>
                                    </div>
                                </div>
                                <div className='flex' >
                                    <div className='my-auto' >
                                        <p className='font-bold text-3xl mb-3' >Fund your account</p>
                                        <div className='md:pt-0 pt-5'>
                                        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='border-2 rounded-full border-mons-blue-1
                                                            sm:px-5 px-1 py-1
                                                            text-mons-blue-1 text-lg font-bold' >
                                            Deposite Now
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex z-50 my-4' >
                                <div className='w-[114px] h-[114px] flex rounded-full p-8 mr-4 border-2 bg-white'>
                                    <div className=' text-center font-bold'>
                                        <p className='text-xl leading-3' >STEP</p>
                                        <p className='text-4xl'>3</p>
                                    </div>
                                </div>
                                <div className='flex' >
                                    <div className='my-auto' >
                                        <p className='font-bold text-3xl' >Get access to hundreds of products</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='relative' >
                        <div className='text-white lg:absolute bottom-0 right-0 hidden'>
                            <div className='text-end'>
                                <h3 className='text-4xl font-bold mb-4' >
                                    Taking Off with <br/>
                                    Monstrade
                                </h3>
                                <p className='text-lg' >
                                    We make trading easy.<br/> 
                                    MonsTrade offers ready to trade<br/>
                                    platforms so users have to enjoy<br/>
                                    trading anytime and anywhere 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
