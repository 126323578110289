import React from "react";

const AccountTransferContent = () => {
    return(
        <>
            <div className="p-8 text-gray-700 md:text-left text-center">
                <p>
                    If you have open positions with another broker, simply transfer them to MonsTrade and get the most attractive trading terms for maximum profit.
                </p>
                <p className="font-bold text-black py-4">
                    Switch to Monstrade and get the best trading conditions:
                </p>
                <p>
                    More than 100 financial instruments: currency pairs, stocks, metals, indices, stock , energies ;
                    <br/><br/>
                    the 20% bonus on every deposit over $100 is paid in the form of non-withdrawable credits, which are added to the balance and can be used in all trading operations;
                    <br/><br/>
                    the promotion is available for trading forex instruments on Standard and Fixed accounts, in MetaTrader 5 platform only;
                    <br/><br/>
                    the maximum amount of bonus funds per client $1,000;
                    <br/><br/>
                    this bonus offer is not cumulative with any other MonsTrade’ bonuses and promotions;
                    <br/><br/>
                    bonus for the reporting period will be canceled, if a client fully or partially withdraws his initial deposit, the above-mentioned bonus has been calculated from, profit received from trading using the bonus can't be withdrawn;
                    <br/><br/>
                    other bonus terms and conditions are governed by the MonsTrade
                </p>
            </div>
        </>
    )
}

export default AccountTransferContent;