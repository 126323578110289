import React from 'react'

export const FAQInnerCollapse = ({question, answer, index}) => {
    return (
        <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id={`heading-${index}`}>
                <button className={`accordion-button relative flex items-center 
                                    w-full py-4 px-5 text-base text-gray-800 
                                    text-left bg-white border-0 rounded-none 
                                    transition focus:outline-none 
                                    ${index !== 0 ? 'collapsed' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded={index === 0 ? true : false}
                    aria-controls={`collapse${index}`}>
                    {question}
                </button>
            </h2>
            <div id={`collapse${index}`} 
                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''} `} 
                aria-labelledby={`heading-${index}`} 
                style={{ visibility: 'visible' }}
                data-bs-parent="#accordionExample">
                <div className="accordion-body py-4 px-5 text-sm leading-4 text-gray-500">
                    {answer}
                </div>
            </div>
        </div>
    )
}
