import HomeIco from "../../assets/icons/active-home-ico.svg"
import OpenAccountIco from "../../assets/icons/active-open-account-ico.svg"
import TradeNowIco from "../../assets/icons/active-trade-now-ico.svg"
import LoginIco from "../../assets/icons/active-login-ico.svg"
import LiveChatIco from "../../assets/icons/active-live-chat-ico.svg"

import PassiveHomeIco from "../../assets/icons/passive-home-ico.svg"
import PassiveOpenAccountIco from "../../assets/icons/passive-open-account-ico.svg"
import PassiveTradeNowIco from "../../assets/icons/passive-trade-now-ico.svg"
import PassiveLoginIco from "../../assets/icons/passive-login-ico.svg"
import PassiveLiveChatIco from "../../assets/icons/passive-live-chat-ico.svg"


const navigationForPhonesContent = () => {
    return(
        [
            {
                name: "Home",
                icon: ['fas', 'house'],
                iconColor: 'text-white',
                href: "/",
            },
            {
                name: "Open Account",
                icon: ['fas','user-plus'],
                iconColor: 'text-white',
                href: "https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard",
            },
            {
                name: "Trade Now",
                icon: ['fas','arrow-right-arrow-left'],
                iconColor: 'text-white',
                href: "/web-trader",
            },
            {
                name: "Login",
                icon: ['fas','arrow-right-to-bracket'],
                iconColor: 'text-white',
                href: "https://client.monstradecrm.com/en/auth/login?returnUrl=%2Fdashboard",
            },
            {
                name: "Live Chat",
                icon: ['fab','whatsapp'],
                iconColor: 'text-[#4fce5e]',
                href: "/about/contact-us/contact",
            }
        ]
    )
}

export default navigationForPhonesContent;