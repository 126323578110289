import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

const TextScroller = ({ text, isHover }) => {
  const [key, setKey] = useState(1);
  // const [isHover, setIsHover] = useState(false)
  
  const scrollingCard = useSpring({
    from: { transform: "translate(0%,0)" },
    to: { transform: "translate(-880%,0)" },
    config: { duration: 20000 },
    reset: true,
    // reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    }
  });

  const scrolling1 = useSpring({
    from: { transform: "translate(0%,0)" },
    to: { transform: "translate(-100%,0)" },
    config: { duration: 3000 + (text.length * 400) },
    reset: true,
    // reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    }
  });
  
  return (
    <div className="absolute bottom-10" >
      <animated.div className={"text-[440px] leading-[300px] whitespace-nowrap z-10 tracking-[0.1em] pl-12 font-bold"} 
      style={
        isHover ? scrolling1 : {} 
        }>{text}</animated.div>
    </div>
  );
};

export default TextScroller;
