import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'

import BannerImage from '../assets/images/banners/monstar-new-banner.png'
import NewBannerImage from '../assets/images/banners/mons-new-banner.png'
import BannerAddition from '../assets/images/banners/monstar-banner-piece.png'
import BlueImage from '../assets/images/monstar-blue-bg.png'
import Monstar1 from '../assets/images/monstar1.png'
import Monstar2 from '../assets/images/monstar2.png'
import Monstar3 from '../assets/images/monstar3.png'
import Monstar4 from '../assets/images/monstar4.png'
import Monstar5 from '../assets/images/monstar5.png'
import Monstar6 from '../assets/images/monstar6.png'
import MonstarSignal from '../assets/images/monstar-signal.png'
import MonstarAnalysis from '../assets/images/monstar-analysis.png'
import MonstarNews from '../assets/images/monstar-news.png'
import MonstarEducation from '../assets/images/monstar-education.png'
import MonstarEconomicCalendar from '../assets/images/monstar-economic-calendar.png'
import MonstarQR from '../assets/images/monstar-qr.png'
import MonstarLogos from '../assets/images/monstar-logos.png'
import GooglePlay from '../assets/images/mons-googlePlay.png'
import AppStore from '../assets/images/mons-app-store.png'
import MonstarNewQR from '../assets/icons/mons-new-qr.svg'
import MonstarDeposit from '../assets/images/mons-deposit.png'
import MonstarSpecial from '../assets/images/mons-special.png'
import Monstore from '../assets/images/mons-monstore.png'
import ArrowRight from '../assets/icons/arrow-right.svg'

import MonstarStore from '../assets/images/mons-monstore-img.png'
import MonstarIphone from '../assets/images/iphone.png'
import MonstarIpad from '../assets/images/ipad.png'
import MonstarBuds from '../assets/images/iphone-buds.png'
import MonstarDrone from '../assets/images/drone.png'
import MonstarAmazonGift from '../assets/images/amazon-gift-card.png'

export const Monstar = () => {

    return (
        <div>
            <div className='grid lg:grid-cols-2 max-w-[1440px] mx-auto mt-8 justify-center items-start lg:h-[450px] h-[850px]'>
                <div className='md:pl-48 text-center'>
                    <div className='mb-[37px]'>
                        <h2 className='text-[48px] text-monstrade-blue font-bold leading-[60px] md:w-[435px] mb-4'>Get ready for more experience with Monstar!</h2>
                        <p className='text-[#6A6A6A] text-lg leading-[22px] md:w-[435px]'>
                            We have combined everything from breaking news updates, financial analysis and expectations,
                            buy-sell signals, economic calendar, educational videos, to campaigns all in one single application!
                        </p>
                    </div>

                    <div className='grid md:grid-cols-2'>
                        <div className='col-span-1 pt-5'>
                            <p className='text-center mb-[11px] text-xl leading-[25px] font-medium text-monstrade-blue'>Download Now</p>
                            <div className='md:flex grid gap-x-4 gap-y-2'>
                                <img className='mx-auto' src={GooglePlay} alt="" />
                                <img className='mx-auto' src={AppStore} alt="" />
                            </div>
                        </div>
                        <div className='md:pl-10'>
                            <img className='mx-auto' src={MonstarNewQR} alt="" />
                        </div>
                    </div>
                </div>
                <div className=' md:right-0 md:top-0 block '>
                    <img src={NewBannerImage} alt="" />
                </div>
            </div>

            <div className='bg-gradient-to-r from-monstrade-blue to-[#3587fa]'>
                <div className='max-w-[1440px] mx-auto'>
                    <div className={"container-full lg:py-16 z-0 max-w-[1064px] relative bg-cover'z-10'"} style={{ backgroundImage: `url("${BlueImage}")` }}>
                        <div className='text-white text-center py-8'>
                            <p className='text-xl tracking-widest'>FEATURES</p>
                            <p className='text-2xl font-bold py-2'>Aim for the Summit in Forex with Monstar!</p>
                            <p className='text-sm max-w-[800px] mx-auto'>Monstar uygulamasında anlık olarak yayınlanan al-sat sinyalleri ile alım-satım yapabilir ve işlem durumlarını takip edebilirsiniz. Haftalık, aylık ve total olarak işlem başarısının ne kadar olduğunu görüntüleyebilirsiniz.</p>
                        </div>
                        <div className='grid md:grid-rows-2 pb-8'>
                            <div className='grid sm:grid-cols-6 grid-cols-2 gap-8 max-w-[800px] mx-auto pb-8'>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar1} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar2} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar3} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar4} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar5} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar6} alt="" /></div>

                            </div>
                            <div className='grid sm:grid-cols-4 grid-cols-2 gap-8 mx-auto'>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar1} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar2} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar3} alt="" /></div>
                                <div><img className='max-w-[100px] mx-auto' src={Monstar4} alt="" /></div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className='bg-[#EAF3F9] text-center p-6'>
            <div className='max-w-[1440px] mx-auto'>
                <div className='mb-[44px]'>
                    <h2 className='text-[50px] leading-[62px] mb-[49px]'>
                        You will enjoy trading <br /> even more with <span><img src={Monstore} alt="" className='inline' /></span>
                    </h2>
                    <p className='text-[22px] leading-[27px] text-center'>
                        There is no limit to promotions and rewards on MonsTrade! Earn <br /> tickets as you invest and convert your tickets into rewards.
                    </p>
                </div>
                <div className='lg:flex grid grid-cols-2 lg:gap-x-6 gap-4 justify-center  mb-6 lg:mx-96'>
                    <div className='bg-[#F6F6F6] rounded-full  flex flex-col justify-center w-[90px] h-[90px] mx-auto'>
                        <span className='font-extrabold text-[22px] leading-[27px]'>3000+</span>
                        <span className='text-[13px] leading-[16px]'>Send Gift</span>
                    </div>
                    <div className='bg-[#F6F6F6] rounded-full  flex flex-col justify-center w-[90px] h-[90px] text-center mx-auto'>
                        <span className='font-extrabold text-[22px] leading-[27px]'>3500+</span>
                        <span className='text-[13px] leading-[16px]'>Silver Tickets</span>
                    </div>
                    <div className='bg-[#F6F6F6] rounded-full  flex flex-col justify-center w-[90px] h-[90px] text-center mx-auto'>
                        <span className='font-extrabold text-[22px] leading-[27px]'>2900+</span>
                        <span className='text-[13px] leading-[16px]'>Gold Tickets</span>
                    </div>
                    <div className='bg-[#F6F6F6] rounded-full  flex flex-col justify-center w-[90px] h-[90px] text-center mx-auto'>
                        <span className='font-extrabold text-[22px] leading-[27px]'>2200+</span>
                        <span className='text-[13px] leading-[16px]'>Diamond <br /> Tickets</span>
                    </div>
                </div>

                <div className='lg:flex grid gap-y-6 gap-x-6 md:justify-center items-center mb-6'>
                    <a
                        className='bg-monstrade-blue text-white text-lg py-4 px-6 rounded-full font-semibold hover:bg-[#0052E0] transition-all duration-300 ease-in-out md:w-[255px]'
                        href="#"
                    >
                        Download Monstar
                    </a>

                    <a
                        className='border border-monstrade-blue text-monstrade-blue text-lg py-4 px-6 rounded-full font-semibold hover:bg-monstrade-blue hover:text-white transition-all duration-300 ease-in-out md:w-[255px]'
                        href="#"
                    >
                        Explore Store <span><img src={ArrowRight} alt="" className='inline' /></span>
                    </a>
                </div>
                <div className='lg:flex grid grid-cols-2 gap-8 lg:justify-around justify-center items-center'>
                    <img className='mx-auto' src={MonstarStore} alt="" />
                    <img className='mx-auto' src={MonstarIphone} alt="" />
                    <img className='mx-auto' src={MonstarBuds} alt="" />
                    <img className='mx-auto' src={MonstarIpad} alt="" />
                    <img className='mx-auto' src={MonstarDrone} alt="" />
                    <img className='mx-auto' src={MonstarAmazonGift} alt="" />
                </div>
            </div>
            </div>
            <div className='relative'>
                <div className='grid lg:grid-cols-2 max-w-[1064px] mx-auto lg:text-left text-center py-16'>
                    <div>
                        <img className='max-w-[400px] md:w-auto w-64 mx-auto' src={MonstarSignal} alt="" />
                    </div>
                    <div className='max-w-[480px] py-10 lg:mx-0 mx-auto'>
                        <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>SIGNALS</p>
                        <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Take Solid Steps with Instant Trade Signals</p>
                        <p className='font-light'>Monstar's daily trade signals allow you to experience the Forex world in a healthier way. The AI-powered trading assistant provides new investors in the Forex world with the opportunity to make healthier trades, while offering higher potential returns for intermediate or professional investors.</p>
                    </div>
                </div>
                <div class="signals-triangle"></div>
            </div>

            <div>
                <div className=' bg-[#F3F3F3]'>
                    <div className='grid lg:grid-cols-2 lg:text-left text-center max-w-[1064px] mx-auto py-10'>
                        <div className='max-w-[480px] mx-auto pt-12'>
                            <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>ANALYSIS</p>
                            <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Technical & Basic Analysis</p>
                            <p className='font-light'>Our expert analysts' reports contain detailed information about market trends, price movements, and other important factors. This allows investors to make more profitable trades by making the right decisions.</p>
                        </div>
                        <div>
                            <img className='max-w-[300px] mx-auto' src={MonstarAnalysis} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='relative'>
                <div class="news-triangle"></div>
                <div className='grid lg:grid-cols-2 lg:text-left text-center max-w-[1064px] mx-auto pt-10'>
                    <div>
                        <img className='max-w-[240px]  mx-auto' src={MonstarNews} alt="" />
                    </div>
                    <div className='max-w-[480px] lg:mx-0 mx-auto'>
                        <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>NEWS</p>
                        <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Breaking News From the Finance World</p>
                        <p className='font-light'>Monstar, being up-to-date with news and analysis is crucial to succeed in the Forex market. Therefore, it tracks financial events worldwide every day and provides you with the latest Forex news, analysis, and comments. It provides all the tools you need to keep your finger on the pulse of the market.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className=' bg-[#F3F3F3] deposit-triangle '>
                    <div className='grid lg:grid-cols-2 lg:text-left text-center max-w-[1064px] mx-auto py-10'>
                        <div className='max-w-[480px] mx-auto pt-12'>
                            <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>DEPOSIT & WITHDRAW</p>
                            <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Multiple Options for Depositing and Withdrawing</p>
                            <p className='font-light'>Trading on MonsTrade is made easy for depositing and withdrawing fiat currencies and cryptocurrencies. Simply download the Monstar app and use the menu to make deposits and withdrawals. Moreover, you have multiple options for this process. You can choose to deposit and withdraw using credit cards, bank transfers, and cryptocurrencies such as Bitcoin.</p>
                        </div>
                        <div>
                            <img className='md:max-w-[760px] mx-auto' src={MonstarDeposit} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative'>
                <div className='grid lg:grid-cols-2 lg:text-left text-center max-w-[1064px] mx-auto pt-10'>
                    <div>
                        <img className='max-w-[240px]  mx-auto' src={MonstarSpecial} alt="" />
                    </div>
                    <div className='max-w-[480px] lg:mx-0 mx-auto'>
                        <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>SPECIAL FOR YOU</p>
                        <p className='text-3xl text-monstrade-blue py-2 font-semibold tracking-wider'>Campaigns Prepared Specifically for Individuals</p>
                        <p className='font-light'>In the Monstar app, you can explore personalized campaigns just for you in the 'Special For You' section. View and join campaigns tailored to your needs.</p>
                    </div>
                </div>
            </div>
            <div className='bg-[#F3F3F3]'>
                <div className='text-center py-10  max-w-[1064px] mx-auto'>
                    <div className='max-w-[800px] mx-auto pt-12'>
                        <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>EDUCATION</p>
                        <p className='text-3xl max-w-[600px] mx-auto font-semibold text-monstrade-blue py-2 tracking-wider'>The Road to Professionalism Goes Through Good Education!</p>
                        <p className='font-light'>To achieve professionalism, it is not enough to just receive news and trading signals! Good education and informative videos about Forex are your most important tools on this process. With MonsTrade's special educational content in the Monstar application, you can reach professionalism faster.</p>
                    </div>
                </div>
                <div>
                    <img className='max-w-[760px] sm:w-auto w-80 mx-auto pb-20' src={MonstarEducation} alt="" />
                </div>
            </div>

            <div className='py-12'>
                <div className='border max-w-[1024px] mx-auto rounded-2xl shadow-xl mt-[-80px] bg-white'>
                    <div className='max-w-[800px] text-center mx-auto pt-12'>
                        <p className='text-lg bg-monstrade-blue inline-block p-1 text-white font-semibold'>ECONOMIC CALENDER</p>
                        <p className='text-3xl max-w-[600px] mx-auto font-semibold text-monstrade-blue py-2 tracking-wider'>Master All the Market Activities with Monstar!</p>
                        <p className='font-light'>With the economic calendar in Monstar, you no longer need an assistant! Monstar provides you with daily, weekly, and monthly events with an advanced economic calendar. This way, you can take positions according to events and important developments without the need for any other details, and make more informed trades.</p>
                    </div>
                    <div>
                        <img className='sm:max-w-[550px] sm:w-auto w-[280px] py-12 mx-auto' src={MonstarEconomicCalendar} alt="" />
                    </div>
                </div>
            </div>

            <div className='bg-[#F3F3F3]'>
                <div className='max-w-[800px] text-center mx-auto pt-12'>
                    <p className='text-3xl max-w-[450px] mx-auto font-semibold text-monstrade-blue py-2 tracking-wider'>Download Monstar from any Platform You Wish</p>
                    <p className='font-light'>Download Monstar anytime and from anywhere to your Android or IOS device and start using it! Get all the tools you need for trading with MonsTrade, all in one application.</p>
                </div>
                <div>
                    <img className='max-w-[100px] py-12 mx-auto' src={MonstarQR} alt="" />
                </div>
                <div>
                    <img className='max-w-[300px] pb-12 mx-auto' src={MonstarLogos} alt="" />
                </div>
            </div>
        </div>
    )
}