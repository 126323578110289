import React from 'react'
import Banner from '../../assets/images/banners/loss-refund-banner.png'

const LossRefund = () => {
  return (
    <>
      <div className='bg-monstrade-blue'>
        <div className='grid lg:grid-cols-2 max-w-[1440px] mx-auto h-[524px] items-center'>
          <div className='text-white mx-2'>
            <p className='text-xl font-semibold'>| MONSTRADE PRIVILEGES</p>
            <p className='text-5xl font-semibold py-28'>First Trade</p>
            <p className='text-sm max-w-[460px]'>Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.</p>
          </div>
          <div>
            <img src={Banner} alt="" />
          </div>
        </div>
      </div>
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='max-w-[1440px] mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            A certain percentage of the total loss during the week, as determined by the investor, is refunded to the investor at the end of the week.
            <br/><br/>
            Before benefiting from the LOSS REFUND application, the investor must activate the application by requesting it through their investment consultant or institutional support lines.
            <br/><br/>
            Within the scope of the application, the weekly total loss is covered by MONSTRADE, not exceeding 10% of the investor's investment amount; up to 250 USD for the Cent category, 350 USD for the Classic category, 500 USD for the Prime category, and 750 USD for the ECN category.
            <br/><br/>
            Within the application, the weekly total loss will be covered by MONSTRADE according to the rules.
            <br/><br/>
            The refunded investment amount in the LOSS REFUND application is credited back to the investor's account as MONSCREDIT.
            <br/><br/>
            The LOSS REFUND application covers the total loss in all weekly transactions and can be used if the investor incurs a minimum of 100 USD loss for each category to benefit from this application.
            <br/><br/>
            The LOSS REFUND privilege becomes active in the week selected by the investor. It is a one-time-use campaign and cannot be used in the next week after the application is activated.
            <br/><br/>
            These losses will be credited to the investor's account as MONSCREDIT.
            <br/><br/>
            The general rules of the MonsCredit application apply. It cannot be used as withdrawable capital in withdrawals.
            <br/><br/>
            The application takes place if requested between 20:00-23:00 on Fridays.
            <br/><br/>
            In case of any misuse, Monstrade reserves the right to ban the investor from the application.
          </p>
        </div>
      </div>
    </>
  )
}

export default LossRefund