import React from "react";
import TradingBonusImg from "../assets/images/mons-blue-bg.png"
import ZeroCommisionIco from "../assets/icons/zero_commision.svg"
import HandMoneyIco from "../assets/icons/hand_money.svg"
import CardIco from "../assets/icons/card_ico.svg"
import PercentIco from "../assets/icons/circle_percent.svg"
import TradingBonusContent from "../components/PromotionComps/TradingBonusContent";
import PromotionButtons from "../components/PromotionComps/PromotionButtons";
import PromotionFooter from "../components/PromotionComps/PromotionFooter";
import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";

const landingOptions = {
    image: TradingBonusImg,
    mainZindex: 'z-[-1]',
    header: {
        text: 'Double Your Trading Deposit',
        size: 'text-[54px]',
    },
    subHeader: {
        text: "Gain gifts to use in your trades during your deposits to MonsTrade",
        align: "text-center",
        size: 'text-[24px]',
    }
}
const cards = [
    { header: 'Zero Fees', description: 'Zero fees on credit card/electronic wallet deposits and withdrawals', icon: ZeroCommisionIco, link: '' },
    { header: 'Zero Commission', description: 'Zero fees on deposits / withdrawals made by wire transfer over 200 USD, or currency equivalent', icon: HandMoneyIco, link: '' },
    { header: 'Instant Funding', description: 'Instant account funding via all credit cards/electronic wallets', icon: CardIco, link: '' },
    { header: 'No Comission', description: 'No hidden commissions', icon: PercentIco, link: '' },
]

const TradingBonusPage = () => {
    return (
        <>
            {/* <TopBanner firstIcon={ZeroCommisionIco} secondIcon={HandMoneyIco} thirdIcon={CardIco} forthIcon={PercentIco} mainTopic={mainTopic} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={CashbackImg} is4Columns={true} />  */}
            <BannerContainer options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-2'} />
                </div>
            </BannerContainer>
            <div className="container-full bg-slate-50 py-8">
                <div className="container-max mx-auto bg-white py-10">
                    <TradingBonusContent />
                    <PromotionButtons depositAccount={true} />
                </div>
            </div>
            {/* <PromotionFooter /> */}
        </>
    )
}

export default TradingBonusPage;