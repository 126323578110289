
const accountTypeForexContent = () => {
    return (
        [
            {
                id: 0,
                recomended: false,
                command: '',
                header: 'ECN Account',
                description: 'An account type with the best trading\
                 conditions available at the company. It is suitable\
                 for both currency and other types of markets.',
                table: [
                    { column: 'Initial deposit', value: '10000 USD' },
                    { column: 'Execution type', value: 'Market Execution' },
                    { column: 'Spreads', value: 'Floating from 0.6 points' },
                    { column: 'Bonuses', value: 'Reduced number of promotions' }
                ]
            },
            {
                id: 1,
                recomended: true,
                command: 'CLIENTS’ CHOICE',
                header: 'Prime Account',
                description: 'An account type with the best trading\
                 conditions available at the company. It is suitable\
                 for both currency and other types of markets.',
                table: [
                    { column: 'Initial deposit', value: '2500 USD' },
                    { column: 'Execution type', value: 'Market Execution' },
                    { column: 'Spreads', value: 'Floating from 0.8 points' },
                    { column: 'Bonuses', value: 'Reduced number of promotions' }
                ]
            },
            {
                id: 2,
                recomended: false,
                command: 'CLIENTS’ CHOICE',
                header: 'Classic Account',
                description: 'An account type with the best trading\
                 conditions available at the company. It is suitable\
                 for both currency and other types of markets.',
                table: [
                    { column: 'Initial deposit', value: '100 USD' },
                    { column: 'Execution type', value: 'Market Execution' },
                    { column: 'Spreads', value: 'Floating from 1.2 pips' },
                    { column: 'Bonuses', value: 'All promotions' }
                ]
            }
        ]
    )
}

export default accountTypeForexContent;