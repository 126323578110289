import { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { animated, useSpring } from '@react-spring/web'


const SliderV2Carousel = ({ children, onIndexChange = () => { }, hideArrows = false, indexValue = 0, width = 240, trashold_scale = 0.5, trashold_opacity = 0.15 }) => {

    const [carouselIndex, setCarouselIndex] = useState(0)

    const handleCarouselIndex = (direction) => {
        setCarouselIndex(prevState => {
            return (prevState + (direction ? prevState < children.length - 1 ? 1 : -1 * (children.length - 1) : prevState > 0 ? -1 : children.length - 1))
        })
    }

    useEffect(() => {
        onIndexChange(carouselIndex)
    }, [carouselIndex])    
    
    useEffect(() => {
        // console.log(indexValue)
        setCarouselIndex(indexValue)
    }, [indexValue]) 

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    width: width,
                    height: 660,
                    position: 'relative'
                }}
            >
                {children.map((item, itemIndex) => (
                    <CarouselCard
                        key={itemIndex}
                        carouselIndex={carouselIndex}
                        itemCount={children.length}
                        itemIndex={itemIndex}
                        width={width}
                        trashold_scale={trashold_scale}
                        trashold_opacity={trashold_opacity}
                    >
                        {item}
                    </CarouselCard>
                ))}
            </div>
            {!hideArrows &&
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 10,
                    }}
                >
                    <p
                        onClick={() => handleCarouselIndex(false)}
                    >Prev</p>
                    <p
                        onClick={() => handleCarouselIndex(true)}
                    >Next</p>
                </div>
            }
        </div>
    )
}

export default SliderV2Carousel

const CarouselCard = ({ children, carouselIndex, itemIndex, itemCount, width, trashold_scale, trashold_opacity }) => {

    const ref = useRef(0)

    const [springs, api] = useSpring(() => ({
        from: { x: 0, scale: 1, opacity: 1 },
    }))

    let cIndex = (itemIndex - carouselIndex >= 0 ? itemIndex - carouselIndex : (itemCount) + (itemIndex - carouselIndex))
    let refIndex = (itemIndex - ref.current >= 0 ? itemIndex - ref.current : itemCount + (itemIndex - ref.current))

    useEffect(() => {
        api.start({
            from: {
                x: -1 * ((width / 2) * refIndex),
                scale: 1 - (((1 - trashold_scale) / (itemCount - 1)) * refIndex),
                opacity: 1 - (((1 - trashold_opacity) / (itemCount - 1)) * refIndex),
            },
            to: {
                x: -1 * ((width / 2) * cIndex),
                scale: 1 - (((1 - trashold_scale) / (itemCount - 1)) * cIndex),
                opacity: 1 - (((1 - trashold_opacity) / (itemCount - 1)) * cIndex),
            },
        })
        ref.current = carouselIndex
    }, [carouselIndex])

    return (
        <animated.div
            style={{
                zIndex: (itemCount - cIndex),
                position: 'absolute',
                ...springs
            }}
        >
            {children}
        </animated.div>
    )
}
