import React from "react";

const CashbackContent = () => {
    return(
        <>
            <div className="p-8">
                <p className="font-semibold text-lg">Dear clients!</p>
                <br/>
                <p>
                    We are happy to inform you "Cashback from MonsTrade": Chance to win cashback per lot. The increased repayment rate is valid indefinitely and is available to all participants of its program.
                </p>
                <br/>
                <p>
                    Trade more, get more cashback from Monstrade 
                    to your account.
                </p>
                <br/>
                <p>
                    Receive up to $3 for every lot traded!
                </p>
                <br/>
                <p className="font-semibold text-lg">How it works</p>
                <br/>
                <p>
                    As part of the cashback program, MonsTrade returns part of the spread to active clients. Everything is simple: the more you trade, the bigger part of the spread is returned to your trading account!
                </p>

            </div>
        </>
    )
}

export default CashbackContent;