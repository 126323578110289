import React from 'react'

import { WhyChooseIconCard } from '../components/Cards/WhyChooseIconCard'

//icons
import candlestick from '../assets/icons/candlestick-who.svg'
import chartwho from '../assets/icons/chart-who.svg'
import dolorwho from '../assets/icons/dolor-who.svg'
import medalwho from '../assets/icons/medal-who.svg'
import moneywho from '../assets/icons/money-who.svg'
import personwho from '../assets/icons/person-who.svg'
import phonewho from '../assets/icons/phone-who.svg'
import shieldwho from '../assets/icons/shield-who.svg'

export const WhyChoose = () => {
    const cards = [
        { icon : candlestick, text : 'Spreads From 0.0 Pips'},
        { icon : chartwho, text : 'Access to The Most Liquid Markets'},
        { icon : personwho, text : 'Multi-account Management'},
        { icon : phonewho, text : '24/5, Multingual Customer Service'},
        { icon : dolorwho, text : '3D-secure Transactions, Ultra-fast Order Execution'},
        { icon : medalwho, text : 'Regulated and Trusted'},
        { icon : shieldwho, text : 'Safe trade'},
        { icon : moneywho, text : 'Fast withdrawal'},
    ]
    return (
        <div className='container-full'>
            <div className='container-max mx-auto'>
                <div className='py-24' >
                    <div className='text-center mb-24' >
                        <h2 className='text-4xl' >Why Choose Monstrade?</h2>
                    </div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-12'>
                        { cards.map( (item) => {
                            return <WhyChooseIconCard icon={item.icon} text={item.text} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
