import React from 'react'
import Banner from '../../assets/images/banners/swap-day-banner.png'

const SwapDay = () => {
  return (
    <>
      <div className='bg-monstrade-blue'>
        <div className='grid lg:grid-cols-2 max-w-[1440px] mx-auto h-[524px] items-center'>
          <div className='text-white mx-2'>
            <p className='text-xl font-semibold'>| MONSTRADE PRIVILEGES</p>
            <p className='text-5xl font-semibold py-28'>Swap Day</p>
            <p className='text-sm max-w-[460px]'>Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.</p>
          </div>
          <div>
            <img src={Banner} alt="" />
          </div>
        </div>
      </div>
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='max-w-[1440px] mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            A portion of the expense incurred by the extension of a trade to the next day through the privilege of Swapday is credited to the investor's account as MonsCredit.
            <br/><br/>
            To benefit from the Swapday privilege, the investor needs to request this option from their investment consultant or institutional support lines.
            <br/><br/>
            The Swapday privilege is valid during the time frame earned by the investor within the scope of the campaign they are involved in, and there is no refund right for the Swap expenses incurred after the expiration of the period.
            <br/><br/>
            MonsTrade reserves the right to make restrictions and changes related to risk management regarding this privilege.
            <br/><br/>
            There is absolutely no retrospective Swapday campaign.
            <br/><br/>
            In case of misuse of the relevant privilege, MonsTrade reserves the right to invalidate the privilege without any need for approval or justification.
          </p>
        </div>
      </div>
    </>
  )
}

export default SwapDay