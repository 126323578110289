import React from 'react'
import Banner from '../../assets/images/banners/monscredit-banner.png'

const MonsCredit = () => {
  return (
    <>
      <div className='bg-monstrade-blue'>
        <div className='grid lg:grid-cols-2 max-w-[1440px] mx-auto h-[524px] items-center'>
          <div className='text-white mx-2'>
            <p className='text-xl font-semibold'>| MONSTRADE PRIVILEGES</p>
            <p className='text-5xl font-semibold py-28'>MonsCredit</p>
            <p className='text-sm max-w-[460px]'>Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.</p>
          </div>
          <div>
            <img src={Banner} alt="" />
          </div>
        </div>
      </div>
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='max-w-[1440px] mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            MonsCredit is a type of collateral support incentive available within Monstrade. With Monstrade, you can earn MonsCredit through various promotions.
            <br/><br/>
            MonsCredit is credited to the investor's account upon request.
            <br/><br/>
            There is no expiration date for MonsCredit. Investors will have an equal amount of MonsCredit deducted from their account when making withdrawals. For example, if an investor with a balance of 1000USD and 500USD of MonsCredit requests a withdrawal of 300USD, 150USD of MonsCredit will also be deducted from their account.
            <br/><br/>
            MonsCredit allows you to increase your collateral, enabling you to take positions with high lot values.
            <br/><br/>
            The percentage rates of MonsCredit rewards may vary depending on the promotions.
            <br/><br/>
            MonsCredit is not a spendable amount.
            <br/><br/>
            MonsTrade reserves the right to impose restrictions and make changes related to this privilege within the scope of risk management.
          </p>
        </div>
      </div>
    </>
  )
}

export default MonsCredit