import React, { Fragment } from 'react'
import ReactCountryFlag from "react-country-flag"

export const CountryFlagLabels = ({country, text}) => {
  return (
    <Fragment>
        <ReactCountryFlag countryCode={country} title={country}/> {text}
    </Fragment>
  )
}
