import React from "react";
import CashbackImg from "../assets/images/mons-blue-bg.png"
import DollarIco from "../assets/icons/dollar_cashback.svg"
import WalletIco from "../assets/icons/balance_ico.svg"
import ServerIco from "../assets/icons/cloud-server.svg"
import PersonIco from "../assets/icons/person.svg"
import Button from "../components/Button"
import VipContent from "../components/PromotionComps/VipContent"
import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";

const landingOptions = {
    image: CashbackImg,
    mainZindex: 'z-[-1]',
    header: {
        text: 'VIP Client',
        size: 'text-[54px]',

    },
    subHeader: {
        text: "Become a MonsTrade VIP client and get exceptional privileges.",
        align: "text-center",
        size: 'text-[24px]',
    }
}
const cards = [
    { header: 'Additional Cashback', description: 'Cashback (Rebates)', icon: DollarIco, link: '' },
    { header: 'Increased % on account balance', description: 'Having joined our VIP program, you have an opportunity to receive up to ekstra of additional payouts.', icon: WalletIco, link: '' },
    { header: 'Access to VPS server with no restrictions', description: 'No limitations if there is at least 1 transaction over the previous calendar month.', icon: ServerIco, link: '' },
    { header: 'Personal VIP manager', description: 'Personal VIP manager willanswer any question you might have.', icon: PersonIco, link: '' },
]

const VipPage = () => {
    return (
        <>
            {/* <TopBanner firstIcon={DollarIco} secondIcon={WalletIco} thirdIcon={ServerIco} forthIcon={PersonIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} topic4={topic4} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={CashbackImg} is4Columns={true} />  */}
            <BannerContainer options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-2'} />
                </div>
            </BannerContainer>
            <div className="mt-10">
                {/* <Button content="DEPOSIT FUNDS and become a VIP Client" color="bg-[#00D17F]" py="py-2" px="px-8" tempStyle="" isArrow={false} /> */}
                <center>
                    <a
                        href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard'
                        className="bg-[#00D17F] hover:bg-[#00d17dda] text-white font-bold py-3 px-12 rounded-[5px] flex flex-col items-center w-[260px]">
                        <span className="text-lg">DEPOSIT FUNDS</span>
                        <span className="text-sm">and become a VIP Client</span>
                    </a>
                </center>
            </div>
            <VipContent />
        </>
    )
}

export default VipPage;
