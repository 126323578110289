import React from "react";
import ForexBrokerSpreads from "../containers/ForexBrokerSpreads";
import { SpreadCalculationInfo } from "../containers/SpreadCalculationInfo";
import SpreadInForex from "../containers/SpreadInForex";
import SpreadsAffect from "../containers/SpreadsAffect";
import WhatIsSpread from "../containers/WhatIsSpread";
import {HighAndLowForexSpread}  from "../containers/HighAndLowForexSpread";
import { LowForexSpread } from "../containers/LowForexSpread";
import { MonstradeForexTradingAccount } from "../containers/MonstradeForexTradingAccount";
import { EcnCommissionCharges } from "../containers/EcnCommissionCharges";
import { ForexSpreadsFAQ } from "../containers/ForexSpreadsFAQ";
import { ForexSpreadSearch } from "../containers/ForexSpreadSearch";
import { StartTrading } from "../containers/StartTrading";
//component
import { BannerContainer } from '../components/Containers/BannerContainer'
//images
import bgImage from "../assets/images/banners/spreads-banner.png"
import indicesIcon from '../assets/images/indices.svg'
import forexIcon from '../assets/images/forex-icon.svg'
import cryptoIcon from '../assets/images/cryptocurrencies-icon.svg'
import comonditiesIcon from '../assets/images/comondities-icon.png'
import metalsIcon from '../assets/images/metals-icon.svg'
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";
import PricingMenu from '../components/PricingMenu';

const Spreads = () => {

    const cards = [
        { header: 'Indices', icon: indicesIcon, link: '', btnText : 'View Spreads' },
        { header: 'Forex', icon: forexIcon, link: '', btnText : 'View Spreads' },
        { header: 'Cryptocurrencies', icon: cryptoIcon, link: '', btnText : 'View Spreads' },
        { header: 'Commodities', icon: comonditiesIcon, link: '', btnText : 'View Spreads' },
        { header: 'Metals', icon: metalsIcon, link: '', btnText : 'View Spreads' }
    ]

    const landingOptions = {
        image : bgImage,
        imageGradient: "linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2))",
        container : {
          width : 'w-3/4',
          padding : 'py-20'
        },
        header: {
          text : 'Forex Spreads',
          size: 'text-3xl'
        },
        subHeader: {
          text : "Forex spread is the difference between the buy price and the sell price. Spread cost is measured \
          in 'pips' and the cost of trade. Popular currency pairs such as EUR/GBP and AUD/USD have lower \
          spreads as a result. higher liquidity levels. Monstrade offers you one of the lowest spreads on the \
          market"},
        button: {
            bgcolor: "bg-white",
            textcolor: "text-monstrade-blue",
            text: "Start Trading",
            padding: "pt-4",
            hover: 'hover:bg-monstrade-green'
        }
      }

    return (
        <>
            <BannerContainer options={landingOptions}/>
            <PricingMenu isActive="spreads"/>
            <SimpleCardListContainer 
                cards={cards}
                header={'Click on a Product to View Our Typical Spreads'} />
            <SpreadsAffect />
            <WhatIsSpread />
            <SpreadInForex />
            <ForexBrokerSpreads />
            <SpreadCalculationInfo />
            <HighAndLowForexSpread />
            <LowForexSpread />
            <MonstradeForexTradingAccount />
            <EcnCommissionCharges />
            <ForexSpreadSearch />
            <StartTrading />
            <ForexSpreadsFAQ />
        </>
    )
}

export default Spreads;