import React from "react";
import BlueTick from "../../assets/icons/blue-tick.svg"
import NewAccount from "../../assets/icons/new-account-black.svg"
import LaptopCoffee from "../../assets/icons/laptop-coffe-black.svg"
import GrayWallet from "../../assets/icons/black-wallet.svg"
import SwipeIco from "../../assets/icons/swipe-left.png"

const VipContent = () => {

    const memberships = [
        {
            id: 'silver',
            name: 'SILVER',
            cashback: '20%',
            accountBalance: '20%',
            vpsConditions: 'Improvement of VPS server order conditions',
            vipManager: 'Personal VIP manager',
        },
        {
            id: 'gold',
            name: 'GOLD',
            cashback: '30%',
            accountBalance: '30%',
            vpsConditions: 'Improvement of VPS server order conditions',
            vipManager: 'Personal VIP manager',
        },
        {
            id: 'platinum',
            name: 'PLATINUM',
            cashback: '40%',
            accountBalance: '40%',
            vpsConditions: 'Improvement of VPS server order conditions',
            vipManager: 'Personal VIP manager',
        },
    ];
    return (
        <>
            <div className="container-full py-8">
                <div className="container-max mx-auto">
                    <div className="text-center">
                        <p className="text-[40px]">VIP Program Advantages</p>
                        <br />
                        <p className="text-xl">VIP Program levels are granted to forex traders depending on the total amount of <br /> funds on their trading accounts.</p>
                    </div>

                    <div className="py-16">
                        <div class="flex flex-col md:overflow-hidden overflow-x-auto flex-wrap">
                            <div class="sm:-mx-6 lg:-mx-8">
                                <div class="py-2 max-w-[1000px] text-center sm:px-6 lg:px-8 mx-auto">
                                    <div class="overflow-hidden">
                                        <table class="min-w-full">
                                            <thead class="bg-white border-b">
                                                <tr>
                                                    <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4">
                                                        <div className="sm:hidden block">
                                                            <img className="w-10 float-right" src={SwipeIco} alt="" />
                                                        </div>
                                                    </th>
                                                    <th scope="col" class="text-sm font-medium text-white silver-gradient px-6 py-4">
                                                        SILVER
                                                    </th>
                                                    <th scope="col" class="text-sm font-medium text-white gold-gradient px-6 py-4">
                                                        GOLD
                                                    </th>
                                                    <th scope="col" class="text-sm font-medium text-white platinium-gradient px-6 py-4">
                                                        PLATINIUM
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">Additional Cashback(Rebates)</td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        20%
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        30%
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        40%
                                                    </td>
                                                </tr>
                                                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">More % on account balance</td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        20%
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        30%
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        40%
                                                    </td>
                                                </tr>
                                                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                                    <td class="px-6 py-4 text-sm font-medium text-gray-900 text-left">Improvement of VPS server order conditions</td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        <img src={BlueTick} alt="" className="mx-auto" />
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        <img src={BlueTick} alt="" className="mx-auto" />
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        <img src={BlueTick} alt="" className="mx-auto" />
                                                    </td>
                                                </tr>
                                                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">Personal VIP manager</td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        <img src={BlueTick} alt="" className="mx-auto" />
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        <img src={BlueTick} alt="" className="mx-auto" />
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                        <img src={BlueTick} alt="" className="mx-auto" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center py-4">
                        <p className="text-[35px] leading-[40px]">How to get VIP conditions for your account?</p>
                        <br />
                        <p className="text-xl">After the total amount of funds for forex trading on your accounts reaches the required <br /> number, the VIP status will be granted automatically.</p>
                    </div>
                    <div className="grid lg:grid-cols-3 grid-rows-1 py-8 sm:text-left text-center gap-4">
                        <div className="grid-rows-2 lg:w-[300px] py-4">
                            <div className=" bg-slate-200 flex justify-start items-center py-4 pl-3 relative rounded-[6px]">

                                <img src={NewAccount} alt="" className="float-left" />
                                <p className="text-monstrade-blue font-semibold text-lg">Open a trading account</p>

                                <span className="absolute bg-monstrade-blue text-white px-2 right-0 top-0 rounded-tr-[6px]">1</span>
                            </div>
                            <div>
                                <p className="text-sm pt-4">To get access to the “VIP program, you have to have one or several open accounts at Monstrade.</p>
                            </div>
                        </div>
                        <div className="grid-rows-2 lg:w-[300px] py-4">
                            <div className=" bg-slate-200 flex justify-start items-center py-4 pl-3 relative rounded-[6px] gap-2">
                                <img src={LaptopCoffee} alt="" className="float-left" />
                                <p className=" text-monstrade-blue font-semibold text-lg">Fulfill the requirements for the total amount of funds</p>
                                <span className="absolute bg-monstrade-blue text-white px-2 right-0 top-0 rounded-tr-[6px]">2</span>
                            </div>
                            <div>
                                <p className="text-sm pt-4">Make the total amount of funds on your accounts reach the required number by using any of more than 10 methods of depositing.</p>
                            </div>
                        </div>
                        <div className="grid-rows-2 lg:w-[300px] py-4">
                            <div className=" bg-slate-200 flex justify-start items-center py-4 pl-3 relative rounded-[6px] gap-2">

                                <img src={GrayWallet} alt="" className="float-left" />
                                <p className="text-monstrade-blue font-semibold text-lg">Benefit from VIP conditions</p>
                                <span className="absolute bg-monstrade-blue text-white px-2 right-0 top-0 rounded-tr-[6px]">3</span>

                            </div>
                            <div>
                                <p className="text-sm pt-4">Get ready to earn more</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div class="flex lg:flex-row flex-col gap-3">
                            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard'>
                                <button
                                    href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard'
                                    type="button"
                                    class="px-12 py-4 bg-[#00D17F] text-white font-medium text-lg leading-tight uppercase rounded shadow-md"
                                >OPEN ACCOUNT</button>
                            </a>
                            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard'>
                                <button

                                    type="button"
                                    class="px-12 py-4 bg-monstrade-blue text-white font-medium text-lg leading-tight uppercase rounded shadow-md"
                                >DEPOSIT FUNDS</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VipContent;