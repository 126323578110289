import React from 'react';
import PhoneMockup from '../assets/images/phone_mockup.png'
import MetatraderIco from '../assets/icons/metatrader5.svg'

const Phone = (props) => {
    return(
        <div className="w-screen -mb-2 mx-auto lg:h-[64vw] xl:max-h-[60vw] h-auto xl:bg-transparent bg-black relative break-words">
            <div className='xl:visible invisible absolute z-[-1]'>
                <img src={PhoneMockup} alt="" />
            </div>
            <div className="grid-rows-4 text-center text-white xl:float-right xl:pr-36 md:py-32 py-10">
                <div className='mb-3'>
                    <p className='text-3xl font-semibold'>Monstrade Trading Platforms</p>
                </div>
                <div className=''>
                    <p className='md:w-[490px] w-full mx-auto px-4'>You can reach out to the Monstrade from any devices to trade with
                    competitive conditions. All you need to do that just choose a platform
                    and start your investment in {props.pageName}.</p>
                </div>
                <div className='py-4'>
                    <a className="text-monstrade-blue underline-offset-2" href="/platforms/computer/mt5-for-pc">Download a platform and invest in forex</a>
                </div>
                <div className='xl:pt-[13vw] pt-10'>
                    <img className='mx-auto' src={MetatraderIco} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Phone;