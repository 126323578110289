import React, {useState} from "react";
import { useEffect } from "react";
import Select from "react-select";
import { accountClassContent, accountCurrencyContent, instrumentContent, typeContent } from "../../features/content/calculations.content";

export const CalculatorForm = ({ content }) => {
    const accountCurrencies = accountCurrencyContent();
    const accountClasses = accountClassContent();
    const instrumentes = instrumentContent();
    const types = typeContent();

    const [calculationResult, setCalculationResult] = useState([]);

    const marginCalculation = () => {
        setCalculationResult([
            {name : 'CURRENT CONVERSION RATE', value : 0},
            {name : 'REQUIRED MARGIN', value : 0}
        ])
    }

    const currencyCalculation = () => {
        setCalculationResult([
            {name : 'CURRENT CONVERSION RATE', value : 0},
            {name : 'CURRENT CONVERSION PRICE', value : 0}
        ])
    }

    const pipCalculation = () => {
        setCalculationResult( [
            {name : 'CURRENT CONVERSION RATE', value : 0},
            {name : 'PIP VALUE', value : 0}
        ])
    }

    const swapCalculation = () => {
        setCalculationResult( [
            {name : 'CURRENT CONVERSION RATE', value : 0},
            {name : 'SWAP LONG', value : 0},
            {name : 'SWAP SHORT', value : 0},
        ])
    }

    const profitCalculation = () => {
        setCalculationResult( [
            {name : 'CURRENT CONVERSION RATE', value : 0},
            {name : 'CURRENT STOP LOSS', value : 0},
            {name : 'CURRENT TAKE PROFIT', value : 0},
        ])
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('start')
        if(content.name === 'Margin') return marginCalculation()
        if(content.name === 'Currency') return currencyCalculation()
        if(content.name === 'Pip') return pipCalculation()
        if(content.name === 'Swap') return swapCalculation()
        if(content.name === 'Profit') return profitCalculation()
        console.log('end')
    }

    useEffect(() => {
        setCalculationResult([])
    }, [content])
    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 grid-rows-1">
                    {content.options.map((item) => {
                        let options;
                        if (item.id === 'accountCurrency' ||
                            item.id === 'from' ||
                            item.id === 'to') options = accountCurrencies;
                        if (item.id === 'accountClass') options = accountClasses;
                        if (item.id === 'instrument') options = instrumentes;
                        if (item.id === 'type') options = types;


                        return (
                            <div key={item.id} >
                                {item.type === 'select' ?
                                    <div className="mb-3 xl:w-[336px] lg:mr-3 w-full">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label inline-block mb-2 text-gray-700"
                                        >
                                            {item.name}
                                        </label>
                                        <Select options={options} />
                                    </div>
                                    : <></>
                                }
                                {item.type === 'text' ?
                                    <div className="mb-3 xl:w-[336px] lg:mr-3 w-full">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label inline-block mb-2 text-gray-700"
                                        >
                                            {item.name}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 
                                                        bg-white bg-clip-padding border border-solid border-gray-300 rounded 
                                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                                                        focus:border-blue-600 focus:outline-none"
                                            id="exampleFormControlInput1"
                                            placeholder="Example label"
                                        />
                                    </div> : <></>
                                }
                            </div>

                        );
                    })}
                    <div className="mb-3 xl:w-[336px] flex relative h-[71px]">
                        <button className="bg-mons-blue-1 text-white h-[39px] w-full absolute bottom-0" 
                                type='submit'>
                            CALCULATE
                        </button>
                    </div>
                </div>
            </form>
            <div className={`grid grid-cols-${calculationResult.length} mt-[-50px] mb-24`}>
                {calculationResult.map( (item) => {
                    return(
                        <div className="mt-24 text-center" >
                            <p className="text-mons-gray-3 mb-6" >{item.name}</p>
                            <p className="font-bold text-xl" >{item.value}</p>
                        </div>
                    )
                })}
            </div>
            {calculationResult.length === 0 && content.description ? 
            <div>
                <div className="grid md:grid-cols-2 grid-rows-1 md:text-left text-center pt gap-6">
                    <div>
                        <p className="text-lg font-bold mb-4">How it works</p>
                        <p dangerouslySetInnerHTML={{__html : content.description.howItWorks}} ></p>
                    </div>
                    <div>
                        <p className="text-lg font-bold mb-4" >Example:</p>
                        <p dangerouslySetInnerHTML={{__html : content.description.example}} ></p>
                    </div>
                </div>
            </div>
            : <></>}
            <div className="py-8">
                <div className="grid-rows-4 text-monstrade-blue">
                    <div className="py-4 font-bold text-xl text-black"><p>The calculation is performed as follows:</p></div>
                    <div className="py-4"><p><span className="font-semibold text-xl">Forex</span><br/>Lots * contract size / leverage * conversion rate to account currency</p></div>
                    <div className="py-4"><p><span className="font-semibold text-xl">Indices</span><br/>Lots * contract size * current price / tick size * tick price * percentage / 100 * conversion rate to account currency</p></div>
                    <div className="py-4"><p><span className="font-semibold text-xl">Energies, Metals, CFD Stocks</span><br/>Lots * contract size * current price * percentage / 100 * conversion rate to account currency</p></div>
                </div>
            </div>
        </div>
    );
};





