import React from 'react'
import Iframe from 'react-iframe'

export const GoogleMapContainer = () => {
  const containerStyle = {
    width: '350px',
    height: '350px'
  };

  const center = {
    lat: -3.745,
    lng: -38.523
  };
  return (
    <div>
      <Iframe 
        url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.433531413289!2d20.424547!3d44.812731899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a656076a4a65f%3A0xd106aa2e75ecbed0!2sAntifa%C5%A1isti%C4%8Dke%20borbe%2029%2C%20Beograd%2011070%2C%20Serbia!5e0!3m2!1sen!2str!4v1671047848836!5m2!1sen!2str' 
        styles={containerStyle}
        />
    </div>
  )
}
