import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
//images
import bgImage from '../assets/images/license-banner.png'
import image1 from '../assets/images/lisence-commission-logo.png'
import image2 from '../assets/images/lisence-verify-my-trade-logo.png'
import image3 from '../assets/images/license-certification.png'
import icon1 from '../assets/icons/license-umbrella-icon.svg'
import icon2 from '../assets/icons/piggybank-icon.png'
import icon3 from '../assets/icons/person-w-search.png'
import icon4 from '../assets/icons/shield-icon-lisence.png'

export const LicenseAndRegistration = () => {

    return (
        <div>
            <ContainerWithBGImage image={bgImage}>
                <div className='z-40 mt-24' >
                    <h1 className='text-6xl font-bold text-white pt-24 mb-6' >You’re Backed by Solid <br /> Regulation</h1>
                    <p className='text-white text-xl' >
                        Over the years of the Company’s existence,the letter A <br />
                        has acquired several different meanings, which now <br />
                        shape our mission and philosophy:
                    </p>
                </div>
                <div className='absolute z-40 -bottom-4 left-[40vw]' >
                    <a href='/' className='px-4 py-2 bg-monstrade-green text-white font-semibold text-3xl rounded-md' >START INVESTING</a>
                </div>
            </ContainerWithBGImage>
            <div className='container-full'>
                <div className='container-max mx-auto pt-24 mb-20 flex'>
                    <div className='flex mx-auto w-3/4'>
                        <div className='mr-10 min-w-[244px]'>
                            <img src={image1} alt="" />
                        </div>
                        <div className='flex'>
                            <div className='my-auto' >
                                <h2 className='mb-5 text-2xl leading-7' >
                                    Monstrade – member of The Financial Commission since 2013
                                </h2>
                                <p className='leading-5' >
                                    Monstrade is a member of The Financial Commission, a recognized
                                    independent mediator in the financial services industry that
                                    ensures the transparency and integrity of a broker and provides
                                    a Compensation Fund for up to €20,000 per trade claim.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-full'>
                <div className='container-max mx-auto flex'>
                    <div className='flex mx-auto w-3/4 mb-20'>
                        <div className='flex'>
                            <div className='my-auto' >
                                <h2 className='mb-5 text-2xl leading-7' >
                                    Monstrade – member of The Financial Commission since 2013
                                </h2>
                                <p className='leading-5 text-gray-500' >
                                    Monstrade is a member of The Financial Commission, a recognized
                                    independent mediator in the financial services industry that
                                    ensures the transparency and integrity of a broker and provides
                                    a Compensation Fund for up to €20,000 per trade claim.
                                </p>
                            </div>
                        </div>
                        <div className='ml-10 min-w-[244px]'>
                            <img src={image2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-full'>
                <div className='container-max mx-auto mb-12  '>
                    <div className='w-3/4 mx-auto' >
                        <p className='text-gray-500 text-center leading-6 mb-8' >
                            The decisions of The Financial Commission regarding its members
                            are binding. Should a Company refuse to adhere to a judgment from
                            <span className='text-mons-blue-1' >The Financial Commission</span> and provide compensation to a client,
                            it will be excluded from the members of The Financial Commission
                            and compensation to the client will be paid from the Commission’s
                            Compensation Fund.
                        </p>
                        <p className='text-center font-semibold' >Each Monstrade’ client is guaranteed insurance coverageup to <span className='text-mons-blue-1'>20,000 EUR</span> per claim.</p>
                    </div>
                </div>
                <div className='container-max mx-auto flex mb-24'>
                    <div className='m-auto' >
                        <img src={image3} alt="" />
                    </div>
                </div>
                <div className='container-max mx-auto flex'>
                    <div className='w-3/5 mx-auto text-center mb-12'>
                        <p className='text-lg text-gray-500'>
                            Your security goes beyond regulation. We’ve taken every measure necessary
                            to protect your funds and keep you as safe as possible.
                        </p>
                    </div>
                </div>
                <div className='container-max mx-auto flex'>
                    <div className='grid grid-cols-2 gap-24'>
                        <div className='border-[1px] border-mons-blue-1 px-16 py-24'>
                            <div className='mb-5' >
                                <img src={icon1} alt="" />
                            </div>
                            <div>
                                <p className='font-bold mb-6' >Segregated Accounts</p>
                                <p>Know your funds are entirely separated from Monstrade’s funds.</p>
                            </div>
                        </div>
                        <div className='border-[1px] border-mons-blue-1 px-16 py-24'>
                            <div className='mb-5' >
                                <img src={icon2} alt="" />
                            </div>
                            <div>
                                <p className='font-bold mb-6' >Compensation Fund</p>
                                <p>
                                    Monstrade is a member of the Financial Commission 
                                    that works to protect your interests against 
                                    those of a broker.
                                </p>
                            </div>
                        </div>
                        <div className='border-[1px] border-mons-blue-1 px-16 py-24'>
                            <div className='mb-5' >
                                <img src={icon3} alt="" />
                            </div>
                            <div>
                                <p className='font-bold mb-6' >Third-Party Auditing</p>
                                <p>
                                    A reputable third-party company audits your balance 
                                    with Monstrade to ensure there are never any errors.
                                </p>
                            </div>
                        </div>
                        <div className='border-[1px] border-mons-blue-1 px-16 py-24'>
                            <div className='mb-5' >
                                <img src={icon4} alt="" />
                            </div>
                            <div>
                                <p className='font-bold mb-6' >Segregated Accounts</p>
                                <p>Know your funds are entirely separated from Monstrade’s funds.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
