import React from 'react'
import styles from './CountrySessionCard.module.css'

export const CountrySessionCard = ({deg, city, hours}) => {
  return (
    <div className='block' >
      <div className={`h-[120px] w-[120px] border-4 border-mons-gray-3 rounded-full relative m-auto flex z-0 mb-4`} style={{rotate : `${deg}deg`}} >
        <div className={styles.clockContainer}>
          <div className={styles.circleContainer} >
            <div class={styles.quarterTopLeft}></div>
            <div class={styles.quarterTopRight}></div>
            <div class={styles.quarterBottomLeft}></div>
            <div class={styles.quarterBottomRight}></div>
          </div>
          <div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
            <div className={styles.hourTick}>
              <div className={styles.hourInner}></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className='font-bold' >{city} Session</p>
        <p className='text-mons-gray-3 text-lg pb-6' >{hours} (EST)</p>
      </div>
    </div>
  )
}
