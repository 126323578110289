import React from "react";

const InviteAFriendContent = () => {
    return(
        <>
            <div className="p-8 text-gray-700 md:text-left text-center">
                <p>
                    Are you already trading in the financial markets with MonsTrade? 
                <br/><br/>
                    Share your success with others! Tell your friends about trading conditions at MonsTradeand get $10 cash money or %20 credit for every friend you refer. All newly invited clients will get a 20% trading credit on every deposit over $100.
                <br/><br/>
                </p>
                <p className="font-bold text-black">
                    Promotion terms:<br/><br/>
                </p>
                <p>
                    participants must be at least 18 years old;
                    <br/><br/>
                    minimum deposit amount for a newly referred client is $100 USD ;
                    <br/><br/>
                    only verified accounts can participate in the promotion;
                    <br/><br/>
                    a client and a friend referred by that client must contact a manager and confirm that they meet the promotion terms;
                    the client will receive $10 for every friend he referred;
                    <br/><br/>
                    the referred friend will get a 20% trading credit on every deposit over $100 or its equivalent in other currency, valid for a month.
                    <br/><br/>
                    Please note, that this promotion is for MonsTrade’ clients only. Partners are not eligible to participate in this promotion.
                    <br/><br/>
                </p>
                <p className="font-bold text-black">
                    Bonus terms and conditions:
                </p>
                <p>
                    <br/>
                    the 20% bonus on every deposit over $100 is paid in the form of non-withdrawable credits, which are added to the balance and can be used in all trading operations;
                    <br/><br/>
                    the promotion is available for trading forex instruments on all accounts, in MetaTrader 5 platform only;
                    <br/><br/>
                    the maximum amount of bonus funds per client – $1000;
                    <br/><br/>
                    this bonus offer is not cumulative with any other MonsTrade’ bonuses and promotions;
                    <br/><br/>
                    bonus for the reporting period will be canceled, if a client fully or partially withdraws his initial deposit, the above-mentioned bonus has been calculated from;
                    <br/><br/>
                    other bonus terms and conditions are governed by the MonsTrade’ general Bonus Terms and Conditions regulations.
                </p>
            </div>
        </>
    )
}

export default InviteAFriendContent;