import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pcImage from '../assets/images/trade-pc-mockup.png';

export const EcnCommissionCharges = () => {
    return (
        <div className='container-full'>
            <div className='container-max mx-auto'>
                <div className='sm:block hidden'>
                    <h2 className='text-xl text-center my-6' >
                        MetaTrader Prime - ECN Account <br />
                        <span className='font-bold' >Commission Charges</span>
                    </h2>
                    <p className='px-10 mb-10 text-center' >
                    Check our commission fees for RAW ECN account based on the currency of your trading account. The table is divided by commission per lot to make it easier for you to trade. Calculate your costs based on transaction volume.
                    </p>
                    <div>
                        <table className="table-fixed w-full">
                            <thead className='bg-monstrade-blue rounded-t-lg' >
                                <tr className='text-white' >
                                    <th>Instrument</th>
                                    <th>Standart Account</th>
                                    <th colSpan={3} >RAW Account</th>
                                </tr>
                            </thead>
                            <tbody className='text-center font-light' >
                                <tr>
                                    <td className='bg-[#ECF1F5]' >Forex</td>
                                    <td className='bg-[#ECF1F5]' >Zero (Built into the spread)</td>
                                    <td className='bg-[#ECF1F5]' colSpan={3} >Zero (Built into the spread)</td>
                                </tr>
                               
                                <tr className='bg-[#E2E6EA]' >
                                    <td>Commodities</td>
                                    <td>Zero (Built into the spread)</td>
                                    <td colSpan={3} >Zero (Built into the spread)</td>
                                </tr>
                                <tr className='bg-[#ECF1F5]' >
                                    <td>Indices</td>
                                    <td>Zero (Built into the spread)</td>
                                    <td colSpan={3} >Zero (Built into the spread)</td>
                                </tr>
                                <tr className='bg-[#E2E6EA]' >
                                    <td>Metal</td>
                                    <td>Zero (Built into the spread)</td>
                                    <td colSpan={3} >Zero (Built into the spread)</td>
                                </tr>
                                <tr className='bg-[#ECF1F5]' >
                                    <td>Cryptocurrenies</td>
                                    <td>Zero (Built into the spread)</td>
                                    <td colSpan={3} >Zero (Built into the spread)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='my-10'>
                    <h3 className='text-3xl text-center' >
                        Now You Can See the <span className='text-monstrade-blue' >Bid/Ask</span> <br />
                        <span className='text-monstrade-blue' >Price</span> for Any Instrument
                    </h3>
                    <div className='grid lg:grid-cols-2 my-16 gap-10'>
                        <div>
                            <ul className='text-lg h-100 pr-10' >
                                <li className='flex py-5'>
                                    <div className='mr-3' ><FontAwesomeIcon icon="fa-regular fa-circle-check" className='text-monstrade-green'/></div>
                                    <p>Click on “View” and choose “Market Watch”</p>
                                </li>
                                <li className='flex py-5'>
                                    <div className='mr-3'><FontAwesomeIcon icon="fa-regular fa-circle-check" className='text-monstrade-green'/></div>
                                    <p>To see the spread for a particular symbol,
                                        right-click anywhere on the Market Watch
                                        window and select “Spread”</p>
                                </li>
                                <li className='flex py-5'>
                                    <div className='mr-3'><FontAwesomeIcon icon="fa-regular fa-circle-check" className='text-monstrade-green'/></div>
                                    <p>You can now see an additional column,
                                        displaying the spread for each currency
                                        pair, commodities or indices</p>
                                </li>
                                <li className='flex py-5'>
                                    <div className='mr-3'><FontAwesomeIcon icon="fa-regular fa-circle-check" className='text-monstrade-green'/></div>
                                    <p>Note that MT5 quotes spreads in
                                        MetaTrader 5 points. To find the spread
                                        in pips, you will need to divide the number by 10.</p>
                                </li>
                            </ul>

                        </div>
                        <div className='lg:block hidden'>
                            <img src={pcImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
