import React from "react";
import NavigationForPhonesItem from "./NavigationForPhonesItem";
import navigationForPhonesContent from "../features/content/navigationForPhones.content";

const content = navigationForPhonesContent();
const path = window.location.pathname;
const NavigationForPhones = () => {
    return (
        <>
            {path !== '/web-trader' ?
                <div className="container-full block md:hidden fixed inset-x-0 bottom-0 z-10 bg-monstrade-blue shadow">
                    <div className="grid grid-cols-5 text-center sm:text-xs text-[10px] whitespace-nowrap">
                        {content.map((item) => (
                            <NavigationForPhonesItem name={item.name} icon={item.icon} href={item.href} iconColor={item.iconColor} />
                        ))}
                    </div>
                </div> : 
                <></>
            }
        </>
    )
}

export default NavigationForPhones;