import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import image from '../assets/images/banners/ib-commission-banner.png';
import { PartnerReceive } from '../containers/PartnerReceive';
import { LoyalityProgram } from '../containers/LoyalityProgram';
import { AffiliateCommissionCalculatorContainer } from '../containers/AffiliateCommissionCalculatorContainer';
import { AffiliateAccounts } from '../containers/AffiliateAccounts';
import { MonstradePartneredReceive } from '../containers/MonstradePartneredReceive';
import { BannerContainer } from '../components/Containers/BannerContainer'

export const IBCommission = () => {

    const landingOptions = {
        image : image,
        imageGradient: "linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2))",
        container : {
          width : 'w-1/2',
          padding : 'py-20'
        },
        header: {
          text : 'Affiliate commission',
          size: 'text-3xl'
        },
        subHeader: {
            text : "Receive affiliate commission every day without any limitations on maximum payouts."
        },
        button: {
            bgcolor: "bg-monstrade-blue",
            textcolor: "text-white",
            text: "Become a Partner",
            subText: "Start earning in few minutes",
            padding: "pt-4"
        }
      }

    return (
    <div>
        <BannerContainer options={landingOptions}/>
        <PartnerReceive />
        <LoyalityProgram />
        {/* <AffiliateCommissionCalculatorContainer /> */}
        <AffiliateAccounts />
        <MonstradePartneredReceive />
    </div>
  )
}
