import React, {useState} from 'react'
import { useForm } from "react-hook-form";

export const DownloadConfigForm = ({ dataHeaders, exportPDF, apiData }) => {
    const [pageSize, setPageSize] = useState('A4')
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data =>{ 
        console.log(data);
        let keys = Object.keys(data);
        keys = keys.filter( (key) => { return data[key] ? 1 : 0})
        console.log(pageSize);
        console.log(keys)
        exportPDF(apiData, keys, pageSize)
    }
    const changeHandler = (e) => {
        setPageSize(e.target.value)
    }
    return (
        <div>
            <div className="block px-10 py-3 rounded-lg shadow-lg bg-white max-w-sm">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label for="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Download Configuration</label>
                    <label for="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700 text-sm">Data</label>
                    {dataHeaders &&
                        (
                            dataHeaders.map((header) => {
                                return (
                                    <div className="form-group form-check mb-1">
                                        <input type="checkbox"
                                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm 
                                                    bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none 
                                                    transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain 
                                                    float-left mr-2 cursor-pointer"
                                                id={header}
                                                name='data'
                                                defaultChecked={true}
                                                {...register(header)}
                                                />
                                        <label className="form-check-label inline-block text-gray-800 text-sm" for="exampleCheck1">{header}</label>
                                    </div>
                                )
                            })
                        )
                    }
                    <label for="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700 text-sm">Doc. Size</label>
                    <div class="flex justify-center mb-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border 
                                        border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 
                                        focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center 
                                        bg-contain float-left mr-2 cursor-pointer" 
                                    type="radio" 
                                    name="size" 
                                    id="A4" 
                                    value="A4" 
                                    defaultChecked={true}
                                    onChange={changeHandler}/>
                                <label class="form-check-label inline-block text-gray-800" for="inlineRadio10">A4</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border 
                                        border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 
                                        focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center 
                                        bg-contain float-left mr-2 cursor-pointer" type="radio" 
                                    name="size"    
                                    id="A3"
                                    value="A3" 
                                    onChange={changeHandler} />
                                <label class="form-check-label inline-block text-gray-800" for="inlineRadio20">A3</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border 
                                        border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 
                                        focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center 
                                        bg-contain float-left mr-2 cursor-pointer" type="radio" 
                                    name="size"    
                                    id="A2"
                                    value="A2" 
                                    onChange={changeHandler}
                                    />
                                <label class="form-check-label inline-block text-gray-800" for="inlineRadio20">A2</label>
                        </div>
                    </div>
                    <button type="submit" 
                            className="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight 
                                        uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg 
                                        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 
                                        active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" >Download</button>
                </form>
            </div>
        </div>
    )
}
