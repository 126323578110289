import React from 'react';

const Faq = (props) => {
    return(
        <>
            <div className='w-full grid-rows-3 text-center md:py-16 py-8'>
                <div>
                    <p className='text-5xl md:mb-24 mb-6 text-gray-800'>FAQ</p>
                </div>
                <div>
                    <div className="accordion mb-6" id="accordionExample">
                        <div className="accordion-item bg-white border border-gray-200">
                            <h2 className="accordion-header mb-0" id="headingOne">
                            <button className="accordion-button relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne">
                                <p className='font-bold'>{props.faqTitle1}</p> 
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body py-4 px-5 visible text-left text-gray-700">
                                <p>
                                    {props.faqText1}
                                </p>
                            </div>
                            </div>
                        </div>
                        {props.isTwoItems ?
                        <div className="accordion-item bg-white border border-gray-200">
                            <h2 className="accordion-header mb-0" id="headingTwo">
                            <button className="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                            aria-controls="collapseTwo">
                                 <p className='font-bold'>{props.faqTitle2}</p>
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body py-4 px-5 visible text-left text-gray-700">
                            {props.faqText2}
                            </div>
                            </div>
                        </div>
                        : ""}
                        {props.isThreeItems ?
                        <div className="accordion-item bg-white border border-gray-200">
                            <h2 className="accordion-header mb-0" id="headingThree">
                            <button className="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                                 <p className='font-bold'>{props.faqTitle3}</p>
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body py-4 px-5 visible text-left text-gray-700">
                            {props.faqText3}
                            </div>
                            </div>
                        </div>
                        : ""}
                    </div>
                </div>
            </div>
        </>
    )
}


export default Faq;