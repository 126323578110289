import React from 'react'
import { ContainerWithFlat } from '../components/ContainerLayout/ContainerWithFlat'
import image from '../assets/images/currency-exchange-euro.png'
export const ForexSwapWork = () => {
  return (
    <ContainerWithFlat bgColor={'bg-white'} >
        <div className='grid md:grid-cols-2 -md:grid-rows-2 py-24'>
            <div className='pr-10' >
                <h3 className='text-3xl mb-10' >How Does a Forex Swap Work?</h3>
                <p>
                When traders enter into an agreement to buy or sell a currency, they undertake 
                to make the final payments on the "value date". In the spot market, clearing 
                takes place within two business days after the transaction. If the position remains 
                open and is carried over to the next day, it means that the value date is moved one day ahead.<br/><br/>
                The corresponding currencies in trading are borrowed and loaned from the interbank market 
                at current loan interest and deposit rates. Thus, the profits from lending and the cost of 
                borrowing are transferred to the trader by his broker. Either the position is automatically reopened 
                at a new value date adjusted according to the swap rate and a new price, or the position remains at 
                the previous price while the swap is credited or debited to the trader's account.
                </p>
            </div>
            <div className='flex md:justify-end' >
                <img src={image} />
            </div>
        </div>
    </ContainerWithFlat>
  )
}
