import React from 'react';
import bgImage from '../assets/images/banners/partner-second-banner.png'

const PartnerAffiliatesBanner = () => {
    return(
        <>
        <div className="container-full xl:h-auto h-[80vh]">
            <div className="mx-auto absolute xl:text-left text-center">
                <div className='grid grid-rows-2'>
                    <div className='xl:ml-[55%] xl:py-0 pt-16'>
                        <p className='font-bold text-3xl'>Affiliates</p>
                        <p className='xl:w-4/5 py-4 px-8'>
                            As part of the MonsTrade Affiliate Programme, your website will direct traders 
                            to MonsTrade trading platform, earning you an attractive revenue share with 
                            a flat commission fee when they open a trading account and become active traders. 
                            Our program suits a variety of online professionals, including bloggers, digital 
                            marketers, email marketers, mobile marketers, media buyers, social media 
                            commentators and website publishers.
                        </p>
                        <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">
                        <button class="bg-monstrade-blue hover:text-white hover:bg-monstrade-green text-white font-bold py-2 px-6 rounded">
                            Join Now
                        </button>
                        </a>
                    </div>
                    <div className='xl:ml-[20%] xl:mt-[7%] xl:py-0 py-16'>
                        <p className='font-bold text-3xl'>Introducing Brokers</p>
                        <p className='xl:w-2/5 py-4 px-8'>
                            The MonsTrade Introducing Broker (IB) program is created to incentivise introducers 
                            for referred clients. A highly competitive, volume-based rebate structure, combined 
                            with low entry barriers, makes MonsTrade the ideal partner for IBs of all sizes 
                            and levels of experience.
                        </p>
                        <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">
                        <button class="bg-monstrade-blue hover:text-white hover:bg-monstrade-green text-white font-bold py-2 px-6 rounded">
                            Join Now
                        </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className='xl:block hidden'>
                <img src={bgImage} alt="" />
            </div>

        </div>
            
        </>
    )
}

export default PartnerAffiliatesBanner;