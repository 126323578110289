import React from 'react'

export const PublicHolidayHours = () => {
    const table1 = {
        header : ["Holiday", "2023", "2024" ],
        content : [
            ["New Years Day", "Monday, January 2 (New Year’s holiday observed)", "Monday, January 1"],
            ["Martin Luther King, Jr. Day", "Monday, January 16", "Monday, January 15" ],
            ["Washington’s Birthday", "Monday, February 20", "Monday, February 19" ],
            ["Good Friday", "Friday, April 7", "Friday, March 29" ],
            ["Memorial Day", "Monday, May 29", "Monday, May 27" ],
            ["Juneteenth National Independence Day", "Monday June 19", "Wednesday, June 19" ],
            ["Independence Day", "Tuesday, July 4**", "Thursday, July 4**" ],
            ["Labor Day", "Monday September 4", "Monday, September 2" ],
            ["Thanksgiving Day", "Thursday, November 23***", "Thursday, November 28***" ],
            ["Chirstmas Day", "Monday November 25", "Wednesday, December 25****" ],
        ]
    }

    return (
        <div>
            <div className='my-16'>
                <h2 className='text-center mb-10 text-4xl font-bold' >Public Holiday Trading Hours !</h2>
                <p>
                    Forex trading hours are impacted by public holidays and other events.
                    The alternate trading hours vary across global financial markets due
                    to the fact that some countries have additional public holidays. In
                    Australia, this includes events such as Australia Day, ANZAC Day, Good
                    Friday, Easter Monday and Boxing Day.<br /><br />
                    Trading of specific instruments across <span className='font-bold' >Forex</span> and <span className='font-bold' >CFDs</span> are impacted as a
                    result. Traders should be aware that trading day hours may vary on MetaTrader
                    5. Please check the tables below which will be regularly updated in advance
                    of any upcoming holidays.
                </p>
            </div>
            <div className='mb-16' >
                <div className='text-center mb-6' >
                    <h3 className='text-2xl font-semibold' >Holiday Hours</h3>
                    <p className='text-xl text-gray-500' >(GMT +2)</p>
                </div>
                <table className='table-fixed w-full text-center' >
                    <thead className='h-[65px] text-xl ' style={{background: 'linear-gradient(270deg, #00C7FF -4.6%, #0052FF 101.41%)'}}>
                        { table1.header.map((item) => { return <th className='text-white' >{item}</th>})}
                    </thead>
                    <tbody className='font-semibold' >
                        {table1.content.map( (line, index) => {
                            return(
                                <tr className={`h-[65px] ${index % 2 === 0 ? '' : 'bg-mons-gray-1'}`} >
                                    {line.map((item) => { return <td>{item}</td>})}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
