import React from 'react'
import Banner from '../../assets/images/banners/first-trade-banner.png'

const FirstTrade = () => {
  return (
    <>
      <div className='bg-monstrade-blue'>
        <div className='grid lg:grid-cols-2 max-w-[1440px] mx-auto h-[524px] items-center'>
          <div className='text-white mx-2'>
            <p className='text-xl font-semibold'>| MONSTRADE PRIVILEGES</p>
            <p className='text-5xl font-semibold py-28'>First Trade</p>
            <p className='text-sm max-w-[460px]'>Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.</p>
          </div>
          <div>
            <img src={Banner} alt="" />
          </div>
        </div>
      </div>
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='max-w-[1440px] mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            In the context of this application, if the investor experiences a loss in their MonsTrade account on the first trade after making an investment, the amount of this loss is refunded.
            <br/><br/>
            The loss incurred after the first trade within the scope of the application is covered by MonsTrade within the rules, assisting the investor's trading success.
            <br/><br/>
            The loss amount incurred in the first trade within the First Trade application is covered by MonsTrade within certain limits, not exceeding 10% of the investment amount; up to 150 USD for the Cent category, 200 USD for the Classic category, 300 USD for the Prime category, and 500 USD for the ECN category.
            <br/><br/>
            If there is a SWAP in the investor's trades, meaning the trades are carried over to the next day, the First Trade refund is not applied.
            <br/><br/>
            In case of the detection of misuse of the application, the loss will not be covered.
            <br/><br/>
            General rules of the MonsCredit application apply, and it cannot be used as withdrawable capital in withdrawals.
            <br/><br/>
            The First Trade privilege is defined if the investor requests it until the end of the trading day, which is 23:59 on the day the investment is made.
            <br/><br/>
            In case of any misuse, Monstrade reserves the right to ban the investor from the application.
          </p>
        </div>
      </div>
    </>
  )
}

export default FirstTrade