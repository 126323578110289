import React from 'react'
//component
import { BannerContainer } from '../components/Containers/BannerContainer'
import { CompetitiveForexSwapRates } from '../containers/CompetitiveForexSwapRates';
import { AboutForexSwapRates } from '../containers/AboutForexSwapRates';
import { WhatAreForexSwapRates } from '../containers/WhatAreForexSwapRates';
import { ForexSwapWork } from '../containers/ForexSwapWork';
import { CalculationOfSwapRates } from '../containers/CalculationOfSwapRates';
import { SwapCharges } from '../containers/SwapCharges';
import { SwapRatesInMT5 } from '../containers/SwapRatesInMT5';
import { StartTrading } from '../containers/StartTrading';
import { SwapPoints } from '../containers/SwapPoints';
import PricingMenu from '../components/PricingMenu';
//images
import bgImage from '../assets/images/banners/forex-swap-rates-banner.png';
import ratesBanner from '../assets/images/banners/what-are-swap-rates-banner.png'


export const SwapRates = () => {

    const landingOptions = {
        image : bgImage,
        container : {
          width : 'w-3/4',
          padding : 'py-20'
        },
        header: {
          text : 'Forex Swap Rates',
          size: 'text-3xl'
        },
        subHeader: {
          text : "Monstrade understands that competitive swap rates are important to any trader."},
        button: {
            bgcolor: "bg-white",
            textcolor: "text-monstrade-blue",
            text: "Start Trading",
            padding: "pt-4"
        }
      }

    return (
        <>
            <BannerContainer options={landingOptions}/>
            <PricingMenu isActive="swaprates"/>
            <CompetitiveForexSwapRates />
            <AboutForexSwapRates />
            {/* <WhatAreForexSwapRates /> */}
            <div className='sm:block hidden'>
                <BannerContainer options={{image:ratesBanner,container:{textAlign:"text-left"}}}>
                <div className='m-auto' >
                    <div className='text-white text-center mb-10'>
                        <h3 className='text-4xl' >What Are Forex Swap Rates?</h3>
                    </div>
                    <div className='grid md:grid-cols-2 text-white'>
                        <div className='md:pr-16'>
                            <p>
                                Swap fees on Forex or renewal interest rates are the net interest yield a trader accumulates on a currency position held overnight. This fee is charged when the trader borrows money to purchase another currency as part of forex trading.
                                <br/><br/>
                                For example, if you are buying EUR/USD, you can borrow in US Dollars and buy Euros with that amount. In doing so, you will need to pay interest on the US Dollars you borrow and earn interest on the Euros you buy.
                                <br/><br/>
                                The net interest fee is calculated based on the difference in the interest rates of the two traded currencies. If the Forex swap rate calculation is positive, the trader wins, if it is negative, it is a cost to the trader.
                            </p>
                        </div>
                        <div className='md:pl-16' >
                            <p className='mb-6' >
                                Normally, the deposit and loan rates are different in the same currency. 
                                The loan rate is usually higher than the deposit rate. This is why forex 
                                swap rates differ for long and short positions in the same currency pair. 
                                The "storage" for holding a position overnight depends on several factors such as:
                            </p>
                            <ul className='list-disc' >
                                <li className='mb-3' >The current interest rate differential between two currencies</li>
                                <li className='mb-3'>Currency pair price fluctuations</li>
                                <li className='mb-3'>Behaviour of the forward market</li>
                                <li className='mb-3'>Swap points of the counterparty</li>
                                <li className='mb-3'>Position of the liquidity provider in the market hierarchy</li>
                                <li className='mb-3'>Difference between forex swaps for long and short positions</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </BannerContainer>
            </div>
            <div className='sm:block hidden'>
                <ForexSwapWork />
                <CalculationOfSwapRates />
                <BannerContainer options={{image:ratesBanner,container:{textAlign:"text-left"}}}>
                <div className='m-auto' >
                    <div className='text-white text-center mb-10'>
                        <h3 className='text-4xl' >Importance of Swap Charges in Forex</h3>
                    </div>
                    <div className='grid md:grid-cols-2 text-white'>
                        <div className='md:pr-16'>
                            <p className='text-[20px] leading-5 font-light' >
                                Swap calculations are made for positions that remain open after 17:00pm ET at the end of the day. This is important for those who want to stay in long-term positions for a substantial period of time. Traders who use strategies that focus not only on intraday price fluctuations but also trend trading based on fundamental market changes need to carefully consider swap rates.
                                <br/><br/>
                                Additionally, forex swap fees are vital for traders using carry trading strategies. These strategies are based entirely on the interest rate difference between the two currencies. The lower yielding currency becomes the funding currency (borrowed currency) and the borrowed amount is used to purchase a higher yielding currency.
                            </p>
                        </div>
                        <div className='md:pl-16' >
                            <p className='text-[20px] leading-5 font-light' >
                                For example, the Swiss franc (CHF) has a negative spread with almost any currency, indicating that a trader trading with "swiss" can expect negative swap rates. On the other hand, if someone enters a short position, they can expect a positive trade-off when the positions are held overnight. Conversely, trading in GBP can result in positive swap rates against the euro, yen and Switzerland, while negative swaps with the US, Canadian and Australian dollars can be expected.
                                <br/><br/>
                                Forex swaps are also important for hedging purposes. If a trader opens a position in anticipation of a certain market movement that has not yet started, he may consider opening another position in the opposite direction without closing the first one. This is often referred to as "lock mode protection". The low variance between the rates provided by interbank clearing can help minimize the cost of holding such positions.
                            </p>
                        </div>
                    </div>
                </div>
                </BannerContainer>
            </div>
            <SwapRatesInMT5 />
            <SwapPoints />
            <StartTrading />
        </>
    )
}
