import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'
//images
import bgImage from '../assets/images/banners/trading-hours-banner.png'
import { ContainerWithFlat } from '../components/ContainerLayout/ContainerWithFlat'
import { PublicHolidayHours } from '../containers/PublicHolidayHours'
import { StartTrading } from '../containers/StartTrading'
import PricingMenu from '../components/PricingMenu';

export const HolidayTradingHours = () => {

      const landingOptions = {
        image : bgImage,
        container : {
          width : 'w-4/5',
          padding : 'py-20'
        },
        header: {
          text : 'Holiday Trading Hours',
          size: 'text-3xl'
        },
        subHeader: {
          text : "Please be aware of special trading hours during public holiday periods."},
        button: {
            bgcolor: "bg-white",
            textcolor: "text-monstrade-blue",
            text: "Start Trading",
            padding: "pt-4"
        }
      }
  return (
    <div>
        <BannerContainer options={landingOptions}/>
        <PricingMenu isActive="holidaytradinghours"/>
        <ContainerWithFlat>
            <PublicHolidayHours />
        </ContainerWithFlat>
        <StartTrading />
    </div>
  )
}
