import React from "react";
import CashbackImg from "../assets/images/mons-blue-bg.png"
import ZeroCommisionIco from "../assets/icons/zero_commision.svg"
import HandMoneyIco from "../assets/icons/hand_money.svg"
import CardIco from "../assets/icons/card_ico.svg"
import PercentIco from "../assets/icons/circle_percent.svg"
import ZeroCommissionContent from "../components/PromotionComps/ZeroCommissionContent";
import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";

const landingOptions = {
    image: CashbackImg,
    mainZindex: 'z-[-1]',
    header: {
        text: 'Zero Commission',
        size: 'text-[54px]',
    },
    subHeader: {
        text: "Trade on MonsTrade without paying any commission",
        align: "text-center",
        size: 'text-[24px]',
    }
}
const cards = [
    { header: 'Zero Fees', description: 'Zero fees crypto wallet deposits and withdrawals', icon: ZeroCommisionIco, link: '' },
    { header: 'Zero Commission', description: 'Zero fees on deposits / withdrawals made by wire transfer oor currency equivalent', icon: HandMoneyIco, link: '' },
    { header: 'Instant Funding', description: 'Instant account funding via all depozit wallets', icon: CardIco, link: '' },
    { header: 'No Comission', description: 'No hidden commissions', icon: PercentIco, link: '' },
]

const ZeroCommission = () => {
    return (
        <>
            {/* <TopBanner firstIcon={ZeroCommisionIco} secondIcon={HandMoneyIco} thirdIcon={CardIco} forthIcon={PercentIco} mainTopic={mainTopic} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={CashbackImg} is4Columns={true} />  */}
            <BannerContainer options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-2'} />
                </div>
            </BannerContainer>
            <ZeroCommissionContent />
        </>
    )
}

export default ZeroCommission;