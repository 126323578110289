import React, {useState} from 'react';

export const RegisterForm = () => {
    const [formInput, setFormInput] = useState({
        firstName : { 
                placeholder: "First Name", 
                value : "",
                type : "text"},
        lastName : { 
                placeholder: "Last Name", 
                value : "" ,
                type : "text"},
        email : {
            placeholder: "Email", 
            value : "",
            type : "email" },
        phone : { 
            placeholder: "+90", 
            value : "",
            type : "phone" }
    })

    const handleInputChange = (e) => {

        setFormInput((prevState) => (
            {
                ...prevState,
                [e.target.name] :{
                    ...prevState[e.target.name],
                    value : e.target.value
                }
            }
        ))
    }

    const handleSubmit = (e) => {
        console.log(formInput);
        e.preventDefault();
    }

  return (
        <form onSubmit={handleSubmit} >
                { Object.keys(formInput).map( (inputState) => {
                    return (
                        <div className='mb-6' key={inputState}>
                            <input name={inputState}
                                    className='border-2 bg-transparent px-3 py-3 w-full' 
                                    placeholder={formInput[`${inputState}`].placeholder} 
                                    value={formInput[`${inputState}`].value}
                                    type={formInput[`${inputState}`].type}
                                    onChange={ handleInputChange } />
                                    
                        </div>
                    )
                })}
            <div className='pt-8'>
                <button type='submit'
                    className='bg-monstrade-green text-center w-full py-4 text-xl font-semibold text-white rounded-lg' > 
                    Open Account
                </button>
            </div>
        </form>
  )
}
