import React from "react";

const BrokerBonusContent = () => {
    return(
        <>
            <div className="p-8 text-gray-700">
                <p className="font-semibold text-black text-2xl">To get the Bonus</p>
                <br/>
                <p>All  and Fixed USD  accounts in the  MT5 platforms can participate in the  program.</p>
                <br/>
                <p className="font-semibold text-black text-2xl">Terms and conditions:</p>
                <p>
                    Maximum amount of bonus funds per client — $1000;
                    <br/><br/>
                    Bonus is paid in the form of non-withdrawable credits and displayed in Credit line of the MetaTrader 5 platform. Bonus funds are not added to the real funds of your account balance;
                    <br/><br/>
                    This offer is not cumulative with any other MonsTrade’ bonuses and promotions;
                    <br/><br/>
                    Bonus funds will not for withdrawal 
                    <br/><br/>
                    Only closed trades are counted in the turnover.
                    <br/><br/>
                    Only new trades are taken into account in calculations of the turnover. Trading account history doesn’t affect turnover calculations;
                    <br/><br/>
                    The client will have to calculate «Stop out» level himself as the MetaTrader 5 platform takes into account only real assets with no regard to bonus funds, that can’t be used during the drawdown. Therefore, you can reach your «Stop out» level sooner, than indicated in MetaTrader 5;
                    <br/><br/>
                    Bonus for the reporting period will be cancelled, if a client fully or partially withdraws his initial deposit, the above-mentioned bonus has been calculated from;
                    <br/><br/>
                    If fraudulent activity with credit funds is detected, the Company reserves the right to block the client’s trading account and write off all bonus payments from the trading account without prior notification and explanation.
                    <br/><br/>
                    The offer is valid for all accounts in MetaTrader 5.
                    <br/><br/>
                    Please, pay attention that your bonus is valid for 30 days. If your trading account has shown no activity during this period, the bonus will be cancelled.
                </p>
            </div>
        </>
    )
}

export default BrokerBonusContent;