

export const spreadTableContent = () => {
    return (
        [
            {
                name: 'Forex Spreads',
                meta: [
                    { column: 'Symbol', subColumn: [] },
                    { column: 'Product', subColumn: [] },
                    {
                        column: 'Standart A/c', subColumn: [
                            { column: 'Avg', subColumn : [] }
                        ]
                    },
                    {
                        column: 'Raw ECN A/c', subColumn: [
                            { column: 'Min', subColumn : [] },
                            { column: 'Avg', subColumn : [] },
                        ]
                    },
                ],
                data: [
                    ['EURUSD', 'EURO / U.S. DOLLAR', 1.8, 0, 0.6],
                    ['GBPUSD', 'BRITISH POUND / U.S. DOLLAR', 1.8, 0, 0.6],
                    ['USDJPY', 'U.S. DOLLAR / JAPANESE YEN', 1.8, 0, 0.6],
                    ['AUDUSD', 'AUSTRALIAN DOLLAR / U.S. DOLLAR', 1.8, 0, 0.6],
                    ['USDCAD', 'U.S. DOLLAR / CANADIAN DOLLAR', 1.8, 0, 0.6],
                    ['USDCHF', 'U.S. DOLLAR / SWISS FRANC', 1.8, 0, 0.6],
                    ['NZDUSD', 'NEW ZEALAND DOLLAR / U.S. DOLLAR', 1.8, 0, 0.6],
                ]
            },
            {
                name: 'Metal Spreads',
                meta: [
                    { column: 'Symbol', subColumn: [] },
                    { column: 'Product', subColumn: [] },
                    {
                        column: 'Standart A/c', subColumn: [
                            { column: 'Avg', subColumn : [] }
                        ]
                    },
                    {
                        column: 'Raw ECN A/c', subColumn: [
                            { column: 'Min', subColumn : [] },
                            { column: 'Avg', subColumn : [] },
                        ]
                    },
                ],
                data: [
                    ['XAUUSD', 'GOLD  / U.S. DOLLAR', 0.4, 0, 0.3],
                    ['XAUEUR', 'GOLD / EURO', 0.4, 0, 0.2],
                    ['XAGUSD', 'SILVER  / U.S. DOLLAR', 0.4, 0, 0.2]
                ]
            },
            {
                name: 'CFD Indices Spreads',
                meta: [
                    { column: 'Symbol', subColumn: [] },
                    { column: 'Product', subColumn: [] },
                    {
                        column: 'Standart A/c', subColumn: [
                            { column: 'Min', subColumn : [] },
                            { column: 'Avg', subColumn : [] },
                        ]
                    },
                ],
                data: [
                    ['NASDAQ100', 'US 100 CFD', 0.3, 2.05],
                    ['DAX40', 'GERMAN 40 CFD', 4.6, 9.23],
                    ['DOW30', 'US 30 CFD', 1.4, 2.3]
                ]
            },
            {
                name: 'Cryptocurrency Spreads',
                meta: [
                    { column: 'Symbol', subColumn: [] },
                    { column: 'Product', subColumn: [] },
                    {
                        column: 'Standart A/c', subColumn: [
                            { column: 'Min', subColumn : [] },
                            { column: 'Avg', subColumn : [] },
                        ]
                    },
                    {
                        column: 'Raw ECN A/c', subColumn: [
                            { column: 'Min', subColumn : [] },
                            { column: 'Avg', subColumn : [] },
                        ]
                    },
                ],
                data: [
                    ['ADAUSD', 'CARDANO  / U.S. DOLLAR', 3.00, 2.05, 2.00, 0.193],
                    ['ETHUSD', 'ETHEREUM  / U.S. DOLLAR', 2.25, 9.23, 2.00,1.0716],
                    ['BTCUSD', 'BITCOIN  / U.S. DOLLAR', 3.0, 2.3, 2.01,1.5426]
                ]
            }
        ]
    )
}