import React from 'react'
import bgImage from '../assets/images/about-us-world-image.png'
import { BannerContainer } from '../components/Containers/BannerContainer'


export const MissionOnAboutUs = () => {
    return (
        <>
        <div className='container-full bg-[#00030a] h-[50vw] xl:block hidden' >
            <div className='container-max mx-auto relative h-full flex flex-col'>
                <div className='absolute m-auto h-[40vw] flex z-[0]' >
                    <img className='m-auto' src={bgImage} alt="" />
                </div>
                <div className='mt-10 z-40'>
                    <p className='text-white text-lg text-center mx-auto' >
                        Over the years of the Company’s existence, the letter A has acquired
                        several different meanings, which now shape our mission and philosophy:
                    </p>
                </div>
                <div className='text-white z-40 relative'>
                    <div className='max-w-[240px] absolute text-right left-[4vw]' >
                        <h3 className='text-xl font-bold mb-1' >Experience</h3>
                        <p>
                            Leadership and perfection in our quest for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-right top-[13vw]' >
                        <h3 className='text-xl font-bold mb-1' >Sustainable</h3>
                        <p>
                            Excellent service and sustainable investment
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-right top-[29.5vw] left-[12vw]' >
                        <h3 className='text-xl font-bold mb-1' >Responsible</h3>
                        <p>
                            Investor earnings
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-left right-[4vw]' >
                        <h3 className='text-xl font-bold mb-1' >Leader</h3>
                        <p>
                            A symbol of leadership and perfection in our quest for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-left top-[13vw] right-0' >
                        <h3 className='text-xl font-bold mb-1' >Success</h3>
                        <p>
                            A successful and satisfying experience
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-left top-[29vw] right-[5vw]' >
                        <h3 className='text-xl font-bold mb-1' >Partnership</h3>
                        <p>
                            Partnership with our customers
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className='hidden'>
            <BannerContainer options={{image:bgImage}} >
                <div className='mt-10 z-40'>
                    <p className='text-white text-lg text-center mx-auto pb-10' >
                        Over the years of the Company’s existence, the letter A has acquired
                        several different meanings, which now shape our mission and philosophy:
                    </p>
                </div>
                <div className='grid md:grid-cols-2 gap-5 text-white'>
                    <div>
                        <h3 className='text-xl font-bold mb-1' >Experience</h3>
                        <p>
                            Leadership and perfection in our quest for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold mb-1' >Sustainable</h3>
                        <p>
                            Excellent service and sustainable investment
                        </p>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold mb-1' >Responsible</h3>
                        <p>
                            Investor earnings
                        </p>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold mb-1' >Leader</h3>
                        <p>
                            A symbol of leadership and perfection in our quest for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold mb-1' >Success</h3>
                        <p>
                            A successful and satisfying experience
                        </p>
                    </div>
                    <div>
                        <h3 className='text-xl font-bold mb-1' >Partnership</h3>
                        <p>
                            Partnership with our customers
                        </p>
                    </div>
                </div>
            </BannerContainer>
        </div>
        </>
    )
}
