import React from "react";
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import { ReactComponent as CompanyIcon } from '../assets/images/android-chrome-512x512.svg'
const NavigationForPhonesItem = (props) => {
    const path = window.location.pathname;
    const [isShown, setIsShown] = useState(false);

    return (
        <>
            <div className="py-3" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                    <a href={props.icon[1] !== 'whatsapp' ? props.href : 'https://api.whatsapp.com/send/?phone=447450125893&type=phone_number&app_absent=0'}>
                        <div className="mx-auto ">
                            <FontAwesomeIcon icon={props.icon} size="2xl" className={`${props.iconColor}`} />
                        </div>
                        <p className={`inline-block ${props.iconColor}`}>{props.name}</p>
                    </a>
            </div>
        </>
    )
}

export default NavigationForPhonesItem;