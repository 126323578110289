import React from "react";
import tradepc from '../assets/images/trade-pc-mockup.png'
import trademobile from '../assets/images/trade-mobile-mockup.png'

const content = [
    { header: "MT5 FOR PC", image: require('../assets/images/platform-logo/windows.png') },
    { header: "MT5 FOR MAC", image: require('../assets/images/platform-logo/mac.png') },
    { header: "MT5 WEBTRADER", image: require('../assets/images/platform-logo/web.png') },
    { header: "MT5 FOR IPHONE", image: require('../assets/images/platform-logo/iphone.png') },
    { header: "MT5 FOR ANDROID", image: require('../assets/images/platform-logo/android.png') },
    { header: "MT5 FOR IPAD", image: require('../assets/images/platform-logo/tablet.png') },
]

const RangeOfPlatform = () => {
    return (
        <>
            <div className="container-full">
                <div className="container-max flex m-auto pt-12">
                    <div className="grid-rows-5">
                        <div className="text-center p-5">
                            <p className="md:text-3xl text-2xl md:font-bold font-bold" >Browse the Full Range of Platforms</p>
                        </div>
                        <div className="text-center md:px-5 px-3">
                            <p className="text-lg" >
                                At Monstrade we completely understand our clients needs. 
                                Hence, we offer for our clients a wide selection of trusted, 
                                award-winning platforms and account types.
                            </p>
                        </div>
                        <div className="lg:columns-6 columns-3 justify-center py-12">
                            {content.map((item, index) => {
                                return (
                                        <div key={index} >
                                            <div className="rows-2 py-4">
                                                <div className="flex pb-3 ">
                                                    <img className="m-auto" src={item.image} alt="" />
                                                </div>
                                                <div className="text-center" >
                                                    <p>{item.header}</p>
                                                </div>
                                            </div>
                                        </div>
                                )
                            })}
                        </div>
                        <div className="flex w-fit m-auto relative py-5" > 
                            <div className="m-auto" >
                                <img src={tradepc} alt="" />
                            </div>
                            <div className="absolute bottom-0 sm:-left-10" >
                                <img className="max-h-[50vw]" src={trademobile} alt="" />
                            </div>
                        </div>
                        <div className="flex" >
                            <div className="py-10 m-auto ">
                                <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard" className="bg-monstrade-blue hover:text-white hover:bg-monstrade-green rounded-lg py-2" >
                                    <span className="text-white py-3 px-4 font-semibold " >Start Trading</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RangeOfPlatform;