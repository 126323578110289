import React,{useState} from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import ForexP from '../../assets/images/forex-phone.png'
import HisseP from '../../assets/images/hisse-phone.png'
import CommoP from '../../assets/images/commodit-phone.png'
import CryptoP from '../../assets/images/crypto-phone.png'
import ExoticP from '../../assets/images/exotic-phone.png'
import EndexP from '../../assets/images/exdex-phone.png'

import ForexIco from '../../assets/icons/forexv2.svg'
import IndicesIco from '../../assets/icons/indicesv2.svg'
import CommoIco from '../../assets/icons/commov2.svg'
import StocksIco from '../../assets/icons/stocksv2.svg'
import CryptoIco from '../../assets/icons/cryptov2.svg'
import ExoticIco from '../../assets/icons/exoticv2.svg'

import BlueArrow from '../../assets/icons/blue-right.svg'

 
export function DropdownV2() {

    const [isHover, setIsHover] = useState(ForexP)
    const [constImage, setConstImage] = useState(ForexP)

    const Data = [
        {
            id: 1,
            icon: ForexIco,
            phone: ForexP,
            title: "Forex",
            desc: "50+ currency pairs",
            content: [
                "Competetive Leverage",
                "0.0 pips typical spread on Majors"
            ]
        },
        {
            id: 2,
            icon: CommoIco,
            phone: CommoP,
            title: "Commodities",
            desc: "15+ metals and energies",
            content: [
                "Competetive Leverage",
                "0.0 pips typical spread on Majors"
            ]
        },
        {
            id: 3,
            icon: CryptoIco,
            phone: CryptoP,
            title: "Cryptocurrencies",
            desc: "10+ cryptocurrencies",
            content: [
                "Competetive Leverage",
                "0.0 pips typical spread on Majors"
            ]
        },
        {
            id: 4,
            icon: IndicesIco,
            phone: EndexP,
            title: "Indices",
            desc: "15+ indices",
            content: [
                "Competetive Leverage",
                "0.0 pips typical spread on Majors"
            ]
        },
        {
            id: 5,
            icon: StocksIco,
            phone: HisseP,
            title: "Stocks & ETF’s",
            desc: "200+ stocks & ETF’s",
            content: [
                "Competetive Leverage",
                "0.0 pips typical spread on Majors"
            ]
        },
        {
            id: 6,
            icon: ExoticIco,
            phone: ExoticP,
            title: "Forex Exotic",
            desc: "30+ indices",
            content: [
                "Competetive Leverage",
                "0.0 pips typical spread on Majors"
            ]
        },
]

function Icon({ id, open }) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
    }

  const [open, setOpen] = React.useState(0);
 
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
 
  return (
    <>
    <div>
        <div className="grid lg:grid-cols-3 items-center py-20">
            <div>
                <img className="mx-auto sm:pb-0 pb-6" src={isHover} alt="" />
            </div>
            <div className="lg:col-span-2 md:mx-auto mx-4">
                <div className="p-2 bg-gray-100 max-w-[400px] text-center">
                    <p className="text-monstrade-blue font-bold">ALL ASSET TYPES ON MONSTRADE</p>
                </div>
                <p className="text-4xl py-4"><span className="font-bold">Financial Asset</span> Types of MonsTrade</p>
                <div className="grid lg:grid-cols-2 mx-auto gap-2">
                    
                        {Data.map((d)=> {

                            return(
                            <div onClick={()=>setConstImage(d.phone)} onMouseEnter={() => setIsHover(d.phone)} onMouseLeave={() => setIsHover(constImage)}>
                                <Accordion open={open === d.id} icon={<Icon id={ d.id} open={open}></Icon>} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                                    <AccordionHeader
                                    onClick={() => handleOpen( d.id)}
                                    className={`border-b-0 transition-colors ${
                                        open ===  d.id ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                                    >
                                    <div className="flex gap-4 items-center">
                                        <div>
                                            <img src={d.icon} alt="" />
                                        </div>
                                        <div>
                                            <p className="font-semibold">{d.title}</p>
                                            <p className="font-normal">{d.desc}</p>
                                        </div>
                                    </div>
                                    </AccordionHeader>
                                    <AccordionBody className="pt-0 text-base font-normal">
                                        {d.content.map((c)=>{
                                        return(
                                            <div className="flex gap-2">
                                                <img src={BlueArrow} alt="" />
                                                <p>{c}</p>
                                            </div>
                                        )
                                        })}
                                    </AccordionBody>
                                </Accordion>
                            </div>
                            )
                        })}
                    
                </div>
                <p className="font-light pt-4">Some assets may be priced differently than those specified here.</p>
            </div>
        </div>
    </div>
      
    </>
  );
}