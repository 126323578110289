import React from 'react'
import { useState, useEffect } from 'react';
import DoubleDot from '../../assets/images/double-dot.png'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const XMasBannerContainer = ({ options, children }) => {


    const targetDate = new Date('January 1, 2024 00:00:00 GMT+00:00');
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [targetDate]);

    function calculateTimeLeft(targetDate) {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            // If the target date has passed, set timeLeft to 0
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    }


    const { height, width } = useWindowDimensions();
    let container = options.container ? options.container : {};
    return (
        <div className={`container-full relative bg-no-repeat bg-cover ${options.mainZindex ? options.mainZindex : 'z-10'}`} style={{ backgroundImage: `url("${width > 768 || !options.mobileImage ? options.image : options.mobileImage}")` }}>
            {options.imageGradient && <div className='absolute h-full w-full z-20' style={{ background: `${options.imageGradient}` }}></div>}
            <div className='container-max py-12 m-auto flex'>
                <div className=
                    {`z-30 ${container.textAlign ? container.textAlign : 'text-center'}
                    ${container.width ? container.width : 'w-full'}
                    ${container.margin ? container.margin : 'm-auto'}
                    ${container.padding ? container.padding : 'padding-0'}`} >
                    <div className={`${container.marginY ? options.container.marginY : ''}`} >

                    <p className='text-white text-center font-bold lg:mr-24'>Remaining Time for the Draw</p>
                    <div className='grid lg:grid-cols-11 grid-cols-2 items-center py-6 ml-0 lg:mr-24'>
                <div className='lg:col-span-2'>
                    <div className='countdown-div h-[84px] max-w-[115px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[66px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.days}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-white'>DAYS</p></div>
                </div>
                <div className='lg:block hidden w-5 mx-auto'>
                    <img className='mx-auto' src={DoubleDot} alt="" />
                </div>
                <div className='lg:col-span-2'>
                <div className='countdown-div h-[84px] max-w-[125px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[66px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.hours}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-white'>HOURS</p></div>
                </div>
                <div className='lg:block hidden w-5 mx-auto'>
                    <img className='mx-auto'  src={DoubleDot} alt="" />
                </div>  
                <div className='lg:col-span-2'>
                <div className='countdown-div h-[84px] max-w-[125px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[66px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.minutes}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-white'>MINUTES</p></div>
                </div>
                <div className='lg:block hidden w-5 mx-auto'>
                    <img className='mx-auto'  src={DoubleDot} alt="" />
                </div>
                <div className='lg:col-span-2'>
                <div className='countdown-div h-[84px] max-w-[125px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[66px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.seconds}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-white'>SECONDS</p></div>
                </div>
        </div>

                            {options.header2 &&
                            <div className='mt-[-20px] mb-6' >
                                <h1 className=
                                    {`${options.header2.size ? options.header2.size : 'text-2xl'} 
                    ${options.header2.bold ? options.header2.bold : 'font-bold'}
                    ${options.header2.color ? options.header2.color : 'text-white'}
                    ${options.header2.align ? options.header2.align : 'text-center'}`}>{options.header2.text}</h1>
                            </div>}
                        {options.subHeader &&
                            <div className={`${options.subHeader.margin ? options.subHeader.margin : 'mb-6'}`} >
                                <h2 className=
                                    {`${options.subHeader.size ? options.subHeader.size : 'text-normal'} 
                                    ${options.subHeader.bold ? options.subHeader.bold : 'font-normal'} 
                                    ${options.subHeader.color ? options.subHeader.color : 'text-white'}
                                    ${options.subHeader.align ? options.subHeader.align : 'text-center'}`}>{options.subHeader.text}
                                </h2>
                            </div>
                        }
                        {options.button &&
                            <div className={options.button.padding}>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className={`
                                    ${options.button.bgcolor}
                                    ${options.button.textcolor}
                                    ${options.button.bordercolor ? `border-2 ${options.button.bordercolor}` : ""}
                                    ${options.button.hover ? `${options.button.hover}` : 'hover:bg-monstrade-blue'}
                                    ${options.button.textSize ? `${options.button.textSize}` : 'lg:text-2xl md:text-xl '}
                                    hover:text-white
                                    ease-in
                                    duration-150
                                    lg:py-4 lg:px-4 sm:px-2 py-2 px-2
                                    rounded-lg 
                                    font-semibold`}>
                                    {options.button.text}
                                </a>
                            </div>}
                        {options.button2 && <div className={options.button2.padding}>
                            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className={`
                                    ${options.button2.bgcolor}
                                    ${options.button2.textcolor}
                                    ${options.button2.bordercolor ? `border-2 ${options.button2.bordercolor}` : ""}
                                    ${options.button2.textSize ? `${options.button2.textSize}` : 'lg:text-2xl md:text-xl '}
                                    hover:bg-monstrade-blue
                                    hover:text-white
                                    ease-in
                                    duration-150
                                    lg:py-4 lg:px-4 sm:px-2 py-2 px-2
                                    rounded-lg 
                                    font-semibold`}>
                                {options.button2.text}
                            </a>
                        </div>}
                        {children}
                    </div>

                </div>
            </div>
        </div>
    )
}
