import React from 'react';
import SecurityImg from '../assets/images/secure.png'
import Diploma from '../assets/icons/diploma.svg'
import Money from '../assets/icons/money.svg'
import Healthcare from '../assets/icons/health-care.svg'
import Quality from '../assets/icons/quality.svg'

const Security = () => {
    return(
        <>

            <div className='container-full relative lg:h-[57.1vw] h-auto bg-monstrade-blue z-[-2]'>
                <div className='absolute z-[-1] h-full w-full overflow-hidden'>
                    <img className='lg:w-full max-w-none lg:ml-0 -ml-[20%] lg:h-auto h-full' src={SecurityImg} alt="" />
                </div>
                <div>
                    <p className='text-center text-3xl text-white py-16'>Security of Client's Funds</p>
                </div>
                <div className='grid lg:gap-44 md:gap-16 gap-10 lg:grid-cols-2 lg:grid-rows-2 text-white text-center lg:text-lg pb-8'>
                    <div>
                        <img className='w-1/6 mx-auto mb-2' src={Diploma} alt="" />
                        <p className='font-semibold' >Regulated activities</p>
                    </div>
                    <div>
                        <img className='w-1/6 mx-auto mb-2' src={Money} alt="" />
                        <p className='font-semibold' >Negative balance<br/>Protection</p>
                    </div>
                    <div>
                        <img className='w-1/6 mx-auto mb-2' src={Healthcare} alt="" />
                        <p className='font-semibold' >Participant of The Financial<br/>Commission Compensation fund</p>
                    </div>
                    <div>
                        <img className='w-1/6 mx-auto mb-4' src={Quality} alt="" /> 
                        <p className='font-semibold' >Execution quality certificate<br/>of Verify My Trade</p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Security;