import checkIcon from "../../assets/images/monstrade-check.svg"

const checkIco = <img className="w-5" src={checkIcon} alt="" />

const tradingStock = () => {
    return(
        {
            meta : [
                "Deposit from",
                "Leverage",
                "Commision",
                "Min. commision",
                "Min. order volume",
                "Max. order volume",
                "Spread",
                "Trading Time (requests)",
                "Stop out",
                "Corporate actions (mandatory)",
                "Swap Free (Only 1:1 Leverage)"
            ],
            data : [
                {
                    header : "CLASSIC",
                    lines : [
                        "100 USD",
                        "Up to 300 (or by request)",
                        "0",
                        "0",
                        "0.1 share",
                        "10,000 shares",
                        "from 1.2 USD",
                        "24/7",
                        "50%",
                        checkIco,
                        checkIco
                    ]
                },
                {
                    header : "PRIME",
                    lines : [
                        "2500 USD",
                        "Up to 300 (or by request)",
                        "0",
                        "0",
                        "0.1 share",
                        "10,000 shares",
                        "from 0.8 USD",
                        "24/7",
                        "40%",
                        checkIco,
                        checkIco
                    ]
                },
                {
                    header : "ECN",
                    lines : [
                        "10000 USD",
                        "Up to 300 (or by request)",
                        "0",
                        "0.25 USD",
                        "0.1 share",
                        "10,000 shares",
                        "from 0.6 USD",
                        "24/7",
                        "30%",
                        checkIco,
                        checkIco
                    ]
                }
            ]
        }
    )
}

export default tradingStock;