import React from "react";

const TextAreaWithIcon = ({ item, hclass, dclass, iclass, iheight }) => {
    return (

        <div className="rounded-md w-full shadow-2xl p-6">
            <div className={iheight ? iheight : 'h-24'} >
                <img className={`${iclass ? iclass : ''}`} src={item.image} alt="" />
            </div>
            <div className="h-20" >
                <p className={`${hclass ? hclass : ''}`}>{item.header}</p>
            </div>
            <div>
                <p className={`${dclass ? dclass : ''}`}>{item.description}</p>
            </div>
        </div>
    )
}

export default TextAreaWithIcon;