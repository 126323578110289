import React from "react";
import CardBg from "../../assets/images/energies_bg.png"

const EnergiesCard = () => {
    return(
        <>

            <div className="container-full">
                <div className='absolute z-[-1]'>
                    <img src={CardBg} alt="" />
                </div>
                <div className="container-max mx-auto">
                    <div class="grid xl:grid-cols-2 text-center justify-center py-32">
                        <div class="p-6 rounded-lg shadow-lg bg-white md:max-w-lg">
                            <h5 class="text-gray-900 text-xl leading-tight font-bold mb-2 text-center">Brent</h5>
                            <p class="text-gray-700 text-base mb-4 text-center">
                            One of the most famous oil grades. It is produced in the North Sea and serves as a price formation foundation for many other grades of oil.
                            </p>
                            <div>
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                <tbody>
                                                    <tr class="border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Account type
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            Prime, ECN, Classic, Islamic
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            1 Pip Size
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Size of 1 lot
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            1,000 barrels
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Minimum contract size (lot)
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Term Currency
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            USD
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-6 rounded-lg shadow-lg bg-white md:max-w-lg">
                            <h5 class="text-gray-900 text-xl leading-tight font-bold mb-2 text-center">WTI</h5>
                            <p class="text-gray-700 text-base mb-4 text-center">
                            Grade of Oil, which is produced in Texas, USA. This grade is used to produce petrol, that’s why it is in good demand.
                            </p>
                            <div>
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                <tbody>
                                                    <tr class="border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Account type
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            Prime, ECN, Classic, Islamic
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            1 Pip Size
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Size of 1 lot
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            1,000 barrels
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Minimum contract size (lot)
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-semibold px-4 py-4 md:whitespace-nowrap text-left">
                                                            Term Currency
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 md:whitespace-nowrap">
                                                            USD
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
} 

export default EnergiesCard;