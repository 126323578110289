

const leverageContent = () => {
    return [
        {
            name: "FOREX, METALS",
            conditions : [],
            meta: [
                "Equity","ECN","PRIME","CLASSIC"
            ],
            data: [
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: "1.000",
                    },
                    standart : "1:500",
                    fixed: "1:500",
                    ecn: "1:300"
                },
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: "10.000",
                    },
                    standart : "1:500",
                    fixed: "1:500",
                    ecn: "1:300"
                },
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: "50.000",
                    },
                    standart : "1:500",
                    fixed: "1:300",
                    ecn: "1:200"
                },
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: "100.000",
                    },
                    standart : "1:300",
                    fixed: "1:200",
                    ecn: "1:100"
                },
                {
                    equity : {
                        equityLow: "100.000",
                        equityHigh: 0,
                    },
                    standart : "1:100",
                    fixed: "1:100",
                    ecn: "1:100"
                },
            ]
        },
        {
            name: "INDICES, COMMODITIES, BONDS",
            conditions : [],
            meta: [
                "Equity","Leverage"
            ],
            data: [
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 250000,
                    },
                    leverage : "1:100"
                },
                {
                    equity : {
                        equityLow: 250000,
                        equityHigh: 500000,
                    },
                    leverage : "1:50"
                },
                {
                    equity : {
                        equityLow: 500000,
                        equityHigh: 0,
                    },
                    leverage : "1:20"
                }
            ]},
        {
            name : "CRYPTOCURRENCIES",
            conditions : [],
            meta: [
                "Equity","BTC/USD","Other Cryptocurrencies"
            ],
            data : [
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 250000,
                    },
                    btcusd : "1:50",
                    other : "1.20"
                },
                {
                    equity : {
                        equityLow: 250000,
                        equityHigh: 0,
                    },
                    btcusd : "1:25",
                    other : "1.5"
                }
            ]
        },
        {
            name : "STOCKS",
            conditions : ["Maximum leverage 1:10"],
            meta: [],
            data : []
        }
    ]
}

export default leverageContent;