import React from 'react';
import TrustpilotIco from '../assets/icons/trustpilot-ico.png'

const Trustpilot = () => {
    return(
        <>
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>

            <div className="trustpilot-widget text-center py-4" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="6388426932558aa062e7f7db" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="10" data-style-alignment="center">
                <a href="https://www.trustpilot.com/review/monstrade.com" target="_blank" rel="noopener">
                    <img className='inline-block w-6' src={TrustpilotIco} alt="" />
                    <p className='text-lg font-bold' >Trustpilot</p> 
                </a>
            </div>
        </>
    )
}

export default Trustpilot;