import React from 'react'
import { Helmet } from "react-helmet"
export const WebTrader = () => {


  return (
    <div className='container-full' >
      {/* <div id="webterminal" style={{width : '100%', height : '600px'}}></div>
            <Helmet 
  script={[{ 
    type: 'text/javascript', 
    innerHTML: 'new MetaTraderWebTerminal( "webterminal", { version: 5, servers: ["Monstrade-Server"], server: "Monstrade-Server", utmSource: "www.monstrade.com", startMode: "create_demo", language: "en", colorScheme: "black_on_white"} );' 
  }]} 
/> */}
      <iframe src="https://metatraderweb.app/trade" style={{ width: '100%', height: '600px' }} />

    </div>
  )
}
