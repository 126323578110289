import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image from '../../assets/images/book-sample-image.png'
import introductionToFibonacci from '../../assets/documents/introductionToFibonacci.pdf'
import tradingCosts from '../../assets/documents/tradingCosts.pdf'
import riskManagement from '../../assets/documents/riskManagement.pdf'
import tradingMajors from '../../assets/documents/tradingMajors.pdf'
import becomeIB from '../../assets/documents/becomeIB.pdf'

export const EBookCard = (item) => {
    return (
        <div className='bg-white p-5 rounded-2xl'>
            <div className='text-center' >
                <h3 className='text-xl font-semibold' >{item.h1}</h3>
            </div>
            <div className='mb-2' >
                <img className='mx-auto' src={image} alt='' />
            </div>
            <div className='text-center mb-3' >
                <p className='font-light text-gray-500' >{item.p}</p>
            </div>
            <div className='justify-end flex'>
                <div className='text-gray-400'>
                    <a href={
                         item.link == "../../assets/documents/introductionToFibonacci.pdf"
                         ? introductionToFibonacci
                         : [
                             item.link == "../../assets/documents/tradingCosts.pdf"
                                 ? tradingCosts
                                 : [
                                     item.link == "../../assets/documents/riskManagement.pdf"
                                     ? riskManagement 
                                     : [
                                         item.link == "../../assets/documents/tradingMajors.pdf"
                                         ? tradingMajors 
                                         : [
                                             item.link == "../../assets/documents/becomeIB.pdf"
                                             ? becomeIB 
                                             : ""
                                         ]
                                     ]
                                 ]
                         ]
                     
                    } target="_blank" className='mr-3'>
                        <FontAwesomeIcon icon="fa-regular fa-eye" />
                    </a>
                    <a href={
                        item.link == "../../assets/documents/introductionToFibonacci.pdf"
                            ? introductionToFibonacci
                            : [
                                item.link == "../../assets/documents/tradingCosts.pdf"
                                    ? tradingCosts
                                    : [
                                        item.link == "../../assets/documents/riskManagement.pdf"
                                        ? riskManagement 
                                        : [
                                            item.link == "../../assets/documents/tradingMajors.pdf"
                                            ? tradingMajors 
                                            : [
                                                item.link == "../../assets/documents/becomeIB.pdf"
                                                ? becomeIB 
                                                : ""
                                            ]
                                        ]
                                    ]
                            ]
                        
                    } download={
                        item.link == "../../assets/documents/introductionToFibonacci.pdf"
                            ? "Introduction to Fibonacci Analysis.pdf"
                            : [
                                item.link == "../../assets/documents/tradingCosts.pdf"
                                    ? "Knowing Your Trading Costs.pdf"
                                    : [
                                        item.link == "../../assets/documents/riskManagement.pdf"
                                        ? "An In-Depth Look at Risk Management.pdf" 
                                        : [
                                            item.link == "../../assets/documents/tradingMajors.pdf"
                                            ? "Trading The Majors.pdf" 
                                            : [
                                                item.link == "../../assets/documents/becomeIB.pdf"
                                                ? "The Ultimate Guide to Become an IB.pdf" 
                                                : ""
                                            ]
                                        ]
                                    ]
                            ]
                    }>
                        <FontAwesomeIcon icon="fa-solid fa-download" />
                    </a>
                </div>
            </div>
        </div>
    )
}
