import React from 'react'

export const FAQCollapseButton = ({index, header}) => {
    return (
        <li className="nav-item flex-grow text-center border-b-[1px] border-gray-300 " role="presentation">
            <a href="#tabs-homeVertical"
                className={`nav-link block font-medium text-xs 
                    leading-tight uppercase 
                    border-y-0 border-l-2 
                    border-transparent 
                    px-4 py-3
                    hover:bg-gray-100 
                    focus:border-transparent 
                    ${index === 0 ? 'active' : ''}`}
                id="tabs-home-tabVertical" 
                data-bs-toggle="pill" 
                data-bs-target={`#tabs-${index}`}
                role="tab"
                aria-controls="tabs-homeVertical" 
                aria-selected="true">
                {header}
            </a>
        </li>
    )
}
