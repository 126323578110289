import React, {useState, useEffect} from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AnimatedNumbers from "react-animated-numbers";
import StoreTicketCheck from '../components/StoreTicketCheck';

import Background from '../assets/images/store-images/store-banner.png'
import MobileBG from '../assets/images/store-images/store-mobile.png'
import Image1 from '../assets/images/store-images/coffee-image.svg'
import Image2 from '../assets/images/store-images/phone-image.svg'
import Image3 from '../assets/images/store-images/headphones-image.svg'
import Image4 from '../assets/images/store-images/amazon-image.svg'
import Image5 from '../assets/images/store-images/tablet-image.svg'
import Image6 from '../assets/images/store-images/jbl-image.svg'
import Image7 from '../assets/images/store-images/drone-image.svg'
import Image8 from '../assets/images/store-images/mile-image.svg'
import Image9 from '../assets/images/store-images/gold-image.svg'

import Img3000 from '../assets/images/store-images/3000img.svg'
import QR from '../assets/images/store-images/store-qr.png'
import Apple from '../assets/images/home-app-store.png'
import Google from '../assets/images/home-google-store.png'
import Store1 from '../assets/images/store-images/store-image-1.png'
import Store2 from '../assets/images/store-images/store-image-2.png'
import Store3 from '../assets/images/store-images/store-image-3.png'

import Phone1 from '../assets/images/store-images/store-phone-1.png'
import Phone2 from '../assets/images/store-images/store-phone-2.png'
import Phone3 from '../assets/images/store-images/store-phone-3.png'

import AndroidIcon from '../assets/images/store-images/store-icons/android-icon.svg'
import IOSIcon from '../assets/images/store-images/store-icons/ios-icon.svg'

import ArrowBack from '../assets/images/store-images/store-icons/arrow_back.svg'
import ArrowForward from '../assets/images/store-images/store-icons/arrow_forward.svg'

// Silver Icons

import Silver1 from '../assets/images/store-images/store-icons/silver-icons/sesbombasi.svg'
import Silver2 from '../assets/images/store-images/store-icons/silver-icons/kulaklik.svg'
import Silver3 from '../assets/images/store-images/store-icons/silver-icons/airtag.svg'
import Silver4 from '../assets/images/store-images/store-icons/silver-icons/gopro.svg'
import Silver5 from '../assets/images/store-images/store-icons/silver-icons/meta.svg'
import Silver6 from '../assets/images/store-images/store-icons/silver-icons/powerbank.svg'
import Silver7 from '../assets/images/store-images/store-icons/silver-icons/sessistemi.svg'
import Silver8 from '../assets/images/store-images/store-icons/silver-icons/bileklik.svg'
import Silver9 from '../assets/images/store-images/store-icons/silver-icons/amazon.svg'
import Silver10 from '../assets/images/store-images/store-icons/silver-icons/netflix.svg'
import Silver11 from '../assets/images/store-images/store-icons/silver-icons/kahvemak.svg'

// Gold Icons

import Gold1 from '../assets/images/store-images/store-icons/gold-icons/ipad.svg'
import Gold2 from '../assets/images/store-images/store-icons/gold-icons/nintendo.svg'
import Gold3 from '../assets/images/store-images/store-icons/gold-icons/robotsupurge.svg'
import Gold4 from '../assets/images/store-images/store-icons/gold-icons/gopro.svg'
import Gold5 from '../assets/images/store-images/store-icons/gold-icons/meta.svg'
import Gold6 from '../assets/images/store-images/store-icons/gold-icons/djidrone.svg'
import Gold7 from '../assets/images/store-images/store-icons/gold-icons/sessistemi.svg'
import Gold8 from '../assets/images/store-images/store-icons/gold-icons/kahvemak.svg'

// Diamond Icons

import Diamond1 from '../assets/images/store-images/store-icons/diamond-icons/ipad.svg'
import Diamond2 from '../assets/images/store-images/store-icons/diamond-icons/macbook.svg'
import Diamond3 from '../assets/images/store-images/store-icons/diamond-icons/robotsupurge.svg'
import Diamond4 from '../assets/images/store-images/store-icons/diamond-icons/iphoone15.svg'
import Diamond5 from '../assets/images/store-images/store-icons/diamond-icons/vision pro.svg'
import Diamond6 from '../assets/images/store-images/store-icons/diamond-icons/drone.svg'
import Diamond7 from '../assets/images/store-images/store-icons/diamond-icons/ps5.svg'


const Banners = [
    {
        image: Image1
    },
    {
        image: Image2
    },
    {
        image: Image3
    },
    {
        image: Image4
    },
    {
        image: Image5
    },
    {
        image: Image6
    },
    {
        image: Image7
    },
    {
        image: Image8
    },

    {
        image: Image9
    },
]

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const silverItems = [
    {
        image: Silver1,
        title: "JBL Speaker",
        desc: "Net Deposit 2,250 USD",
    },
    {
        image: Silver2,
        title: "Beats Headphones",
        desc: "Net Deposit 3,500 USD",
    },
    {
        image: Silver3,
        title: "Apple Airtag(4 pack)",
        desc: "Net Deposit 3,000 USD",
    },
    {
        image: Silver4,
        title: "GoPro Hero12",
        desc: "Net Deposit 13,500 USD",
    },
    {
        image: Silver5,
        title: "OCULUS Meta",
        desc: "Net Deposit 30,500 USD",
    },
    {
        image: Silver6,
        title: "Apple Battery Pack",
        desc: "Net Deposit 3,000 USD",
    },
    {
        image: Silver7,
        title: "TCL Speakers",
        desc: "Net Deposit 10,500 USD",
    },
    {
        image: Silver8,
        title: "Xiaomi Smart Band",
        desc: "Net Deposit 2,100 USD",
    },
    {
        image: Silver9,
        title: "Amazon Gift Card 50 USD",
        desc: "Net Deposit 1,500 USD",
    },
    {
        image: Silver10,
        title: "Netflix Gift Card",
        desc: "Net Deposit 2,100 USD",
    },
    {
        image: Silver11,
        title: "Nespresso Inissia",
        desc: "Net Deposit 9,000 USD",
    },
]

const goldItems = [
    {
        image: Gold1,
        title: "IPAD 10th",
        desc: "Net Deposit 30,000 USD",
    },
    {
        image: Gold2,
        title: "Nintendo Switch",
        desc: "Net Deposit 15,000 USD",
    },
    {
        image: Gold3,
        title: "Roborock Q8 Max",
        desc: "Net Deposit 3,000 USD",
    },
    {
        image: Gold4,
        title: "GoPro Hero12",
        desc: "Net Deposit 13,500 USD",
    },
    {
        image: Gold5,
        title: "OCULUS Meta",
        desc: "Net Deposit 30,500 USD",
    },
    {
        image: Gold6,
        title: "DJI Mini 3",
        desc: "Net Deposit 30,000 USD",
    },
    {
        image: Gold7,
        title: "TCL Speakers",
        desc: "Net Deposit 10,500 USD",
    },
    {
        image: Gold8,
        title: "Nespresso Inissia",
        desc: "Net Deposit 9,000 USD",
    },
]

const diamondItems = [
    {
        image: Diamond1,
        title: "IPAD 10th",
        desc: "Net Deposit 30,000 USD",
    },
    {
        image: Diamond2,
        title: "Apple MacBook Pro",
        desc: "Net Deposit 50,000 USD",
    },
    {
        image: Diamond3,
        title: "Roborock Q8 Max",
        desc: "Net Deposit 3,000 USD",
    },
    {
        image: Diamond4,
        title: "Iphone 15 Pro",
        desc: "Net Deposit 50,000 USD",
    },
    {
        image: Diamond5,
        title: "Apple Vision Pro",
        desc: "Net Deposit 60.000 USD",
    },
    {
        image: Diamond6,
        title: "DJI Pro Done ",
        desc: "Net Deposit 30,000 USD",
    },
    {
        image: Diamond7,
        title: "Sony Playstation 5 Slim",
        desc: "Net Deposit 35,000 USD",
    },
]

const StorePage = () => {

    const [selectedButton, setSelectedButton] = useState('SILVER');
    const [pageWidth, setPageWidth] = useState('')

    const handleWindowResize = () => {
        setPageWidth(window.innerWidth);
    }

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
      }, []);

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <div className="relative mb-4 gap-4 flex w-full">
              <button onClick={() => previous()} className='absolute bottom-28 2xl:left-28 left-4 p-3'><img src={ArrowBack} alt="" /></button>
              <button onClick={() => next()} className='absolute bottom-28 2xl:right-28 right-4 p-3'><img src={ArrowForward} alt="" /></button>
           </div>
         );
       };

  return (
    <>
        <div className='max-w-[1440px] mx-auto'>
            <div className='bg-center' style={pageWidth > 1024 ? {backgroundImage: "url(" +  Background  + ")", height: '632px'} : {backgroundImage: "url(" +  MobileBG  + ")", height: '632px'}}>

                <Carousel className='max-w-[820px] mx-auto sm:pt-72 pt-56 pb-16 items-center' responsive={responsive} infinite={true} swipeable={true} autoPlay={true} autoPlaySpeed={2000}
                arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}>
                    {Banners.map((banner)=> {
                        return(
                            <div className='flex items-center'>
                                <img className='max-h-[180px] self-center mx-auto' src={banner.image} alt="" />
                            </div>
                        )
                    })}
                </Carousel>

                <div className='flex gap-4 justify-center text-white font-semibold'>
                    <button className='bg-gradient-to-r from-[#3CBB00] to-[#2FFF43] rounded-2xl p-2 px-4'>
                        <a href="https://client.monstradecrm.com/en/auth/login?returnUrl=%2Fdashboard">Deposit Now</a>
                    </button>
                    <button className='bg-transparent border-2 border-white rounded-2xl p-2 px-4'>
                        How to Join
                    </button>
                </div>

            </div>
        </div>

        <div className='bg-monstrade-blue'>
            <div className='flex max-w-[1440px] py-8 mx-auto items-center text-center text-white'>
                <div className='flex-auto'>
                    <p className='font-bold text-5xl inline-block'>
                        <AnimatedNumbers
                            includeComma
                            className="justify-center"
                            transitions={(index) => ({
                            type: "spring",
                            duration: index + 0.3,
                            })}
                            animateToNumber={1000}
                            fontStyle={{
                            fontSize: 40,
                            color: "white",
                            }}
                        />
                    </p>
                    <p className='font-bold inline-block text-5xl'>+</p>
                    <p className='text-3xl font-light'>Participant</p>
                </div>
                <div className='flex-auto'>
                    <p className='font-bold text-5xl'>
                        <AnimatedNumbers
                            includeComma
                            className="justify-center"
                            transitions={(index) => ({
                            type: "spring",
                            duration: index + 0.3,
                            })}
                            animateToNumber={3532}
                            fontStyle={{
                            fontSize: 40,
                            color: "white",
                            }}
                        />
                    </p>
                    <p className='text-3xl font-light'>SILVER TICKETS</p>
                </div>
                <div className='flex-auto'>
                    <p className='font-bold text-5xl'>
                        <AnimatedNumbers
                            includeComma
                            className="justify-center"
                            transitions={(index) => ({
                            type: "spring",
                            duration: index + 0.3,
                            })}
                            animateToNumber={2983}
                            fontStyle={{
                            fontSize: 40,
                            color: "white",
                            }}
                        />
                    </p>
                    <p className='text-3xl font-light'>GOLDEN TICKETS</p>
                </div>
                <div className='flex-auto'>
                    <p className='font-bold text-5xl'>
                        <AnimatedNumbers
                            includeComma
                            className="justify-center"
                            transitions={(index) => ({
                            type: "spring",
                            duration: index + 0.3,
                            })}
                            animateToNumber={2245}
                            fontStyle={{
                            fontSize: 40,
                            color: "white",
                            }}
                        />
                    </p>
                    <p className='text-3xl font-light'>DIAMOND TICKETS</p>
                </div>
            </div>
            
            
        </div>

        <div className='bg-[#EAF1FF]'>

            <div className='lg:flex items-center text-center font-semibold text-4xl justify-center py-8'>
                <p>We have sent</p>
                <img className='lg:mx-0 mx-auto px-4' src={Img3000} alt="" />
                <p>gifts to our Traders this year!</p>
            </div>

            <div className='max-w-[1200px] mx-auto'>
                <div className='lg:flex'>
                    <button onClick={()=> setSelectedButton('SILVER')} className={`${selectedButton == "SILVER" ? "bg-gradient-to-b from-[#ABABAB] via-[#FFFFFF] to-[#C9C9C9] text-[#BBBBBB]" : "bg-[#F6F6F6] text-[#AEAEAE]"} w-full p-2 font-semibold`}>SILVER</button>
                    <button onClick={()=> setSelectedButton('GOLD')} className={`${selectedButton == "GOLD" ? "bg-gradient-to-b from-[#CEBA00] via-[#FFF7AE] to-[#D7C20C] text-[#CCAB00]" : "bg-[#F6F6F6] text-[#AEAEAE]"} w-full p-2 font-semibold`}>GOLD</button>
                    <button onClick={()=> setSelectedButton('DIAMOND')} className={`${selectedButton == "DIAMOND" ? "bg-gradient-to-b from-[#00FFD1] via-[#96FFEC] to-[#00FFFF]  text-[#006357]" : "bg-[#F6F6F6] text-[#AEAEAE]"} w-full p-2 font-semibold`}>DIAMOND</button>
                </div>

                <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-6 py-4'>
                    {selectedButton == "SILVER" ? silverItems.map((item)=> {
                        return(
                        <div className='grid gap-2 bg-white w-[250px] p-4 rounded-xl mx-auto text-center'>
                            <img className='mx-auto max-h-[120px]' src={item.image} alt="" />
                            <p className='font-bold'>{item.title}</p>
                            <p className='text-sm text-gray-600'>{item.desc}</p>
                            <button className='bg-monstrade-blue p-1 px-6 rounded-lg text-white font-semibold'>Request</button>
                        </div>
                        )
                    }) : selectedButton == "GOLD" ? goldItems.map((item)=> {
                        return(
                        <div className='grid gap-2 bg-white w-[250px] p-4 rounded-xl mx-auto text-center'>
                            <img className='mx-auto max-h-[120px]' src={item.image} alt="" />
                            <p className='font-bold'>{item.title}</p>
                            <p className='text-sm text-gray-600'>{item.desc}</p>
                            <button className='bg-monstrade-blue p-1 px-6 rounded-lg text-white font-semibold'>Request</button>
                        </div>
                        )
                    }) : diamondItems.map((item)=> {
                            return(
                            <div className='grid gap-2 bg-white w-[250px] p-4 rounded-xl mx-auto text-center'>
                                <img className='mx-auto max-h-[120px]' src={item.image} alt="" />
                                <p className='font-bold'>{item.title}</p>
                                <p className='text-sm text-gray-600'>{item.desc}</p>
                                <button className='bg-monstrade-blue p-1 px-6 rounded-lg text-white font-semibold'>Request</button>
                            </div>
                        )
                    })
                    }
                </div>
            </div>

            <div className='text-center pb-4'>
                <button className='bg-transparent px-6 p-1 text-xl border-2 border-monstrade-blue rounded-2xl'>View All</button>
            </div>

        </div>

        {/* <StoreTicketCheck/> */}

        <div className='bg-[#F9F9F9]'>
            <div className='grid lg:grid-cols-2 items-center lg:text-left text-center mx-2'>
                <div className='max-w-[500px] mx-auto'>
                    <p className='text-4xl font-semibold'>To Have Gifts, Download the Monstar App</p>
                    <p className='py-4'>Monstrade Giveaway products are claimed through the Monstar app. Download the Monstar app now to request the products you want to own!</p>
                    <div className='flex lg:justify-start justify-center gap-4'>
                        <a target='_blank' href="https://play.google.com/store/apps/details?id=com.monstrade.monstar&pcampaignid=web_share"><img src={AndroidIcon} alt="" /></a>
                        <a target='_blank' href="https://apps.apple.com/tr/app/monstar/id6472909004?l=tr"><img src={IOSIcon} alt="" /></a>
                    </div>
                </div>
                <div className='lg:flex pt-8'>
                    <div className='flex lg:mx-0 mx-auto justify-center pb-4 lg:items-end'>
                        <img className='h-20' src={QR} alt="" />
                        <div>
                            <a target='_blank' href="https://apps.apple.com/tr/app/monstar/id6472909004?l=tr"><img className='w-32' src={Apple} alt="" /></a>
                            <a target='_blank' href="https://play.google.com/store/apps/details?id=com.monstrade.monstar&pcampaignid=web_share"><img className='w-32' src={Google} alt="" /></a>
                        </div>
                    </div>
                    <img className='lg:mx-0 mx-auto' src={Store1} alt="" />
                </div>
                
            </div>
        </div>

        <div className='bg-[#EEEEEE] py-6'>
            <div className='max-w-[1440px] mx-auto'>
            <div className='text-center py-6'>
                <p className='text-3xl font-bold text-monstrade-blue'>STEPS TO JOIN</p>
                <p className='max-w-[450px] text-[#636363] mx-auto'>In order to enter our draw, make sure you copmlete all 3 steps within the New Year’s Draw.</p>
            </div>

            <div className='grid lg:grid-cols-3 gap-8 lg:mx-36 mx-4 py-6 text-white'>
                <div className='relative'>
                    <img className='mx-auto' src={Phone1} alt="" />
                    <div className='absolute w-full bottom-0 bg-gradient-to-b from-[#0052FF] to-[#1BE4FF] rounded-xl p-8 text-center'>
                        <p className='text-4xl font-bold'>1</p>
                        <p>Open an account</p>
                    </div>
                </div>
                <div className='relative'>
                    <img className='mx-auto' src={Phone2} alt="" />
                    <div className='absolute w-full bottom-0 bg-gradient-to-b from-[#0052FF] to-[#1BE4FF] rounded-xl p-8 text-center'>
                        <p className='text-4xl font-bold'>2</p>
                        <p>Verify your profile</p>
                    </div>
                </div>
                <div className='relative'>
                    <img className='mx-auto' src={Phone3} alt="" />
                    <div className='absolute w-full bottom-0 bg-gradient-to-b from-[#0052FF] to-[#1BE4FF] rounded-xl p-8 text-center'>
                        <p className='text-4xl font-bold'>3</p>
                        <p>Deposit a minimum of $250</p>
                    </div>
                </div>
            </div>

            <div className='text-center'>
                <button className='bg-monstrade-blue px-10 p-2 text-xl border-2 rounded-lg text-white'><a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">Register Now</a></button>
                <p className='text-sm text-[#545454] py-4'>Already a client? Log in <a href="https://client.monstradecrm.com/en/auth/login?returnUrl=%2Fdashboard"><span className='underline'>here</span></a> to participitate.</p>
                <p className='text-xs text-[#545454]'>All trading involves risk. It is possible to lose all your capital. <a href=""><span className='underline'><a href="/about/company/documentation">*Terms & Conditions</a></span></a> apply.</p>
            </div>
            </div>
        </div>

        <div className={"max-w-[1440px] mx-auto lg:h-[646px] h-[300px] bg-[#24000b] bg-center bg-no-repeat"} style={{ backgroundImage: `url("${Store3}")` }}>
            <p className='text-white text-center text-xl max-w-[600px] mx-auto pt-6'>Start investing right away! Join the draw and seize the chance to win one of dozens of prizes or even more!</p>
        </div>
    </>
  )
}

export default StorePage