import React, { useState } from 'react'
import TextScroller from './TextScroller'

const data = [
    { mt: 'xl:mt-8', title: 'Safe Trading', text: 'MonsTrade is a regulated institution overseen by official bodies such as FinCEN and NFA, providing you with a secure environment for trading without worries.' },
    { mt: 'xl:mt-24', title: 'Advanced Applications', text: 'Enhance and manage your investments with the latest trade assistant application, Monstar, while trading with the most advanced trading platform MT5 on MonsTrade.' },
    { mt: '', title: 'Forex Signals', text: 'Access real-time signals for Forex investment products with our Monstar app at no additional cost.' },
    { mt: 'xl:mt-16', title: 'Education Resources', text: 'From basic to advanced levels, enhance your skills with forex training and increase profitability in your investments.' },
]

export const HeaderSlider = () => {
    return (
        <div>
            <div className='2xl:flex grid 2xl:gap-0 z-20 gap-8 py-20'>
                {data.map((item, index) => (
                    <SliderContainer
                        key={index}
                        mt={item.mt}
                        title={item.title}
                        text={item.text}
                    />
                ))}
            </div>
        </div>
    )
}

const SliderContainer = ({ mt, title, text, slidingText }) => {
    const [isHover, setIsHover] = useState(false)
    return (
        <div
            onMouseEnter={() => setIsHover(true)} 
            onMouseLeave={() => setIsHover(false)}
            className={`${mt} flex flex-col justify-end relative bg-gradient-to-r z-20 mx-auto from-[#0052FF] to-[#4B85FF] overflow-x-auto overflow-hidden overflow-x-hidden max-w-[360px] h-[360px]`}>
            <div className='text-white p-8 z-50'>
                <p className='font-bold text-3xl pb-2'>{title}</p>
                <p>{text}</p>
            </div>
            <TextScroller
                isHover={isHover}
                text={slidingText ? slidingText : title} />
        </div>
    )
}
