import React from 'react'
import img from '../assets/images/hourglass.png'

export const AboutForexSwapRates = () => {
  return (
    <div className='container-full bg-white' >
        <div className='container-max mx-auto sm:py-20 py-10'>
            <div className='text-center sm:mb-20 mb-6' >
                <h3 className='text-3xl font-semibold' >What You Should Know about Forex Swap Rates?</h3>
            </div>
            <div className='grid md:grid-cols-2 -md:grid-rows-2'>
                <div>
                    <img src={img} alt='' ></img>
                </div>
                <div>
                    <ul className='list-disc' >
                        <li className='mb-4' >Swap rates are applied at 00:00 platform time</li>
                        <li className='mb-4' >Swaps are applied each night on open positions only</li>
                        <li className='mb-4' >Swap rates are calculated in points and can be positive or negative depending on the interest rate difference of the two currencies</li>
                        <li className='mb-4' >Some forex pairs could have negative swap rates on both long and short positions</li>
                        <li className='mb-4' >Swap rates are calculated in points. MT5 convert these points automatically into your account currency</li>
                        <li className='mb-4' >The rates are triple the usual amount on Wednesday nights, to account for the weekend**</li>
                        <li className='mb-4' >Each forex pair has its own rollover fee, which is measured in the standard size of one lot or 100,000 units</li>
                    </ul>
                    <p className='text-gray-600 text-sm'>
                        **Please note that on some instruments Triple Swaps are charged on Friday, Please check the platform specifications of the instrument you have chosen.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
