import React from 'react'
import { TradingTimeCollapse } from '../components/Collapse/TradingTimeCollapse'

export const TradingTimeTables = () => {
  const timeForForex = ['-', '23:59 pm – 00:01 am', '23:59 pm – 00:01 am', '23:59 pm – 00:01 am', '23:59 pm – 00:01 am', '23:59 pm – 23:59 pm', '-']
  const timeForMetals = ['-', '23:59 pm – 00:01 am', '23:59 pm – 00:01 am', '23:59 pm – 00:01 am', '23:59 pm – 00:01 am', '23:59 pm – 23:59 pm', '-']
  const timeForEnergies = ['-', '23:59 pm – 01:00 am', '23:59 pm – 03:00 am', '23:59 pm – 03:00 am', '23:59 pm – 03:00 am', '23:59 pm – 23:59 pm', '-']
  const timeForIndices = ['-', '23:00 pm – 03:30 am', '23:00 pm – 03:30 am', '23:00 pm – 03:30 am', '23:00 pm – 03:30 am', '23:00 pm – 23:59 pm', '-']
  return (
    <div className='container-full md:py-3 py-10'>
        <div className='container-max mx-auto'>
            <h3 className='text-center text-4xl font-semibold mb-10' >Trading Table for Tables</h3>
            <TradingTimeCollapse header={'Forex'} times={timeForForex}/>
            <TradingTimeCollapse header={'Metals'} times={timeForMetals} />
            <TradingTimeCollapse header={'Energies'} times={timeForEnergies}/>
            <TradingTimeCollapse header={'Indices'} times={timeForIndices}/>
        </div>
    </div>
  )
}
