import React from 'react';

const PromotionButtons = (props) => {
    return (
        <>
            <div className="p-6">
                <div class="flex lg:flex-row flex-col items-center justify-start gap-3">
                    <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard"
                        type="button"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                        class="inline-block px-8 py-3 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:text-white hover:bg-monstrade-green focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >Open Account</a>
                    <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard"
                        type="button"
                        class="inline-block px-6 py-2.5 border border-blue-600 text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    >Deposit Account</a>
                </div>

            </div>

        </>
    )
}

export default PromotionButtons;