import React from "react";
import tempImage from "../../assets/images/rectangle.png"

const ForexNewsCard = ({item}) => {

    return(
        <a href={item.url} className="" target="_blank">
            <div className="w-full mb-8">
                <div>
                    <img className="sm:mx-0 mx-auto" src={tempImage} alt="" />
                </div>
                <div className="pt-1" >
                    <p className="text-gray-400 text-sm">{item.date}</p>
                </div>
                <div className="mb-2" >
                    <p className="text-monstrade-blue font-semibold" >{item.source} - {item.code}</p>
                </div>
                <div className="mb-2" >
                    <h3 className="font-semibold text-lg line-clamp-3 leading-5">{item.title}</h3>
                </div>
                <div className="mb-2" >
                    <p className="text-gray-400 text-sm leading-5 line-clamp-6" >{item.description}</p>
                </div>
            </div>
        </a>
    )
}

export default ForexNewsCard;