import React from "react";
import TradingHoursBG from "../assets/images/trading-hours-bg.png"
import PhoneImg from "../assets/images/trade-mobile-mockup.png"
import LaptopImg from "../assets/images/trade-pc-mockup.png"

const TradingHoursMT5 = () => {
    return(
        <>
            <div className="container-full h-auto sm:py-20 py-14 text-white" style={{background : 'linear-gradient(to right, #360033, #0b8793)'}}>
                <div className="container-max mx-auto">
                    <p className=" font-bold text-2xl text-center sm:pb-16 pb-10">
                        MT5 TRADING HOURS
                    </p>
                    <div className="grid md:grid-cols-2 md:gap-10 gap-5">
                        <div className="relative" >
                            <div className="absolute h-full flex" >
                                <img className="my-auto md:ml-[-30%] ml-[150%] w-[9vw]" src={PhoneImg} alt="" />
                            </div>
                            <div className="flex h-full w-full" >
                                <img className="m-auto md:w-full w-72" src={LaptopImg} alt="" />
                            </div>
                        </div>
                        <div className="sm:pt-10 pt-4">
                            <p className="pb-6">
                                MetaTrader 5 is the world's most popular trading platform and the platform of choice for day traders. Monstrade trading hours for MetaTrader 5 can be found in the table below. Forex traders should pay attention to the following:
                            </p>
                            <ul className="list-disc pl-4">
                                <li>Monstrade MetaTrader 5 server time and charts are GMT+2 or GMT+3 (when New York Daylight savings takes effect)</li>
                                <li>We operate these server times to ensure a 5 daily candle week</li>
                                <li>All currency pairs trade 24 hours a day with a break for two minutes between 23:59 and 00:01. The trading week starts on Monday at 00:02 and closes Friday at 23:57</li>
                                <li>This applies to all currencies including USD, AUD, GBP, EUR and JPY</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TradingHoursMT5;