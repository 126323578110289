import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
//images
import bgImage from '../assets/images/banners/contact-us-banner.png'
import { ContactInfo } from '../containers/ContactInfo'
import { BannerContainer } from '../components/Containers/BannerContainer'

import TopBanner from "../components/TopBanner"
import Button from "../components/Button"

export const ContactUs = () => {

    const landingOptions = {
      image : bgImage,
      imageGradient : "linear-gradient(90deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1))",
      container : {
        width : 'w-2/3',
        padding : 'py-20'
      },
      header: {
        text : 'Your trade will be Fully Supported by our System',
        size: 'text-3xl',
      },
      subHeader: {
        text : "Whatever the question, we have the answer. Whatever the problem, we have the solution. Our award-winning team is the support you need to develop your trading career:",
      },
      button: {}
    }

  return (
    <div>
        <BannerContainer options={landingOptions}/>
        <ContactInfo />
    </div>
  )
}
