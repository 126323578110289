import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";   
import StockContentTable from "../components/StockContentTable" 
import Faq from "../components/Faq"
import Security from "../components/Security";
import StocksImg from "../assets/images/stocks_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import WalletIco from "../assets/icons/wallet2.svg"
import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";

const pageName = "Stocks"

const landingOptions = {
    image : StocksImg,
    imageGradient : 'linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5))',
    mainZindex : 'z-[-1]',
    header: {
        text : 'Stocks',
        size: 'text-3xl',
      },
      subHeader: {
        text : "Invest in stocks at Monstrade:get an access to thousands of instruments right now.",
        align : "text-center"
      }
}
const cards = [
    { header: 'Leverage value is up to 1:500', description: '', icon: BalanceIco, link: '' },
    { header: 'Low spreads', description: '', icon: ForexIco, link: '' },
    { header: 'Start trading with 100 USD', description: '', icon: WalletIco, link: '' },
]

const question1= 'What is stocks trading?'
const answer1= "Are you interested in how to start trading stocks? Let’s start with the basics. Stocks represent ownership in a company that’s publicly listed on a stock exchange. Companies typically ‘go public’ – that is, list their stock on an exchange – to raise more capital than they can receive from their private owners or through bank financing.<br/><br/>A company will typically start to sell its shares on an exchange through an initial public offering (IPO) on a primary market, taking it from private ownership to a combination of privately and publicly owned shares. Once the shares start trading on a secondary market, which is a stock market exchange, their price fluctuates based on investor assessment of the stock’s value throughout the trading session.<br/><br/>The capital a company raises from stock investors is typically used to expand the business, whether organically or through mergers and acquisitions (M&A)."

const question2= 'What is the difference between stock and shares?'
const answer2= "First let's look at stocks versus shares since these are the two terms that are most commonly confused, especially by newer investors. The main difference between a stock and a share is that stock is a broader concept to convey ownership in a company, while shares are the individual units of ownership.<br/><br/>Stocks are securities that represent ownership in a corporation. When an investor buys a company's stock, that person is not lending the company money but is buying a percentage of ownership in that company. In exchange for purchasing stocks in a given company, stockholders have a claim on part of its earnings and assets. Some stocks pay quarterly or annual dividends, which are a portion of the issuing company's earnings.<br/><br/>An individual unit of stock is known as a share. For example, if you were to say, I own stock in Apple (NASDAQ:AAPL), it tells us that you are invested in Apple stock and therefore own a small portion of the equity in the company. On the other hand, if you say, I own 100 shares of Apple, it conveys the exact number of ownership units you have."

const question3= 'What stocks are better for trading?'
const answer3= "While some stocks can deliver substantial price action on the intraday chart, others cannot. The former category of stocks is known as day trading stocks. They are the ideal investment vehicle for investors who want to make a living on the stock market as day traders.<br/><br/>The best day trading stock is one with enough volume and volatility. When a stock trades often and in high volumes with a reasonable price fluctuation, it presents an opportunity for investors to enter and exit positions quickly. Additionally, a day trading stock must be newsworthy.<br/><br/>The best trading stocks feature significant volatility and substantial volume to drive daily price actions. A look at some of the best stocks to trade for your investment portfolio.<br/><br/>Tesla Inc. (NASDAQ: TSLA)<br/>Advanced Micro Devices Inc. (NASDAQ: AMD)<br/>Apple Inc. (NASDAQ: AAPL)<br/>Etsy Inc. (NASDAQ: ETSY)<br/>AMC Entertainment Holdings Inc. (NYSE: AMC)<br/>Farfetch Ltd. (NYSE: FTCH)<br/><br/>Stocks are securities that represent ownership in a corporation. When an investor buys a company's stock, that person is not lending the company money but is buying a percentage of ownership in that company. In exchange for purchasing stocks in a given company, stockholders have a claim on part of its earnings and assets. Some stocks pay quarterly or annual dividends, which are a portion of the issuing company's earnings.<br/><br/>An individual unit of stock is known as a share. For example, if you were to say, I own stock in Apple (NASDAQ:AAPL), it tells us that you are invested in Apple stock and therefore own a small portion of the equity in the company. On the other hand, if you say, I own 100 shares of Apple,it conveys the exact number of ownership units you have."
          


const StocksPage = () => {

    return(
        <>
            {/* <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={WalletIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={StocksImg} />  */}
            <BannerContainer options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-3'} />
                </div>
            </BannerContainer>
            <div className="mt-10">
                <Button content="Invest In Metals" color="bg-monstrade-button-green" py="py-2" px="px-8" /> 
            </div>
            <Table type={'stock'} />
            <PhoneMockup pageName={pageName}/>
            <AvailableAccountTypes/>
            {/* <StockContentTable/> */}
            <Security/>
            <Faq faqTitle1={question1} faqText1={<div dangerouslySetInnerHTML={{__html: answer1}} />} faqTitle2={question2} faqText2={<div dangerouslySetInnerHTML={{__html: answer2}} />} faqTitle3={question3} faqText3={<div dangerouslySetInnerHTML={{__html: answer3}} />} isTwoItems={true} isThreeItems={true}/>
        </>
    )
}

export default StocksPage;