import React,{useState,useEffect} from 'react'

import { getCustomerTransaction } from './SliderServices/transaction'
import { animated, useSpring } from '@react-spring/web'
import SliderV2Card from './SliderV2Card';
import SliderV2Carousel from './SliderV2Carousel';

import Slider1 from '../../assets/images/v2-slider2-1.png'
import Slider2 from '../../assets/images/v2-slider2-2.png'
import Slider3 from '../../assets/images/v2-slider2-3.png'
import Slider4 from '../../assets/images/v2-slider2-4.png'

import Previous from '../../assets/icons/v2-previous.svg'
import Forward from '../../assets/icons/v2-forward.svg'

    const sliders = [
        {
            id: 0,
            title:"Zero Commission",
            desc:"MonsTrade covers all deposit and withdrawal transfer fees for payments made via Neteller, Moneybookers and all major credit cards. Additionally, all deposits and withdrawals above processed by wire transfer are also included in our zero fees policy.",
            image: Slider1,
            link: "/promotions/zero-commission"
        },
        {
            id: 1,
            title:"Campaigns",
            desc:"We enable investors to minimize their losses while maximizing their profits with our advantageous campaigns.",
            image: Slider2,
            link: "/campaign/choose-product"
        },
        {
            id: 2,
            title:"Invite Friends",
            desc:"Take a step into the world of MonsTrade's privileges by inviting your friends. Benefit from many advantages such as Monscredit, Monscash, Swapday, Trade Refund, and Store Rewards.",
            image: Slider3,
            link: "/promotions/invite-a-friend"
        },
        {
            id: 3,
            title:"Account Transfer",
            desc:"If you have open positions with another broker, simply transfer them to MonsTrade and get the most attractive trading terms for maximum profit.",
            image: Slider4,
            link: "/campaign/account-change"
        },
    ]

export function SliderV2() {

  const [selectedNumber, setSelectedNumber] = useState(0)
  // const data = Array.from(Array(4).keys())
  const [index, setIndex] = useState(0)
  
  const handleIndex = (carouselIndex) => {
    setIndex(carouselIndex)
  }

  const handleCarouselIndex = (direction) => {
    setIndex(prevState => {
      return (prevState + (direction ? prevState < sliders.length - 1 ? 1 : -1 * (sliders.length - 1) : prevState > 0 ? -1 : sliders.length - 1))
    })
  }

  useEffect(()=>{
    setInterval(() =>  {
      handleCarouselIndex(true)
  }, 10000);
  },[])


  return (
    <div className='relative grid sm:gap-12 xl:grid-cols-2 items-center mx-4'>
    
    <div className='flex xl:justify-end justify-center'>
      <SliderV2Carousel
        onIndexChange={handleIndex}
        hideArrows={true}
        indexValue={index}
      >
          {sliders.map((item,itemIndex) => (
            <div onClick={()=>setIndex(itemIndex)}>
              <SliderV2Card value={item} />
            </div>
          ))}  
      </SliderV2Carousel>   
    </div>
    <div className='sm:hidden block flex gap-2 justify-center'>
      {sliders.map((item)=>{
        return(
          <>
            <div onClick={()=>setIndex(item.id)} className={`${item.id == index ? "bg-black w-8 h-3 rounded-2xl" : "bg-gray-300 w-3 h-3 rounded-2xl"}`}></div>
          </>
        )
      })}
      
    </div>
    <div className='max-w-[600px] xl:mx-0 mx-auto xl:pb-0 pb-6'>
      <p className='text-4xl sm:pt-0 pt-8'>Advantages for you</p>
      <p className='text-lg font-bold py-4'>{sliders[index].title}</p>
      <p>{sliders[index].desc}</p>
      <button className='xl:block hidden bg-monstrade-blue text-white font-semibold rounded-3xl p-2 px-4 my-4'><a href={sliders[index].link}>Learn More</a></button>
      <p>All trading involves risk. It is possible to lose all your capital.</p>
      <button className='xl:hidden block bg-monstrade-blue text-white font-semibold rounded-3xl p-2 px-4 my-4'><a href={sliders[index].link}>Learn More</a></button>
    </div>

      <div className='xl:block hidden absolute right-8 bottom-4'>
          <div className='flex gap-4'>
            <div onClick={()=> handleCarouselIndex(false)}>
              <img className='w-8' src={Previous}/>
            </div>
            <div onClick={()=> handleCarouselIndex(true)}>
              <img className='w-8' src={Forward}/>
            </div>
          </div> 
      </div>
    </div>
  );
}
