import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'
import { RegisterForm } from '../components/Forms/RegisterForm';
import styles from '../containers/StartTrading.module.css'

import bgImage from '../assets/images/banners/license-banner.png'
import imgM1 from '../assets/images/banners/license-banner-mobile.png'

import reguImage from '../assets/images/regulations-image.png'
import reguImage2 from '../assets/images/regulations-image-2.png'
import Document from '../assets/documents/msb_registration.pdf'
import Logo from '../assets/images/nfa-logo.png'

import Icon1 from '../assets/icons/settings-l.png'
import Icon2 from '../assets/icons/lock-l.png'
import Icon3 from '../assets/icons/architech-l.png'
import Icon4 from '../assets/icons/shield-l.png'
import Icon5 from '../assets/icons/umbrella-l.png'

import Image from '../assets/images/license-banner-image.png'

const landingOptions = {
    image : bgImage,
    mobileImage: imgM1,
    container : {
        width : 'md:w-2/4 w-full',
        padding : 'py-20',
        margin : 'md:ml-0 m-auto'
      },
    header: {
      text : 'Safegaurd your Funds with MonsTrade',
      size: 'text-4xl max-w-[390px]',
      color: 'text-white',
      align: "text-left"
    },
    subHeader: {
      text : "Most Transparent Broker",
      size: '',
      color: 'text-[#0DF0FF]',
      align: "md:text-left text-center"
    },
    button: {
        bgcolor: "bg-white",
        textcolor: "text-[#0052FF] font-bold shadow-lg",
        text: "Invest Now ➜",
        textSize: "px-1 flex w-36",
    },
    addition: {
        image: Image,
        align: 'flex pt-10'
    }
  }

export const Regulations = () => {
  return (
    <>
        <BannerContainer options={landingOptions}/>
        <div className={`container-full text-white relative bg-no-repeat h-[707px] bg-cover`} style={{ backgroundImage: `url("${reguImage}")` }}>
            <div className='text-center pt-16'>
                <p className='text-3xl font-bold'>Financial Crimes Enforcement Network</p>
                <p className='text-2xl font-semibold'>MSB Registration Status Information</p>
            </div>
            <div className='grid md:grid-cols-3 md:w-3/4 mx-auto items-center md:text-left text-center gap-8 md:pt-20 pt-12'>
                <div className='md:col-span-2 md:w-3/4 md:mx-auto mx-6'>
                    <p>
                    Since its establishment, MonsTrade has adhered to and continues to adhere to various legal restrictions and regulations. Corporate culture and philosophy are also shaped by these legal restrictions. You can check the license for <a target='_blank' href="https://www.fincen.gov/msb-state-selector" className='underline text-[#97EF16]'>registration here.</a><br/>
                    <a target='_blank' href={Document} className='underline text-[#00F0FF]'>Click here to see our Registration Status Information</a>
                    </p>
                </div>
                <div className='md:col-span-1'>
                    <img className='mx-auto md:w-full w-1/2' src={reguImage2} alt="" />
                </div>
            </div>
        </div>
        <div>
            <div className='grid lg:grid-cols-2 items-center py-10'>
                <div>
                    <img className='mx-auto' src={Logo} alt="" />
                </div>
                <div className='lg:text-left text-center'>
                    <p>The NFA (National Futures Association) is a regulatory organization for the U.S. derivatives industry. The fact that brokerage firms or other financial institutions offering futures services are supervised by the NFA is crucial for ensuring fair and ethical practices within the industry, as well as safeguarding the security of traders and investors from illegal activities. In this regard, the NFA has specific conditions and standards that must be strictly adhered to by institutions under its supervision. Failure to comply with these standards may subject the supervised institutions to certain sanctions and disciplinary measures.
                    <br/><br/>
                    At MonsTrade, in addition to our other regulatory activities, we are also subject to scrutiny by the NFA, ensuring that we meet specific standards for the safety and maximum transparency of our users. This enables our users to carry out their investments with confidence.
                    <br/><br/>
                    "Our Company's Registration on NFA: 0560385"
                    <br/><br/>
                    <a href='#' className='text-[#1BCEF2] underline'>Click here to view our licenses related to the NFA (National Futures Association)</a></p>
                </div>
            </div>
        </div>
        <div className="bg-slate-100">
        <div className='container-max mx-auto'>
            <div className='grid md:grid-cols-2 sm:py-20 py-10 gap-10 sm:text-left text-center'>
                <div>
                    <div className='mb-7' > <h3 className='text-4xl text-black' > Start Trading in Minutes</h3> </div>
                    <div className='text-black text-lg text-left' >
                        <ul className={styles.sul} >
                            <li>Access 10,000+ financial instruments</li>
                            <li>Auto open & close positions</li>
                            <li>News & economic calendar</li>
                            <li>Technical indicators & charts</li>
                            <li>Many more tools included</li>
                        </ul>
                    </div>
                </div>
                <div className='flex md:justify-end justify-center' >
                    <div className='ld:w-3/4 w-full' >
                        <RegisterForm />
                        <p>By supplying your email you agree to MonsTraders <a className='text-blue-400 underline' href="#">privacy policy</a> and recieve future marketing materials from MonsTraders. You can unsubscribe at any time.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-monstrade-blue py-10'>
            <p className='text-4xl text-white pb-6 text-center'>Security of Funds</p>
            <div className='grid lg:grid-cols-5 items-center'>
                <div className='grid grid-cols-2 items-center'>
                    <img className='mx-auto' src={Icon1} alt="" />
                    <p className='text-white max-w-[100px]'>Risk Management</p>
                </div>
                <div className='grid grid-cols-2 items-center'>
                    <img className='mx-auto' src={Icon2} alt="" />
                    <p className='text-white max-w-[120px]'>Leading Financial Safety</p>
                </div>
                <div className='grid grid-cols-2 items-center'>
                    <img className='mx-auto' src={Icon3} alt="" />
                    <p className='text-white max-w-[120px]'>Accounts with major banks</p>
                </div>
                <div className='grid grid-cols-2 items-center'>
                    <img className='mx-auto' src={Icon4} alt="" />
                    <p className='text-white max-w-[100px]'>Segregated Accounts</p>
                </div>
                <div className='grid grid-cols-2 items-center'>
                    <img className='mx-auto' src={Icon5} alt="" />
                    <p className='text-white max-w-[100px]'>Segregated Accounts</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
