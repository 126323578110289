import React from 'react'
//components
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import { MissionOnAboutUs } from '../containers/MissionOnAboutUs'
//images
import image from '../assets/images/banners/about-us-banner.png'
import image2 from '../assets/images/about-monstrade-img.png'
import { WhoWeAre } from '../containers/WhoWeAre'
import { SimpleCardListContainer } from '../components/ContainerLayout/SimpleCardListContainer'
import icon1 from '../assets/icons/security-of-client-cert-icon.svg'
import icon2 from '../assets/icons/security-of-client-heart-icon.svg'
import icon3 from '../assets/icons/security-of-client-tick-icon.svg'
import icon4 from '../assets/icons/security-of-client-shield-icon.svg'
import { CustomerReviewContainer } from '../containers/CustomerReviewContainer'
import { BannerContainer } from '../components/Containers/BannerContainer'

export const AboutUs = () => {

    const aboutUsText = "Monstrade Limited was founded by a group of asset managers with 15 years of experience in the Dubai financial sector, \
    which provides Data provider services to large financial institutions, financially supports fintech start-ups and provides \
    liquidity to financial institutions. Ten years ago, in 2012, with the right investments, Monstrade Limited has grown \
    over time to become one of the leading forex brokerage houses with over $100 million in equity.<br/><br/>Monstrade is \
    headquartered in Belgrade, Serbia. It has over 200 employees. For the last 10 years, we have been serving countries in \
    Europe, Asia and Africa.<br/><br/>Monstrade is also a member of Financialcommission.org and each client is protected up to \
    €20,000. Monstrade and its subsidiaries are trading at US$5.4m per day, and more than 200,000 investors worldwide trust us."

    const cards = [
        {   
            header: 'Regulated Activity', 
            description: '', 
            icon: icon1, link: '' },
        { 
            header: 'Negative balance protection', 
            description: '', 
            icon: icon2, link: '' },
        { 
            header: 'Compensation Fund of The Financial Commission', 
            description: '', 
            icon: icon3, link: '' },
        {   
            header: 'Civil Liability insurance program', 
            description: '', 
            icon: icon4, link: '' },
    ]

    const landingOptions = {
        image : image2,
        container : {
          width : 'w-3/4',
          padding : 'py-2'
        },
        header: {
          text : 'About Monstrade',
          size: 'text-4xl',
          align: 'md:text-left text-center',
          color : "text-black"
        },
        subHeader: {
          text : <div dangerouslySetInnerHTML={{__html: aboutUsText}} />,
          color : "text-black",
          align : "md:text-left text-center"
        },
        button: {}
      }

    return (
        <div>
            <BannerContainer options={{image:image}} >
                <div className='flex-col m-auto text-white text-center' >
                    <div className='mb-10' >
                        <h1 className='md:text-5xl text-3xl font-semibold' >MonsTrade</h1>
                        <h2 className='md:text-5xl text-3xl' >Rapidly Growing Broker</h2>
                    </div>
                    <div className='grid md:grid-cols-3 grid-cols-2 w-full mb-16'>
                        <div className='md:block hidden'>
                            <p className='md:text-6xl text-3xl font-bold mb-3' >5+</p>
                                <p className='mb-3 md:text-2xl text-lg' >years experience in <br/>the industry</p>
                            <div className='px-24' >
                                <div className='md:border-b-8 border-b-4 border-[#00C7FF]' ></div>
                            </div>
                        </div>
                        <div>
                            <p className='md:text-6xl text-3xl font-bold mb-3' >10 000+</p>
                                <p className='mb-3 md:text-2xl text-lg' >clients from 169<br/> countries</p>
                            <div className='px-24' >
                                <div className='md:border-b-8 border-b-4 border-[#00C7FF]' ></div>
                            </div>
                        </div>
                        <div>
                            <p className='md:text-6xl text-3xl font-bold mb-3' >15+</p>
                            <div className='md:px-10 px-4' >
                                <p className='mb-3 md:text-2xl text-lg' >years experience in the industry</p>
                            </div>
                            <div className='px-24' >
                                <div className='md:border-b-8 border-b-4 border-[#00C7FF]' ></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-monstrade-blue py-3 px-4 md:text-3xl text-xl font-semibold hover:text-white hover:bg-monstrade-green' >
                            Trade Now
                        </a>
                    </div>
                </div>
            </BannerContainer>
            <MissionOnAboutUs />
            {/* <BannerContainer options={landingOptions}/> */}
            <WhoWeAre />
            <div className='container-full bg-[#2167EE] text-white py-10'>
                <SimpleCardListContainer 
                    cards={cards} 
                    header={"Security of Client's Funds"}
                    text={"Your money is completely safe when you trade with Monstrade."} />
            </div>
            {/* <CustomerReviewContainer commandIncluded={true} /> */}
        </div>
    )
}
