import React from "react";
import TopBanner from "../components/TopBanner";
import IndicesImg from "../assets/images/indices_img.png"
import Button from "../components/Button"
import Table from "../components/Table"
import IndicesBanner from "../components/IndicesBanner";
import TextArea from "../components/TextArea";
import PhoneMockup from '../components/PhoneMockup'
import StockContentTable from "../components/StockContentTable" 
import Security from "../components/Security";
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import HourglassIco from "../assets/icons/hourglass.svg"
import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";

const pageName = "Indices"

const textAreaTitle = "What is indices trading?"
const textAreaContent = "Indices are trading tools, which consist of a group of bonds and show changes in their prices. For example, an index includes several stocks and its price is calculated based on the total cost of bonds or capitalization of issuers. A set of bonds, which the index consists of, shows what information relating to the current economic situation may be received when analyzing it. Thanks to using indices in trading, traders have an opportunity to diversify their investment portfolio, collect and analyze information relating to the global economy or one of its sectors. <br/> <br/> Monstrade offers its clients access to some of the best conditions for investing in indices in the industry."
const landingOptions = {
    image : IndicesImg,
    imageGradient : 'linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5))',
    mainZindex : 'z-[-1]',
    header: {
        text : 'Are You Ready Index Trading',
        size: 'text-3xl',
      },
      subHeader: {
        text : "Invest in indices with tight spreads and no commission for the trading volume of 1 million USD.",
        align : "text-center"
      }
}
const cards = [
    { header: 'Leverage up to 1:100', description: '', icon: BalanceIco, link: '' },
    { header: 'Low spreads', description: '', icon: ForexIco, link: '' },
    { header: 'High trade speed', description: '', icon: HourglassIco, link: '' },
]
const IndicesPage = () => {
    return(
        <>
            
            <BannerContainer options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-3'} />
                </div>
            </BannerContainer>
            <div className="mt-10">
                <Button content="TRADE INDICES" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={false} />
            </div>
            <Table type={'indice'} />
            <IndicesBanner/>
            <TextArea textAreaTitle = {textAreaTitle} textAreaContent = {<div dangerouslySetInnerHTML={{__html: textAreaContent}} />}/>
            <PhoneMockup pageName={pageName}/>
            <StockContentTable/>
            <Security/>
        </>
    )
}

export default IndicesPage;