import React from "react";

const CashbackTable = () => {
    return(
        <>
        <div class="flex flex-col text-center">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden">
                    <table class="min-w-full">
                    <thead class="bg-white border-b">
                        <tr>
                            <th scope="col" class="text-sm font-semibold text-gray-900 px-6 py-4">
                                Level (lvl)
                            </th>
                            <th scope="col" class="text-sm font-semibold text-gray-900 px-6 py-4">
                                Required monthly trading volume
                            </th>
                            <th scope="col" class="text-sm font-semibold text-gray-900 px-6 py-4">
                                New cashback rate (per lot)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-gray-100 border-b">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">1</td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                                1 lots
                            </td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                            $0.5
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">2</td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                                5 lots
                            </td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                            $4
                            </td>
                        </tr>
                        <tr class="bg-gray-100 border-b">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">3</td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                                5 lots
                            </td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                            $5
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">4</td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                                5 lots
                            </td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                            $8
                            </td>
                        </tr>
                        <tr class="bg-gray-100 border-b">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">5</td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                                5 lots
                            </td>
                            <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                            $12
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CashbackTable;