import React from 'react'

const StoreTicketCheck = () => {
  return (
    <>
        {/* <div className='grid lg:grid-cols-5 items-center lg:text-left text-center py-4 lg:mx-32 mx-2'>
            <div className='lg:col-span-3 py-4 lg:order-first order-last'>
                <p className='text-[#DE0B0B] text-2xl font-bold'>Check Your Tickets</p>
                <p className='py-4'>View your tickets by entering your MetaTrader ID number and password.</p>
                <div className='md:flex grid grid-cols-2 gap-4'>
                    <div>
                        <input {...register('username', { required: true })} className='bg-[#E9E9E9] rounded-lg p-2 md:w-auto w-full' type="text" placeholder='MetaTrader ID' />
                    </div>
                    <div>
                        <input {...register('password', { required: true })} className='bg-[#E9E9E9] rounded-lg p-2 md:w-auto w-full' type="password" placeholder='Password' />
                    </div>
                    <div className='md:inline-block block md:col-span-1 col-span-2'>
                        <button
                            disabled={isDisabled}
                            onClick={handleSubmit(onMetaSubmit)}
                            type='submit'
                            className={`${isDataReturned == false ? "bg-red-600 " : isClicked } rounded-lg p-2 text-white lg:w-auto w-full font-bold`}>
                            {isDataReturned == false ? "Check" : isLoaded}
                        </button>
                    </div>
                </div>
            </div>
            <div className='lg:col-span-2'>
                <img className='lg:mx-0 mx-auto lg:order-last order-first' src={Image1} alt="" />
            </div>
        </div> */}
    </>
  )
}

export default StoreTicketCheck