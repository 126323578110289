import React from "react";
import LandingBanner from "../components/Banners/LandingBanner";
import bgImage from "../assets/images/banners/forex-news-banner.png";
const LandingBannerForexNews = () => {
    const defaultStyle = {
        composition: {
            margin: "left",
            alignment : "center",
            lgW: "lg:w-1/3"
        },
        header : {
            size: "5"
        },
        text : {

        },
        container: {
            h : "md:h-[46.5vw]",
            maxH : "max-h-[671px]"
        },
        image: {

        },
        button: {
            color: "monstrade-blue",
            textColor: "text-white",
            border: true,
            borderColor: "monstrade-blue",
            paddingT: "8"
        }
    }
    const content = {
        header : "We Compile the Most Accurate News for You.",
        text : "We understand that every trader has different needs. Monstrade offers 6 account types to help you trade better",
        button : "Join Now",
        path : "https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard"
    }
    return(
        <>
            <LandingBanner  image={bgImage} defaultStyle={defaultStyle} content={content}/>
        </>
    )
}

export default LandingBannerForexNews;