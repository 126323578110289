import React from 'react'
//images
import icon1 from '../assets/icons/prefered-program-icon-1.svg'
import icon2 from '../assets/icons/prefered-program-icon-2.svg'
import icon3 from '../assets/icons/prefered-program-icon-3.svg'

export const ChooseYourProgram = () => {
    return (
        <div className='container-full' >
            <div className='container-max mx-auto py-10' >
                <div className='text-center mb-10' >
                    <h3 className='text-2xl mb-3' >Choose Your Prefferred Program</h3>
                    <p className='text-gray-500'>
                        Leverage your personal and professional skills in one of
                        Monstrade’s dedicated partner programs.
                    </p>
                </div>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-rows-1 gap-12'>
                    <div className='text-center' >
                        <div className='mb-3 flex'>
                            <img src={icon1} alt="" className='m-auto' />
                        </div>
                        <p className='mb-3 font-bold' >Introducing Broker</p>
                        <p>
                            Introduce traders to Monstrade
                            from your clientele and connetctions, and get
                            a recurring comission on their trades.
                        </p>
                    </div>
                    <div className='text-center' >
                        <div className='mb-3 flex'>
                            <img src={icon2} alt="" className='m-auto' />
                        </div>
                        <p className='mb-3 font-bold' >Affiliate</p>
                        <p>
                            Tell the world about Monstrade trough online platformscommission from every new trader.
                        </p>
                    </div>
                    <div className='text-center' >
                        <div className='mb-3 flex'>
                            <img src={icon3} alt="" className='m-auto' />
                        </div>
                        <p className='mb-3 font-bold' >Company Representative</p>
                        <p>
                            Resent Monstrade among local traders with our full support in marketing seminars, and events you think will work.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
