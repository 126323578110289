import React from 'react'
//images
import icon1 from '../assets/icons/affiliate-pc-dolar-icon.svg'
import icon2 from '../assets/icons/affiliate-person-pc.svg'
import icon3 from '../assets/icons/affiliate-ultilang-icon.svg'
import icon4 from '../assets/icons/affiliate-gold-icon.svg'
import icon5 from '../assets/icons/affiliate-multi-device.svg'
import icon6 from '../assets/icons/affiliate-money-pocket.svg'

export const ForexAffiliateAndPartnership = () => {
    return (
        <div className='container-full bg-mons-gray-1' >
            <div className='container-max mx-auto py-10'>
                <div className='text-center py-10'>
                    <h2 className='text-2xl mb-3' >
                        <span className='font-semibold' >Partnership </span>  &
                        <span className='font-semibold' > Forex Affiliate</span> Program Benefits
                    </h2>
                    <p>
                        Become a partner with one of the most successful affiliate programs in the foreign exchange industry. 
                        The program is designed to charge and reward promoters and partners who refer customers to MonsTrade. 
                        <br/><br/>
                        The MonsTrade partnership program is one of the leading IB and forex affiliate programs.
                    </p>
                </div>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-rows-1 gap-3'>
                    <div className='bg-white grid grid-cols-3 px-5 py-10 rounded-lg gap-5'>
                        <div className='flex'>
                            <img className='m-auto' src={icon1} alt="" />
                        </div>
                        <div className='relative col-span-2 flex' >
                            <p className='text-sm m-auto'>Advanced real-time tracking technology & 24/5 advanced reporting</p>
                        </div>
                    </div>
                    <div className='bg-white grid grid-cols-3 px-5 py-10 rounded-lg gap-5'>
                        <div className='flex'>
                            <img className='m-auto' src={icon2} alt="" />
                        </div>
                        <div className='relative col-span-2 flex' >
                            <p className='text-sm m-auto'>24/5 IB support line</p>
                        </div>
                    </div>
                    <div className='bg-white grid grid-cols-3 px-5 py-10 rounded-lg gap-5'>
                        <div className='flex'>
                            <img className='m-auto' src={icon3} alt="" />
                        </div>
                        <div className='relative col-span-2 flex' >
                            <p className='text-sm m-auto'>Multi-lingual,mobile-friendly marketing tools,ready to convert</p>
                        </div>
                    </div>
                    <div className='bg-white grid grid-cols-3 px-5 py-10 rounded-lg gap-5'>
                        <div className='flex'>
                            <img className='m-auto' src={icon4} alt="" />
                        </div>
                        <div className='relative col-span-2 flex' >
                            <p className='text-sm m-auto'>Customized deals and payment structures</p>
                        </div>
                    </div>
                    <div className='bg-white grid grid-cols-3 px-5 py-10 rounded-lg gap-5'>
                        <div className='flex'>
                            <img className='m-auto' src={icon5} alt="" />
                        </div>
                        <div className='relative col-span-2 flex' >
                            <p className='text-sm m-auto'>State-of-the-art IB & AffiliatePortals</p>
                        </div>
                    </div>
                    <div className='bg-white grid grid-cols-3 px-5 py-10 rounded-lg gap-5'>
                        <div className='flex'>
                            <img className='m-auto' src={icon6} alt="" />
                        </div>
                        <div className='relative col-span-2 flex' >
                            <p className='text-sm m-auto'>Fast & easy payments with generous commissions</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
