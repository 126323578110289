import React from 'react'
import img from "../assets/images/high-low-banner.png"

export const HighAndLowForexSpread = () => {
  return (
        <div className='container-full relative lg:h-[45vw] lg:bg-transparent h-auto text-white z-[-1] sm:block hidden'>
            <div className='absolute z-[-1] h-full w-full overflow-hidden' >
                <img className='lg:w-full max-w-none lg:ml-0 -ml-[50%] lg:h-auto h-full' src={img} alt="" />
            </div>
            <div className='container-max mx-auto text-white pt-8'>
                <div className='pt-12 pb-8' >
                    <h3 className='text-4xl text-center' >Understanding High and Low Forex Spreads</h3>
                </div>
                <div className='grid md:grid-cols-2 gap-24 py-6'>
                    <div>
                        <p>
                            It is important to know that currency spreads can fluctuate throughout the day. This is because spreads are affected by various factors such as liquidity, volatility and market news. You will find that some major currency pairs like EUR/USD or GBP/USD, which tend to have more liquidity, also have lower spreads than emerging currency pairs like USD/JPY.
                            <br/><br/>
                            Also, liquidity can drop and spreads can widen in the days or hours leading up to major economic news events and between trading sessions.
                            <br/><br/>
                            Follow the economic calendar. Releases happen sporadically and prices can fluctuate quickly depending on whether expectations are met. If you are already holding a position and the spread widens, you may receive a margin call. Spreads can widen significantly when financial markets are volatile: this is a phenomenon known as slippage.
                        </p>
                    </div>
                    <div>
                        <p>
                            It is also important to understand the difference between fixed and variable spreads. Fixed spreads remain the same regardless of market conditions. Variable spreads continue to change, depending on the supply and demand of instruments and overall market volatility.
                            <br/><br/>
                            Choosing the optimum spread type is important for reducing trading costs and is an essential part of any good trader's trading strategy. Retail traders with smaller accounts who trade less frequently may benefit from fixed spreads, while those who trade more frequently and during peak market hours (when the spreads are at their tightest) may prefer variable spreads. Variable spreads tend to be lower than fixed spreads, especially in quieter markets.                   
                        </p>
                    </div>
                </div>
            </div>
        </div>
  )
}
