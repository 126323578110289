import React, { useEffect, useState } from 'react'
import { SpreadSearch } from '../Forms/SpreadSearch'
import styles from './SpreadTable.module.css'

export const SpreadTable = ({ table: table }) => {
    const [searchParam, setSearchParam] = useState("");
    const [tableData, setTableData] = useState([])
    let subRowNumber = 0

    table.meta.map((item) => {
        let subRow = 0
        if (item.subColumn.length > 0) {
            subRow++;
            item.subColumn.map((subItem) => {
                if (subItem.subColumn.length > 0) {
                    subRow++;
                }
            })
        }
        if (subRow > subRowNumber) { subRowNumber = subRow }
    })


    useEffect(() => {

        if (!searchParam) return setTableData(table.data);

        const timeOut = setTimeout(() => {

            let filtered = table.data.filter((data) => { return data[0].toLowerCase().includes(searchParam.toLowerCase()) ? 1 : 0 })
            setTableData(filtered)
        }, 400);
        return () => clearTimeout(timeOut)


    }, [searchParam])

    return (
        <div>
            <div>
                <h3 className='text-2xl text-center font-bold py-5'> {table.name} </h3>
            </div>
            <div className='py-5' >
                <SpreadSearch setSearchParam={setSearchParam} />
            </div>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead>
                                    <tr className='text-white font-bold text-center' >
                                        {table.meta.map((item, index) => {
                                            return (
                                                <td rowSpan={item.subColumn.length === 0 ? 2 : 1}
                                                    colSpan={item.subColumn.length > 1 ?
                                                        item.subColumn.length : 1}
                                                    key={index}
                                                    scope="col"
                                                    className={`px-6 py-4 font-semibold
                                                            ${item.subColumn.length === 0 ? styles.fullGr : styles.longGr}`}>
                                                    {item.column}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    {subRowNumber > 0 ?
                                        <tr className={`${styles.shortGr} text-white text-md font-semibold text-center`} >
                                            {
                                                table.meta.map((item, index) => {
                                                    return item.subColumn.map((sitem, sindex) => {
                                                        return (
                                                            <td key={index * sindex} >{sitem.column}</td>
                                                        )
                                                    })
                                                })
                                            }
                                        </tr>
                                        :
                                        <></>
                                    }
                                </thead>
                                <tbody className='' >
                                    {
                                        tableData.map((item, index) => {
                                            return (
                                                <tr key={index} className={index % 2 === 0 ? "bg-gray-100 border-b font-semibold text-gray-900 text-center" : "bg-white border-b font-semibold text-gray-900 text-center"}>
                                                    {
                                                        item.map((sitem, sindex) => {
                                                            return (
                                                                <td key={sindex} className={`${sindex === 0 ? "text-monstrade-blue" : "text-gray-900"}
                                                                                            px-6 py-4 
                                                                                            whitespace-nowrap
                                                                                            text-sm`}>{sitem}</td>

                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
