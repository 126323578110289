import React from "react";
import img from "../assets/images/forex-broker-spreads-img.png"
const ForexBrokerSpreads = () => {
    return (
        <div className="container-full sm:h-[40vw] h-[84vw] bg-[#F0F0F0] sm:block hidden" >
            <div className="container-max relative mx-auto pt-10 flex">
                <div className="lg:w-2/4 w-full relative" >
                    <div className="bottom-0 md:pt-36" >
                        <h3 className="text-4xl font-bold mb-3" >Forex Broker Spreads</h3>
                        <p>
                        Currency spreads are quoted at two different prices: bid and ask price. The buying price is the price at which you can sell the base currency, and the selling price is the price at which you can buy the base currency.
                        </p>
                    </div>
                </div>
                <div className="absolute xl:block hidden right-0">
                    <img src={img}></img>
                </div>
            </div>
        </div>
    )
}

export default ForexBrokerSpreads;