import React from "react";
import bgImage from "../assets/images/what-is-spread-banner.png"

const WhatIsSpread = () => {
    return (
        <div className="container-full relative xl:h-[35vw] xl:bg-transparent h-auto text-white z-[-1]'">
            <div className='absolute z-[-1] h-full w-full overflow-hidden'>
                <img className="xl:w-full max-w-none xl:ml-0 -ml-[50%] xl:h-auto h-full" src={bgImage} alt="" />
            </div>
            <div className="container-max mx-auto md:text-left text-center text-white">
                <div className="md:w-[46%] px-10" >
                    <h2 className="text-4xl mt-10 mb-5" >
                        <span className="font-semibold" ><br/>What does Spread mean?</span>
                    </h2>
                    <p className="mb-5 text-lg" >You can trade with Monstrade on ultra-competitive spreads</p>
                    <p className="text-lg">
                        Forex is the most traded market in the world, offering countless trading opportunities.
                        One of the ways to pay for these opportunities is through broker spreads, or the
                        difference between the bid and ask price of a tradable instrument. When the spread
                        is tight, your transaction cost goes down.
                        <br/>
                        <br/>
                        Our diverse liquidity mix and partnerships with leading banking and non-banking 
                        financial institutions for deep liquidity play an important role in ensuring we 
                        consistently offer our traders low margins starting at 0.0 pips.
                    </p><br/><br/>
                </div>
            </div>
        </div>
    )
}

export default WhatIsSpread;