import React, { Fragment, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag"
import Moment from 'moment';
import economicCalenderContent from '../../features/content/economicCalender.content'
import Skeleton from "react-loading-skeleton";


const date = economicCalenderContent();

const EconomicEvents = ({ events, isApiLoading }) => {

    const prevDate = useRef('1900-01-01 22:45:00');
    // const [prevDate, setPrevDate] = useState('1900-01-01 22:45:00')
    return (
        <>
            <div className="container-full">
                <div className="container-max mx-auto mt-3 mb-10">
                    <div className="overflow-y-auto max-h-96" >
                        <table className="table-auto w-full">
                            <thead className="text-left" >
                                <tr className="bg-[#f2f2f4] text-sm font-light text-gray-400" >
                                    <th className="sticky top-0 bg-[#f2f2f4]" >time</th>
                                    <th className="sticky top-0 bg-[#f2f2f4]" >country</th>
                                    <th className="sticky top-0 bg-[#f2f2f4]" >events</th>
                                    <th className="sticky top-0 bg-[#f2f2f4] text-right" >change</th>
                                    <th className="sticky top-0 bg-[#f2f2f4] text-right" >actual</th>
                                    <th className="sticky top-0 bg-[#f2f2f4] text-right" >estimate</th>
                                    <th className="sticky top-0 bg-[#f2f2f4] text-right px-3" >previous</th>
                                </tr>
                            </thead>
                            <tbody className="text-left text-gray-400 text-sm border-2" >
                                {events &&
                                    (
                                        events.slice(0).reverse().map((event, index) => {

                                            if (Moment(event.date).format('DD.MM.YYYY') != Moment(prevDate.current).format('DD.MM.YYYY')) {
                                                let currentDate = new Date(event.date);
                                                let nameOfMont = currentDate.toLocaleString('en-EN', { month: 'long' });
                                                let nameOfDay = currentDate.toLocaleDateString('en-EN', { weekday: 'long' });

                                                prevDate.current = event.date;
                                                return (
                                                    <Fragment key={index}>
                                                        <tr className="text-center text-medium font-semibold text-black bg-[#f2f2f4]" > <td colSpan='7' > {nameOfDay}, {nameOfMont} {Moment(event.date).format('DD')} </td> </tr>
                                                        <tr key={index} className="border-[1px]">
                                                            <td> {Moment(event.date).format('hh:mm')} </td>
                                                            <td> <ReactCountryFlag
                                                                countryCode={event.country} title={event.country} /> <span className="text-bold text-black" >{event.country}</span> </td>
                                                            <td> {event.event} </td>
                                                            <td className="text-right" >
                                                                {event.changePercentage &&
                                                                    <div className={`${parseInt(event.changePercentage) > 0 ? 'text-green-500' : 'text-red-500'}`} >
                                                                        {parseInt(event.changePercentage).toFixed(2)}%
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className="text-right" > {event.actual} </td>
                                                            <td className="text-right" > {event.estimate == "" ? "-" : event.estimate} </td>
                                                            <td className="text-right px-3" > {event.previous} </td>
                                                        </tr>
                                                    </Fragment>
                                                )
                                            } else {
                                                return (
                                                    <Fragment key={index}>
                                                        <tr key={index} className="border-[1px]">
                                                            <td> {Moment(event.date).format('hh:mm')} </td>
                                                            <td> <ReactCountryFlag
                                                                countryCode={event.country} title={event.country} /> <span className="text-bold text-black" >{event.country}</span> </td>
                                                            <td> {event.event} </td>
                                                            <td className="text-right" >
                                                                {event.changePercentage &&
                                                                    <div className={`${parseInt(event.changePercentage) > 0 ? 'text-green-500' : 'text-red-500'}`} >
                                                                        {parseInt(event.changePercentage).toFixed(2)}%
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className="text-right" > {event.actual} </td>
                                                            <td className="text-right" > {event.estimate == "" ? "-" : event.estimate} </td>
                                                            <td className="text-right px-3" > {event.previous} </td>
                                                        </tr>
                                                    </Fragment>
                                                )
                                            }
                                        })
                                    )
                                }
                                {isApiLoading &&
                                    (<tr className="text-center text-medium font-semibold text-black bg-[#f2f2f4]" >
                                        <td colSpan='7'><Skeleton className="" count={18} /></td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EconomicEvents;