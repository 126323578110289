import React from 'react'
import Banner from '../../assets/images/banners/copy-trade-banner.png'

const CopyTrade = () => {
  return (
    <>
      <div className='bg-monstrade-blue'>
        <div className='grid lg:grid-cols-2 max-w-[1440px] mx-auto h-[524px] items-center'>
          <div className='text-white mx-2'>
            <p className='text-xl font-semibold'>| MONSTRADE PRIVILEGES</p>
            <p className='text-5xl font-semibold py-28'>Copy Trade</p>
            <p className='text-sm max-w-[460px]'>Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.</p>
          </div>
          <div>
            <img src={Banner} alt="" />
          </div>
        </div>
      </div>
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='max-w-[1440px] mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            Copy Trade system is the process of copying transactions from the master accounts chosen by investors in proportion to the balances in their own investment accounts.
            <br/><br/>
            To participate in the Copy Trade system, you need to contact Monstrade consultants to express your interest.
            <br/><br/>
            After choosing a successful trader, you must maintain a sufficient balance in your account.
            <br/><br/>
            Monstrade does not provide any guidance in trader selection. The choice is entirely at the investor's discretion.
            <br/><br/>
            The investor must approve the USER PERMISSION AGREEMENT document to integrate the Copy Trade system into his account.
            <br/><br/>
            After submitting the document to the relevant Monstrade departments, the investor will be included in the system and informed within 2 business days at the latest. This process is performed only at the end of each day.
            <br/><br/>
            When a trader opens a transaction in his account, a transaction in the balance ratio will automatically be opened in the investor's account. Similarly, when a trader closes a trade, the trader's trades will automatically close at the same level.
            <br/><br/>
            Users can view open transactions in their accounts and set Take Profit/Stop Loss levels for profit realization or close the transaction whenever they want.
            <br/><br/>
            In case of partial closure applied to the transactions, the transaction does not continue in accordance with the trader's movement and the remaining part leaves the Copy Trade system.
            <br/><br/>
            The minimum balance required for Copy Trade is 2,000 USD, but this amount may vary depending on the preferred balance of the trader the user chooses.
            <br/><br/>
            The closer the investor's balance is to the balance of their preferred trader, the lower the Stop-Out risk. For example, if the investor's balance is 100%, all transactions are opened by the system at the same rate. If the balance is 75%, 75% of the transactions are opened and when the main account opens 1 lot, a transaction of 0.75 lot size is opened in the other account. The size of the opened transaction depends on what percentage the investor's balance represents the balance of the main account.
          </p>
        </div>
      </div>
    </>
  )
}

export default CopyTrade