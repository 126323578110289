import React, { useState } from 'react'
//package
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Timeline } from 'react-twitter-widgets'

export const TwitterProfileTimeLine = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    const loadHandler = () => { setIsLoaded(true)}

    return (
        <div>
            {!isLoaded && 
                <Skeleton  height={1000} className="rounded-xl w-100"/>
            }

            <Timeline
                dataSource={{
                    sourceType: 'profile',
                    screenName: 'MonsTradeFX'
                }}
                options={{
                    height: '1080',
                    via:true
                }}
                onLoad={loadHandler}
            />
        </div>
    )
}

