import React from "react";
import Button from "../Button"

const ZeroCommissionContent = () => {
    return (
        <>
            <div className="container-full">
                <div className="container-max mx-auto">
                    <div className="p-4">

                        <p className="md:text-left text-center pt-4">
                            MonsTrade covers all deposit and withdrawal transfer fees for payments made via Neteller, Moneybookers and all major credit cards . Additionally, all deposits and withdrawals above processed by wire transfer are also included in our zero fees policy.
                        </p>
                    </div>
                    <p className="font-bold text-[35px] leading-[41px] pt-4 text-center">Instant Funding</p>
                </div>
                <div className="grid md:grid-cols-3 grid-rows-1 md:text-right text-center bg-[#D7D7D7] lg:w-[1000px] mx-auto py-6 my-6">
                    <div className="md:col-span-2 py-4 text-[#6C6C6C]">
                        <p>All our electronic deposit methods, including credit cards as well as electronic wallets, are 100% automatically processed and funds reflect in clients’ accounts automatically.</p>
                    </div>
                    <div className="md:pt-3 mt-4 pb-6">
                        <Button content="OPEN AN ACCOUNT" color="bg-[#84BD31]" py="py-3" px="px-6" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ZeroCommissionContent;

