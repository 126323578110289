import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
//images
import image from '../assets/images/banners/partner-banner.png'
import { ForexAffiliateAndPartnership } from '../containers/ForexAffiliateAndPartnership'
import { ChooseYourProgram } from '../containers/ChooseYourProgram'
import { LoyalityProgram } from '../containers/LoyalityProgram'
import { AffiliateHowItWork } from '../containers/AffiliateHowItWork'
import { AffiliateEasyStart } from '../containers/AffiliateEasyStart'
import PartnerAffiliatesBanner from '../components/PartnerAffiliatesBanner'
import { BannerContainer } from '../components/Containers/BannerContainer'

export const Partner = () => {

    const subHeaderText = "Join the affiliate program ang get profit with Monstrade:<br/><br/>\
    Revenue Share up to 20%<br/>Instant partner commission<br/><br/>\
    Revenue program up to 10%<br/>of the total partner commission"

    const landingOptions = {
        image : image,
        container : {
          width : 'w-4/5',
          padding : 'py-20'
        },
        header: {
          text : 'Monstrade Affiliate Program',
          size: 'text-3xl',
          align: "md:text-left text-center"
        },
        subHeader: {
            text : <div dangerouslySetInnerHTML={{__html: subHeaderText}} />,
            align: "md:text-left text-center"
        },
        button: {
            bgcolor: "bg-white",
            textcolor: "text-monstrade-blue",
            text: "Become a Partner",
            subText: "Start earning in few minutes",
            padding: "pt-4"
        }
      }

    return (
        <div>
            <BannerContainer options={landingOptions}/>
            <ForexAffiliateAndPartnership />
            <PartnerAffiliatesBanner/>
            <ChooseYourProgram />
            <LoyalityProgram />
            <AffiliateHowItWork />
            <AffiliateEasyStart />
        </div>
    )
}
