import React from 'react'

export const LoyalityProgram = () => {
  return (
    <div className='container-full bg-mons-blue-1' >
        <div className='container-max mx-auto py-16' >
            <div className='text-center text-white mb-16' >
                <h3 className='text-2xl font-semibold mb-3' >Receive even more funds within the framework of the IB program</h3>
                <p className='font-light' >
                    Every month, MonsTrade pay an additional percentage . Payouts within the framework of the IB program are made automatically.
                </p>
            </div>
            <div className='grid md:grid-cols-8 gap-8'>
                <div className='md:col-span-2 rounded overflow-hidden'>
                    <div className='bg-[#84BD31] text-white text-center p-3' >
                        <p>Your profit</p>
                        <p>100 - 1000 USD</p>
                    </div>
                    <div className='bg-white text-center text-gray-600 p-3' >
                        <p className='mb-3' >Pay out</p>
                        <p className='mb-3 font-bold text-4xl' >5%</p>
                        <p>up to 50 USD</p>
                    </div>
                </div>
                <div className='flex'>
                    <p className='text-3xl text-white m-auto md:block hidden' > > </p>
                    <p className='text-3xl text-white m-auto md:hidden block' > v </p>
                </div>
                <div className='md:col-span-2 rounded overflow-hidden'>
                    <div className='bg-[#84BD31] text-white text-center p-3' >
                        <p>Your profit</p>
                        <p>1000 - 30.000 USD</p>
                    </div>
                    <div className='bg-white text-center text-gray-600 p-3' >
                        <p className='mb-3' >Pay out</p>
                        <p className='mb-3 font-bold text-4xl' >10%</p>
                        <p>up to 4.500 USD</p>
                    </div>
                </div>
                <div className='flex'>
                    <p className='text-3xl text-white m-auto md:block hidden' > > </p>
                    <p className='text-3xl text-white m-auto md:hidden block' > v </p>
                </div>
                <div className='md:col-span-2 rounded overflow-hidden'>
                    <div className='bg-[#84BD31] text-white text-center p-3' >
                        <p>Your profit</p>
                        <p>more than 30.000 USD</p>
                    </div>
                    <div className='bg-white text-center text-gray-600 p-3' >
                        <p className='mb-3' >Pay out</p>
                        <p className='mb-3 font-bold text-4xl' >15%</p>
                        <p>up to 6.000 USD</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
