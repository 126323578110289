import React from 'react'
import { SimpleCard } from '../Cards/SimpleCard';

export const SimpleCardListContainer = ({ cards, header, text, textClass, containerClass, mobile }) => {
    return (
        <>
            <div className="container-max block m-auto pt-8">
                {header &&
                    <div className="py-8 md:py-10 w-full" >
                        <h2 dangerouslySetInnerHTML={{__html : header}} className="md:text-3xl text-2xl text-center font-semibold " ></h2>
                    </div>
                }
                {text && 
                    <div className="w-full" >
                        <p dangerouslySetInnerHTML={{__html : text}} className={`text-center text-xl font-light pb-6`}></p>
                    </div>
                }
                <div className={`grid ${containerClass ? containerClass : 'grid-cols-2'} text-center md:grid-cols-3 lg:grid-cols-${cards.length} m-auto md:pb-10 pb-5 lg:gap-4 gap-4`}>
                    {
                        cards.map((item, index) => {
                            return (
                                <SimpleCard header={item.header}
                                    link={item.link}
                                    description={item.description}
                                    icon={item.icon}
                                    btnText={item.btnText}
                                    key={index}
                                    textClass={textClass} />
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
