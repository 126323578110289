import React, {useState} from "react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";

  import GreenTick from '../../assets/icons/v2-greentick.svg'

  import AccountGray from '../../assets/icons/account-gray.svg'
  import AccountBlue from '../../assets/icons/account-blue.svg'
  import GroupsGray from '../../assets/icons/groups-gray.svg'
  import GroupsBlue from '../../assets/icons/groups-blue.svg'

  export function TabsComponent() {

    const [which, setWhich] = useState('first');

    const data = [
      {
        iconActive: AccountBlue,
        iconPassive: AccountGray,
        label: "Introducer",
        value: "first",
        title: "They invest, you earn!",
        desc: `Refer investors to us and earn based on the volumes traded by the referred investors.`,
        tick1: "Earn up to 45% share from the revenue generated by the referred investor",
        tick2: "As your client base grows, so does your income",
      },
      {
        iconActive: GroupsBlue,
        iconPassive: GroupsGray,
        label: "Introducer",
        value: "second",
        title: "They register, you earn!",
        desc: `Make money for every customer who opens an account.`,
        tick1: "Earn USD for every customer you refer to MonsTrade",
        tick2: "Expand your global network with exclusive marketing training",
      },
    ];
   
    return (
      <Tabs value="first">
        <TabsHeader
          className="bg-transparent"
          indicatorProps={{
            className: "bg-gray-900/10 shadow-none !text-gray-900",
          }}
        >
          {data.map(({ label, value, iconActive,iconPassive }) => (
            <Tab onClick={()=>setWhich(value)} className={`${which == value ? "border-b-monstrade-blue text-monstrade-blue" : ""} border-b-2 text-center`} key={value} value={value}>
              {which == value ? <img className="mx-auto h-[35px]" src={iconActive} alt="" /> : <img className="mx-auto h-[35px]" src={iconPassive} alt="" />}
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value,title, tick1,tick2, desc }) => (
            <TabPanel className="text-center h-[300px]" key={value} value={value}>
              <p className="font-semibold text-3xl py-4">{title}</p>
              {desc}
              <div className="flex max-w-[300px] text-left mx-auto pt-4">
                <img src={GreenTick} alt="" />
                <p>{tick1}</p>
              </div>
              <div className="flex max-w-[300px] text-left mx-auto">
                <img src={GreenTick} alt="" />
                <p>{tick2}</p>
              </div>
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    );
  }