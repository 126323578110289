import React from "react";

const CashbackTerms = () => {
    return(
        <>
        <div className="p-8">
            <div>
                <p className="text-lg font-bold text-lime-500">Cashback Terms:</p>
            </div>
            <br/>
            <p>
                Only Standard and Fixed USD  accounts in the  MT5 platforms can participate in the  program.
                <br/><br/>
                Cashback is calculated based on the trading activity in all product.
                <br/><br/>
                To stay at the first level of the program, you need to make a trading turnover of 30 lots within 30 days.
                <br/><br/>
                The position holding time for each trade should be at least 5 minutes.
                <br/><br/>
                The price change in each trade should be at least 30 pips.
                <br/><br/>
                The cashback is credited daily in the form of a trading bonus to the trading account participating in the program after the position is closed. Refund accrual for the current trading session will occur the next day before 17:00 EET (trading terminal time). Please note that cashback is credited to the "Balance" line for MT5 trading accounts.
                <br/><br/>
                To advance to the next level, you need to reach a required trading volume of  lots within 30 days.
                <br/><br/>
                If a Client withdraws funds from his trading account or fails to achieve the required trading volume during the period of 30 days, the progress will be lost, and the account will return to level 1.
                <br/><br/>
                The minimum monthly trading turnover for a subscription to the first level is 30 lots.
            </p>
        </div>
        </>
    )
}

export default CashbackTerms;