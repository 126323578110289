import React from "react";
import GlobalMarketSlider from "../components/Slider/GlobalMarketSlider";

const content = [
    {name: 'skrill' , image : require('../assets/images/market-logo/skrill.png')},
    {name: 'amex' , image : require('../assets/images/market-logo/amex.png')},
    {name: 'mastercard' , image : require('../assets/images/market-logo/mastercard.png')},
    {name: 'tether' , image : require('../assets/images/market-logo/tether.png')},
    {name: 'visa' , image : require('../assets/images/market-logo/visa.png')},
]

const GlobalMarket = () => {
    return (
        <>
            <div className="container-full mx-auto bg-monstrade-blue text-white">
                <div className="pt-10 pb-3">
                    <div className="grid-rows-4">
                        <div className="flex" >
                            <div className="mx-auto md:py-2 py-4 text-center" >
                                <p className="text-2xl font-semibold" >Trade the Global Markets 
                                with Industry-Low Spreads</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mx-auto py-4 md:py-2">
                                <p className="text-xl text-center" >
                                    Make speedy and safe trade with spreads as low as 0.0 pips
                                </p>
                            </div>
                        </div>
                        <div className="flex" >
                            <div className="flex mx-auto pt-8" >
                                <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard">
                                    <button  className="bg-white text-monstrade-blue hover:text-white hover:bg-monstrade-green py-2 px-4 rounded font-semibold">
                                        Start Trading
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="flex py-10 overflow-hidden" >
                                <GlobalMarketSlider content={content} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GlobalMarket;